import { useMemo, useState } from 'react';

import { Button } from 'primereact/button';
import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { Divider } from 'primereact/divider';
import { InsertBlockOrderForm, InsertBlockOrderModalProps } from './types';
import SelectManyOperatorTags from 'components/models/Operators/SelectManyOperatorTags';
import useUpsertBlockOrder from 'hooks/mutations/blockOrder/useUpsertBlockOrder';
import SelectManyAuthorizations from 'components/models/Authorization/SelectManyAuthorizations';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { UpsertBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import SelectManyAuthorizationConfigs from 'components/models/AuthorizationConfig/SelectManyAuthorizationConfigs';
import SelectOneBlockOrderType from 'components/models/BlockOrder/SelectOneBlockOrderType';
import { updateUserDefaultOperation } from 'services/ether/case-manager/operation';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';
import { CheckActionTargetWarningEP } from 'services/ether/case-manager/targets/types';
import { checkActionTargetWarning } from 'services/ether/case-manager/targets';
import useShowTargetCheckWarning from 'hooks/dialogs/target/useShowTargetCheckWarning';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import LightDialog from 'components/display/LightDialog';

const InsertBlockOrderModal: React.FC<InsertBlockOrderModalProps> = ({
    blockOrderToEdit,
    onInsert,
    hideFields,
    startingData,
    ...props
}) => {
    const project = useProject();

    const fieldsRequirement = useMemo(() => {
        const defaultFields: Record<
            keyof Omit<
                UpsertBlockOrderEP.InsertData,
                | 'project_id'
                | 'status'
                | 'authorization_flow_id'
                | 'authorization_config_ids'
                | 'authorization_ids'
            >,
            boolean | undefined
        > = {
            name: true,
            operation_id: undefined,
            start_date: true,
            complete_date: true,
            end_date: true,
            type: true,
            operator_tags: undefined,
        };
        if (!project.app_config?.block_order_form?.fields_requirement)
            return defaultFields;
        Object.entries(
            project.app_config.block_order_form.fields_requirement
        ).forEach(([key, value]) => {
            const fieldKey =
                key as keyof typeof project.app_config.block_order_form.fields_requirement;
            defaultFields[fieldKey] = value ?? undefined;
        });
        return defaultFields;
    }, [project]);

    const toast = useToast();
    const [localization] = useLocalization();
    const [loadingReviewTargets, setLoadingReviewTargets] = useState(false);

    const isEdit = !!blockOrderToEdit;

    const [formData, setFormData] = useState<InsertBlockOrderForm>({
        _id: blockOrderToEdit?._id ?? null,
        authorization_flow_id:
            startingData?.authorization_flow_id ??
            blockOrderToEdit?.authorization_flow_id ??
            null,
        authorization_config_ids:
            startingData?.authorization_config_ids ??
            blockOrderToEdit?.authorization_config_ids ??
            [],
        name: startingData?.name ?? blockOrderToEdit?.name ?? '',
        type: startingData?.type ?? blockOrderToEdit?.type ?? null,
        authorizations:
            startingData?.authorizations ??
            (blockOrderToEdit?.authorizations_data
                ? blockOrderToEdit.authorizations_data.map((a) => ({
                      _id: a._id,
                      name: a.name,
                      config_id: a.authorization_config_id,
                  }))
                : []),
        operator_tags:
            startingData?.operator_tags ??
            blockOrderToEdit?.operator_tags ??
            [],
        // start_date -> Date that the operation starts
        // start_hour -> (Optional) time which should be selected alongside complete_hour
        // complete_hour -> Time which the operation ends on the same day as the start date
        // end_date -> (Optional) field which indicates the unblocking date
        start_date:
            startingData?.start_date ?? blockOrderToEdit?.start_date ?? null,
        start_hour:
            startingData?.start_hour ?? blockOrderToEdit?.start_date ?? null,
        complete_hour:
            startingData?.complete_hour ??
            blockOrderToEdit?.complete_date ??
            null,
        end_date: startingData?.end_date ?? blockOrderToEdit?.end_date ?? null,
        operation_id:
            startingData?.operation_id ??
            blockOrderToEdit?.operation_id ??
            null,
    });

    const onUpsertOrder = () => {
        if (onInsert) onInsert();
        props.onHide();
    };

    const {
        insertBlockOrder,
        updateBlockOrder,
        status: upsertStatus,
    } = useUpsertBlockOrder({ onInsert: onUpsertOrder });

    const { dialog: reviewTargetsDialog, show: showReviewTargetsWarning } =
        useShowTargetCheckWarning();

    const [selectedAuthorizationFlow, setSelectedAuthorizationFlow] =
        useState<Ether.CaseManager.AuthorizationFlow | null>(null);
    const allowHourSelection =
        !!selectedAuthorizationFlow &&
        !selectedAuthorizationFlow.block_template_config?.complete_time &&
        !selectedAuthorizationFlow.block_template_config?.start_time;

    const validateInsert = async (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        const shouldVerifyReview =
            status === 'done' &&
            (blockOrderToEdit?.total_targets_review == null ||
                blockOrderToEdit.total_targets_review > 0);
        if (shouldVerifyReview) {
            setLoadingReviewTargets(true);

            const filters: CheckActionTargetWarningEP.Data =
                blockOrderToEdit?._id
                    ? {
                          block_order_id: blockOrderToEdit._id,
                          action: 'finish_block_order',
                      }
                    : {
                          authorization_ids: data.authorizations.map(
                              (a) => a._id
                          ),
                          operation_id: data.operation_id,
                          action: 'finish_block_order',
                      };

            try {
                const targets = await checkActionTargetWarning({
                    ...filters,
                });
                setLoadingReviewTargets(false);
                if (targets.length > 0) {
                    showReviewTargetsWarning({
                        targets,
                        onContinue: () => handleOnInsert(data, status),
                        action: 'finishBlockOrder',
                    });
                    return;
                } else handleOnInsert(data, status);
            } catch (err) {
                toast.show(getErrorToast((err as Error).message, localization));
                setLoadingReviewTargets(false);
            }
        } else {
            handleOnInsert(data, status);
        }
    };

    const handleOnInsert = (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        if (!data.authorization_flow_id) return;

        if (isDefaultOperation && formData.operation_id)
            updateUserDefaultOperation({
                operation_id: formData.operation_id,
            });

        const today = new Date();
        const start_date = formData.start_date
            ? new Date(formData.start_date)
            : null;
        let complete_date = null;
        if (allowHourSelection) {
            const { start_hour, complete_hour } = formData;
            complete_date = new Date(start_date ?? today);
            if (start_hour)
                start_date?.setHours(
                    start_hour.getHours(),
                    start_hour.getMinutes(),
                    0,
                    0
                );
            if (complete_hour)
                complete_date.setHours(
                    complete_hour.getHours(),
                    complete_hour.getMinutes(),
                    0,
                    0
                );
        }

        const uploadData: UpsertBlockOrderEP.InsertData = {
            authorization_flow_id: data.authorization_flow_id,
            authorization_config_ids: data.authorization_config_ids,
            project_id: project._id,
            name: data.name,
            type: data.type,
            authorization_ids: formData.authorizations.map((a) => a._id),
            operator_tags: formData.operator_tags,
            status: status,
            start_date: start_date,
            complete_date: complete_date,
            end_date: formData.end_date,
            operation_id: formData.operation_id,
        };
        if (data._id) {
            updateBlockOrder({
                _id: data._id,
                ...uploadData,
            });
        } else insertBlockOrder(uploadData);
    };

    const [isDefaultOperation, setIsDefaultOperation] = useState(false);

    const invalidTime =
        !!formData.start_hour &&
        !!formData.complete_hour &&
        formData.start_hour > formData.complete_hour;

    const isFormValid = useMemo(() => {
        // Static fields
        if (
            !formData.authorization_flow_id ||
            formData.authorization_config_ids.length <= 0 ||
            formData.authorizations.length <= 0
        )
            return false;

        if (invalidTime) return false;
        if (
            allowHourSelection &&
            fieldsRequirement.complete_date &&
            (!formData.start_hour || !formData.complete_hour)
        )
            return false;
        return Object.entries(fieldsRequirement).every(([key, required]) => {
            if (!required) return true;
            const fieldKey = key as keyof InsertBlockOrderForm;
            if (!(fieldKey in formData)) return true;
            const value = formData[fieldKey];
            if (Array.isArray(value) && value.length <= 0) return false;
            if (value === '' || value == null) return false;
            return true;
        });
    }, [invalidTime, allowHourSelection, fieldsRequirement, formData]);

    const upsertLoading = upsertStatus === 'pending' || loadingReviewTargets;

    return (
        <LightDialog
            {...props}
            header={
                !!blockOrderToEdit
                    ? localization.components.models.blockOrder.button.edit
                    : localization.components.models.blockOrder.button.new
            }
            style={{ minWidth: '50%' }}
            footer={
                <>
                    <Button
                        label={
                            localization.components.common.button.saveAndSubmit
                        }
                        severity='success'
                        onClick={() => validateInsert(formData, 'done')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={
                            localization.components.common.button.saveAndDraft
                        }
                        onClick={() => handleOnInsert(formData, 'draft')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                </>
            }
        >
            <div className='grid gap-2 grid-cols-1'>
                {reviewTargetsDialog}
                <h3>{localization.models.blockOrder.singular}</h3>
                <InputText
                    placeholder={
                        localization.components.models.blockOrder.views.insert
                            .inputBlockOrderName
                    }
                    required={fieldsRequirement.name}
                    label={localization.fields.blockOrder.name}
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                    validations={[
                        {
                            validationError:
                                localization.validations.generic.requiredName,
                            validate: (value) => {
                                if (!value || value === '') return false;
                                return true;
                            },
                        },
                    ]}
                    disabled={upsertLoading}
                    hidden={hideFields?.name}
                />
                {!hideFields?.authorization_flow_id && <Divider />}
                <SelectOneAuthorizationFlow
                    required
                    disabled={upsertLoading}
                    value={formData.authorization_flow_id}
                    onLoad={(flows) =>
                        setSelectedAuthorizationFlow(
                            flows.find(
                                (f) => f._id === formData.authorization_flow_id
                            ) ?? null
                        )
                    }
                    onChange={(flow) => {
                        setSelectedAuthorizationFlow(flow);
                        setFormData((old) => ({
                            ...old,
                            authorization_flow_id: flow?._id ?? null,
                            authorization_config_ids: [],
                            authorizations: [],
                        }));
                    }}
                    hidden={hideFields?.authorization_flow_id}
                />
                {!!selectedAuthorizationFlow && (
                    <>
                        {!hideFields?.operation_id && (
                            <>
                                <Divider />
                                <SelectOrCreateOperation
                                    operationId={formData.operation_id}
                                    setOperationId={(id) =>
                                        setFormData((old) => ({
                                            ...old,
                                            operation_id: id,
                                            // authorizations: [],
                                        }))
                                    }
                                    isDefaultOperation={isDefaultOperation}
                                    setIsDefaultOperation={(def) =>
                                        setIsDefaultOperation(def)
                                    }
                                    ignoreDefaultInit={isEdit}
                                    required={fieldsRequirement.operation_id}
                                />
                            </>
                        )}
                        {!hideFields?.dates && (
                            <>
                                <Divider />
                                <span>
                                    {
                                        localization.components.models
                                            .blockOrder.views.insert.selectDates
                                    }
                                </span>
                                <h3>
                                    {
                                        localization.fields.blockOrder
                                            .operationStartDate
                                    }
                                </h3>
                                <div className='flex flex-row gap-2'>
                                    <Datepicker
                                        label={
                                            localization.fields.blockOrder
                                                .operationDay
                                        }
                                        value={formData.start_date}
                                        onChange={(value) =>
                                            setFormData((old) => ({
                                                ...old,
                                                start_date: value,
                                            }))
                                        }
                                        type='date'
                                        required={fieldsRequirement.start_date}
                                    />
                                    {allowHourSelection && (
                                        <>
                                            <Datepicker
                                                label={
                                                    localization.fields
                                                        .blockOrder
                                                        .operationStartHour
                                                }
                                                value={formData.start_hour}
                                                onChange={(value) =>
                                                    setFormData((old) => ({
                                                        ...old,
                                                        start_hour: value,
                                                    }))
                                                }
                                                type='time'
                                                required={
                                                    fieldsRequirement.complete_date
                                                }
                                                error={invalidTime}
                                            />
                                            <span>-</span>
                                            <Datepicker
                                                label={
                                                    localization.fields
                                                        .blockOrder
                                                        .operationEndHour
                                                }
                                                value={formData.complete_hour}
                                                onChange={(value) =>
                                                    setFormData((old) => ({
                                                        ...old,
                                                        complete_hour: value,
                                                    }))
                                                }
                                                type='time'
                                                required={
                                                    fieldsRequirement.complete_date
                                                }
                                                error={invalidTime}
                                            />
                                        </>
                                    )}
                                </div>
                                {invalidTime && (
                                    <span className='text-red-500'>
                                        {
                                            localization.components.models
                                                .blockOrder.views.insert
                                                .invalidDate
                                        }
                                    </span>
                                )}
                                {!allowHourSelection && (
                                    <span>
                                        {localization.components.models.blockOrder.views.insert.noDateToSelect.replace(
                                            '{name}',
                                            selectedAuthorizationFlow?.name ??
                                                ''
                                        )}
                                    </span>
                                )}
                                <h3>
                                    {
                                        localization.fields.blockOrder
                                            .operationCompletionDate
                                    }
                                </h3>
                                <Datepicker
                                    label={
                                        localization.fields.blockOrder
                                            .operationDay
                                    }
                                    value={formData.end_date}
                                    onChange={(value) =>
                                        setFormData((old) => ({
                                            ...old,
                                            end_date: value,
                                        }))
                                    }
                                    type='datetime-local'
                                    required={fieldsRequirement.end_date}
                                />
                            </>
                        )}
                        {!hideFields?.authorization_config_ids && <Divider />}
                        <SelectManyAuthorizationConfigs
                            value={formData.authorization_config_ids}
                            onChange={(configs) => {
                                const authorizations =
                                    formData.authorizations.filter(
                                        (a) =>
                                            !!configs.find(
                                                (c) => c._id === a.config_id
                                            )
                                    );
                                setFormData((old) => ({
                                    ...old,
                                    authorization_config_ids: configs.map(
                                        (c) => c._id
                                    ),
                                    authorizations: authorizations,
                                }));
                            }}
                            disabled={upsertLoading}
                            queryOptions={{
                                devFilters: {
                                    authorization_flow_ids:
                                        formData.authorization_flow_id,
                                },
                            }}
                            required
                            hidden={hideFields?.authorization_config_ids}
                        />
                    </>
                )}
                {!!selectedAuthorizationFlow &&
                    formData.authorization_config_ids.length > 0 && (
                        <>
                            {!hideFields?.type && (
                                <>
                                    <Divider />
                                    <h3>{localization.common.type}</h3>
                                    <SelectOneBlockOrderType
                                        placeholder={
                                            localization.components.models
                                                .blockOrder.views.insert
                                                .selectTypePlaceholder
                                        }
                                        value={formData.type}
                                        onChange={(e) =>
                                            setFormData((old) => ({
                                                ...old,
                                                type: e?.value ?? 'judicial',
                                            }))
                                        }
                                        disabled={upsertLoading}
                                        required={fieldsRequirement.type}
                                    />
                                </>
                            )}
                            {!hideFields?.authorizations && <Divider />}
                            <SelectManyAuthorizations
                                value={formData.authorizations.map(
                                    (a) => a._id
                                )}
                                onChange={(authorizations) =>
                                    setFormData((old) => ({
                                        ...old,
                                        authorizations: authorizations.map(
                                            (a) => ({
                                                _id: a._id,
                                                name: a.name,
                                                config_id:
                                                    a.authorization_config_id,
                                            })
                                        ),
                                    }))
                                }
                                queryOptions={{
                                    devFilters: {
                                        authorization_config_id:
                                            formData.authorization_config_ids,
                                        authorization_flow_id:
                                            selectedAuthorizationFlow._id,
                                    },
                                    withoutBlockOrder: true,
                                }}
                                disabled={upsertLoading}
                                required
                                additionalItems={blockOrderToEdit?.authorizations_data?.filter(
                                    (a) =>
                                        !!formData.authorization_config_ids.find(
                                            (config) =>
                                                config ===
                                                a.authorization_config_id
                                        )
                                )}
                                hidden={hideFields?.authorizations}
                            />
                            {!hideFields?.operator_tags && <Divider />}
                            <SelectManyOperatorTags
                                value={formData.operator_tags}
                                onChange={(tags) =>
                                    setFormData((old) => ({
                                        ...old,
                                        operator_tags: tags.map((t) => t.value),
                                    }))
                                }
                                disabled={upsertLoading}
                                hidden={hideFields?.operator_tags}
                                required={fieldsRequirement.operator_tags}
                            />
                        </>
                    )}
            </div>
        </LightDialog>
    );
};

export default InsertBlockOrderModal;
