import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import LoadingMessage from 'components/misc/LoadingMessage';
import AuthorizationConfigDataTable from 'components/models/AuthorizationConfig/AuthorizationConfigDataTable';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import TagDataTable from 'components/models/Tag/TagDataTable';
import TargetsDataTable from 'components/models/Targets/TargetsDataTable';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import useInsertDocumentDialog from 'hooks/dialogs/document/useInsertDocumentDialog';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { useMemo } from 'react';
import { detailManyAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import { detailManyTags } from 'services/ether/case-manager/tags';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import { HomeButtonSet } from '../../components/HomeButtonSet';

/**
 * Home page view for the user with Admin role
 * @returns Functional Component for the home
 */
const AdminHome: React.FC = () => {
    const [localization] = useLocalization();
    const project = useProject();
    const metricsQuery = useMetricsQuery(project._id);

    const {
        showEdit: showEditDocument,
    } = useInsertDocumentDialog({
        type: 'document',
    });

    const {
        showEdit: showEditOfficialDoc,
    } = useInsertDocumentDialog({
        type: 'official_document',
    });

    const views: TableWithTabView[] = useMemo(
        () => [
            {
                fetchData: (params) =>
                    detailManyAuthorizationConfig({
                        project_id: project._id,
                        options: {
                            ...params.options,
                        },
                        signal: params.signal,
                    }),
                label: localization.models.authorizationConfig.plural,
                totalCount: metricsQuery.data?.total_authorization_configs ?? 0,
                tableElement: AuthorizationConfigDataTable,
                model: 'authorizationConfig',
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'official_document',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.oficio.plural,
                totalCount: metricsQuery.data?.total_official_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        onStartEdit={(doc) => showEditOfficialDoc(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            },
            {
                fetchData: (params) =>
                    detailManyTags({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'pirate-brand',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.tag.types.pirateBrand.plural,
                totalCount: metricsQuery.data?.total_pirate_brands ?? 0,
                tableElement: TagDataTable,
                model: 'tag',
            },
            {
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: params.options,
                        signal: params.signal,
                    }),
                label: localization.models.target.plural,
                totalCount: metricsQuery.data?.total_targets ?? 0,
                tableElement: (props) => (
                    <TargetsDataTable
                        projectId={project._id}
                        {...props}
                    />
                ),
                model: 'targets',
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        ...params,
                    }),
                label: localization.models.document.plural,
                totalCount: metricsQuery.data?.total_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        onStartEdit={(doc) => showEditDocument(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            },
        ],
        [
            localization,
            metricsQuery.data,
            project._id,
            showEditDocument,
            showEditOfficialDoc,
        ]
    );

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    return (
        <section>
            <div className='flex flex-row w-full justify-end gap-2 mb-1'>
                <HomeButtonSet
                    buttons={[
                        'add-file',
                        'add-officialDocument',
                        'search-target',
                    ]}
                />
            </div>
            <ViewTablesWithTabs
                mainModel={null}
                uniqueQueryKey='admin-home'
                views={views}
            />
        </section>
    );
};
export default AdminHome;
