import { useLocalization } from 'hooks/context/useLocalization';
import { useState } from 'react';
import { IntermediateFilter, UpdateFilterAction } from '../../types';
import { Button } from 'primereact/button';
import clipboard from 'clipboardy';
import { InputText } from 'primereact/inputtext';
import { StringMatchModeDropdown } from '../matchModesComponents';

/**
 * Component for the FIlterBox, being the ID/name filter search (the topmost filter with a search input)
 * @param object.filter - The filter object, without the label necessarily
 * @param object.onFilter - The onFilter dispatch action that will update the filter object
 * @param object.placeholder - Placeholder to display inside the input
 * @returns IDFilter element for the FilterBox
 */
const FilterBoxIDFilter: React.FC<{
    filter: IntermediateFilter;
    onFilter: UpdateFilterAction;
    placeholder?: string;
}> = ({ filter, onFilter, placeholder }) => {
    const [localization] = useLocalization();

    const [, { getPrimereactLocale }] = useLocalization();

    const primereact = getPrimereactLocale();

    const [temporary, setTemporary] = useState<IntermediateFilter>(filter);

    return (
        <div className='flex flex-row gap-2 w-full'>
            <Button
                icon='pi pi-clipboard'
                size='small'
                tooltip={localization.components.common.button.paste}
                onClick={() =>
                    clipboard
                        .read()
                        .then((data) =>
                            setTemporary((old) => ({ ...old, value: data }))
                        )
                }
            />
            <InputText
                value={
                    typeof temporary.value === 'string' ? temporary.value : ''
                }
                onChange={(e) =>
                    setTemporary((old) => ({
                        ...old,
                        value: e.target.value,
                    }))
                }
                onKeyDown={(e) => {
                    if (e.key === 'Enter')
                        onFilter({
                            ...temporary,
                        });
                }}
                placeholder={placeholder}
                className='w-80'
            />
            <StringMatchModeDropdown
                value={temporary.matchMode}
                onChange={(e) =>
                    setTemporary((old) => ({
                        ...old,
                        matchMode: e.value,
                    }))
                }
                className='w-40'
            />
            <Button
                label={primereact.apply}
                icon='pi pi-search'
                onClick={() =>
                    onFilter({
                        ...temporary,
                    })
                }
            />
            {filter.value !== '' && filter.value != null && (
                <Button
                    label={primereact.clear}
                    icon='pi pi-times'
                    onClick={() => {
                        setTemporary((old) => ({
                            ...old,
                            value: '',
                        }));
                        onFilter({
                            ...filter,
                            matchMode: temporary.matchMode,
                            value: null,
                        });
                    }}
                />
            )}
        </div>
    );
};

export default FilterBoxIDFilter;
