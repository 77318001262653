import { SelectInternalProps, SelectManyItemsProps } from '../types';
import BaseSelectItems from '../BaseSelectItems';

function SelectManyItems<T extends Record<string, any>>(
    props: React.PropsWithChildren<
        SelectInternalProps<T> & SelectManyItemsProps<T>
    >
) {
    return <BaseSelectItems {...props} type='many' />;
}

export default SelectManyItems;
