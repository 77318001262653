import { DateBadge } from 'components/ethercity-primereact';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import TargetsDataTable from 'components/models/Targets/TargetsDataTable';
import { useLocalization } from 'hooks/context/useLocalization';
import CellGroup from 'components/display/CellGroup';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import LoadingMessage from 'components/misc/LoadingMessage';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import { DetailedAuthorizationConfigViewProps } from './types';
import { detailManyTags } from 'services/ether/case-manager/tags';
import TagDataTable from 'components/models/Tag/TagDataTable';
import GoBackButton from 'components/misc/GoBackButton';
import CompanyDataTable from 'components/models/Company/CompanyDataTable';
import { detailOperatorCompany } from 'services/ether/case-manager/company';
import NotFound from 'components/page/NotFound';

const DetailedAuthorizationConfigView: React.FC<
    DetailedAuthorizationConfigViewProps
> = ({
    authorizationConfig,
    isLoading,
    error,

    onRefresh,
    hideBackButton,
    hideViewAll,

    tokenData,
}) => {
    const [localization] = useLocalization();

    const projectContext = useProjectMaybe();
    const project = tokenData?.project ?? projectContext;
    if (!project) throw new Error('missing project');

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!authorizationConfig) return <NotFound />;

    return (
        <section className='w-full'>
            {/* {editAuthorizationVisible && (
                <InsertAuthorizationModal
                    visible={editAuthorizationVisible}
                    authorizationToEdit={detailedAuthorization}
                    onHide={hideEdit}
                    onInsert={onRefresh}
                />
            )} */}
            <div style={{ display: 'flex', marginTop: '8px' }}>
                {!hideBackButton && <GoBackButton />}

                <div
                    style={{ display: 'flex', marginLeft: 'auto', gap: '8px' }}
                >
                    {/* {!hideViewAll && (
                        <Link to='..'>
                            <Button
                                label={
                                    localization.components
                                        .authorizationConfigButton.viewAll
                                }
                            />
                        </Link>
                    )} */}
                    {/* {permissions.exportTargets && (
                        <Button
                            icon='pi pi-download'
                            onClick={exportTargets}
                            label={localization.components.models.target.button.export}
                            loading={isExportingTargets}
                        />
                    )} */}
                    {/* {permissions.exportAuthorizationFiles && (
                        <Button
                            icon='pi pi-download'
                            onClick={exportAuthFiles}
                            label={localization.components.common.button.exportFiles}
                            loading={isExportingAuthFiles}
                        />
                    )} */}
                </div>
            </div>
            <section className='grid grid-cols-3 gap-16 items-start my-5'>
                <div>
                    <span>{project.name}</span>
                    <h2 className='mt-1'>{authorizationConfig.name}</h2>
                    {/* <div className='flex gap-4'>
                        {authorizationConfig.pirate_brands.map((pt) => (
                            <Badge key={pt} value={pt} />
                        ))}
                    </div> */}
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: '16px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginTop: '16px',
                        }}
                    >
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorizationConfig
                                            .created
                                    }
                                    :
                                </span>
                            }
                            element2={
                                <DateBadge
                                    value={authorizationConfig.created_at}
                                />
                            }
                        />
                    </div>
                </div>
            </section>
            <ViewTablesWithTabs
                mainModel='authorization-config'
                uniqueQueryKey={authorizationConfig._id}
                views={[
                    // {
                    //     label: localization.models.evidence.plural,
                    //     fetchData: (params) =>
                    //         detailEvidencesFromProject({
                    //             project_id: project._id,
                    //             options: {
                    //                 authorization_id: detailedAuthorization._id,
                    //                 ...params.options,
                    //             },
                    //             signal: params.signal,
                    //         }),
                    //     totalCount: detailedAuthorization.total_evidences ?? 0,
                    //     tableElement: (props) => (
                    //         <EvidencesDatatable {...props} />
                    //     ),
                    //     baseFilters: evidenceFilters,
                    // },
                    // {
                    //     label: localization.models.document.plural,
                    //     fetchData: (params) =>
                    //         detailManyDocuments({
                    //             project_id: project._id,
                    //             options: {
                    //                 // authorization_id: detailedAuthorization._id,
                    //                 ...params.options,
                    //             },
                    //             signal: params.signal,
                    //         }),
                    //     totalCount: -1,
                    //     tableElement: (props) => (
                    //         <DocumentDataTable {...props} />
                    //     ),
                    //     baseFilters: documentFilters,
                    // },
                    {
                        fetchData: (params) =>
                            detailManyDocuments({
                                project_id: project._id,
                                options: {
                                    ...params.options,
                                    devFilters: {
                                        authorization_config_id:
                                            authorizationConfig._id,
                                    },
                                },
                                signal: params.signal,
                            }),
                        label: localization.models.document.plural,
                        totalCount: authorizationConfig.total_documents ?? 0,
                        tableElement: DocumentDataTable,
                        model: 'document',
                    },
                    {
                        fetchData: (params) =>
                            detailManyDocuments({
                                project_id: project._id,
                                options: {
                                    ...params.options,
                                    devFilters: {
                                        category: 'official_document',
                                        authorization_config_id:
                                            authorizationConfig._id,
                                    },
                                },
                                signal: params.signal,
                            }),
                        label: localization.models.oficio.plural,
                        totalCount:
                            authorizationConfig.total_official_documents ?? 0,
                        tableElement: DocumentDataTable,
                        model: 'document',
                    },
                    {
                        fetchData: (params) =>
                            detailManyTags({
                                project_id: project._id,
                                options: {
                                    ...params.options,
                                    devFilters: {
                                        category: 'pirate-brand',
                                        authorization_config_id:
                                            authorizationConfig._id,
                                    },
                                },
                                signal: params.signal,
                            }),
                        label: localization.models.tag.types.pirateBrand.plural,
                        totalCount: authorizationConfig.pirate_brands.length,
                        tableElement: TagDataTable,
                        model: 'tag',
                    },
                    {
                        fetchData: (params) =>
                            detailManyTargets({
                                project_id: project._id,

                                options: {
                                    ...params.options,
                                    devFilters: {
                                        authorization_config_id:
                                            authorizationConfig._id,
                                        get_evidence_parsed: false,
                                    },
                                },
                                signal: params.signal,
                            }),
                        label: localization.models.target.plural,
                        totalCount: authorizationConfig.total_targets ?? 0,
                        tableElement: (props) => (
                            <TargetsDataTable
                                projectId={project._id}
                                onValidation={onRefresh}
                                tokenData={tokenData}
                                {...props}
                            />
                        ),
                        model: 'targets',
                    },
                    {
                        fetchData: (params) =>
                            detailOperatorCompany({
                                project_id: project._id,
                                options: {
                                    ...params.options,
                                    devFilters: {
                                        authorization_config_id:
                                            authorizationConfig._id,
                                    },
                                },
                                signal: params.signal,
                            }),
                        label: localization.models.operator.plural,
                        totalCount: authorizationConfig.total_operators ?? 0,
                        tableElement: (props) => (
                            <CompanyDataTable
                                {...props}
                                showOperatorCount
                                authorizationConfigId={authorizationConfig._id}
                                showExpand='operator'
                            />
                        ),
                        overrideFilters: {},
                        model: 'company',
                    },
                ]}
            />
        </section>
    );
};

export default DetailedAuthorizationConfigView;
