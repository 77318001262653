import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useCreateOperationDialog from 'hooks/models/operation/useCreateOperationDialog';
import { Button } from 'primereact/button';

/**
 * Button to show the search targets dialog, with simplified view
 * Respects permissions and hides if user has no permissions to view and search targets
 * @returns Button element
 */
const InsertOperationButton = () => {
    const [localization] = useLocalization();
    const { element: UpsertDialog, show } = useCreateOperationDialog();
    const { permissions } = useAuth();

    if (!permissions.insertOperation) return null;

    return (
        <>
            <UpsertDialog />
            <Button
                icon='pi pi-plus'
                label={localization.components.models.operation.button.new}
                onClick={show}
            />
        </>
    );
};

export default InsertOperationButton;
