import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

// TODO: UNUSED MONITORING BADGE
const MonitoringStatusBadge: React.FC<{
    monitor: any;
    badgeProps?: BadgeProps;
}> = ({ monitor, badgeProps }) => {
    const delta = Date.now() - (monitor.created_at as Date).getTime();
    const [localization] = useLocalization();
    badgeProps = {
        ...badgeProps,
        severity: 'warning',
        value: localization.components.models.monitoring.badge.status.processing.toLocaleUpperCase(),
    };
    // end longs within 15 seconds
    if (delta >= 13200) {
        badgeProps.severity = 'success';
        badgeProps.value =
            localization.components.models.monitoring.badge.status.done.toLocaleUpperCase();
    }
    return <Badge {...badgeProps} />;
};

export default MonitoringStatusBadge;
