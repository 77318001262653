import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useSearchTargetsDialog from 'hooks/dialogs/target/useShowSearchTargetsDialog';
import { Button } from 'primereact/button';

/**
 * Button to show the search targets dialog, with simplified view
 * Respects permissions and hides if user has no permissions to view and search targets
 * @returns Button element
 */
const SearchTargetsButton = () => {
    const [localization] = useLocalization();
    const { dialog, show } = useSearchTargetsDialog();
    const { permissions } = useAuth();

    if (!permissions.viewTargets) return null;

    return (
        <>
            {dialog}
            <Button
                label={
                    localization.components.models.target.button.searchTargets
                }
                onClick={show}
                icon='pi pi-search'
            />
        </>
    );
};

export default SearchTargetsButton;
