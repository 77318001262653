import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { ObjectDisplayModal } from 'components/ethercity-primereact';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useTagColumnFields from 'hooks/columns/models/useTagColumnFields';

type DetailedModel = Ether.CaseManager.Tag.Detailed;

const TagMenu: React.FC<{
    config: DetailedModel;
    // onShowDetail: (authorization: Ether.CaseManager.Tag) => void;
}> = ({ config }) => {
    // const { user, permissions } = useAuth();
    // const navigate = useNavigate();
    // const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        // ...(permissions.debugAuthorizations
        //     ? [
        //           {
        //               id: 'debug',
        //               label: 'Detail data',
        //               command: () => onShowDetail(authorization),
        //           },
        //       ]
        //     : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const TagDataTable: React.FC<
    DataTableProps<DetailedModel[]> &
        Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const { columnFields, filterOptions } = useTagColumnFields();

    const [viewAuthorizationDialogOptions, setViewAuthorizationDialogOptions] =
        useState<{
            visible: boolean;
            data: Ether.CaseManager.Tag | null;
        }>({
            visible: false,
            data: null,
        });

    // const onAuthorizationViewModalShow = (
    //     data: Ether.CaseManager.Tag
    // ) =>
    //     setViewAuthorizationDialogOptions({
    //         visible: true,
    //         data: data,
    //     });

    const onAuthorizationViewModalHide = () =>
        setViewAuthorizationDialogOptions({
            data: null,
            visible: false,
        });

    const baseFilterOptions = {
        showAddButton: false,
        showFilterOperator: false,
    };

    return (
        <>
            <ObjectDisplayModal
                header={viewAuthorizationDialogOptions.data?.name}
                visible={viewAuthorizationDialogOptions.visible}
                displayData={viewAuthorizationDialogOptions.data}
                onHide={onAuthorizationViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                lazy
                columnConfigName='tag'
                emptyMessage={localization.components.models.tag.datatable.empty}
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(rowData: DetailedModel) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                        />
                    )}
                    {...baseFilterOptions}
                />
                <Column
                    field={columnFields['official_documents'].name}
                    header={localization.models.oficio.plural}
                    body={(rowData: DetailedModel) =>
                        (rowData.documents_data?.length ?? 0) <= 0 ? (
                            '-'
                        ) : (
                            <div className='flex flex-col'>
                                {rowData.documents_data?.map((d) => (
                                    <span key={d._id}>{d.file.name}</span>
                                ))}
                            </div>
                        )
                    }
                />
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) =>
                        data.total_targets
                    }
                />
                <Column
                    field='actions'
                    body={(rowData: DetailedModel) => (
                        <TagMenu config={rowData} />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default TagDataTable;
