import { Dropdown } from "components/ethercity-primereact";
import { useLocalization } from "hooks/context/useLocalization";
import { FilterMatchMode } from "primereact/api";
import { DropdownProps } from "primereact/dropdown";

export const DateMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.dateIs,
            value: FilterMatchMode.DATE_IS,
        },
        {
            label: primereact.dateIsNot,
            value: FilterMatchMode.DATE_IS_NOT,
        },
        {
            label: primereact.dateBefore,
            value: FilterMatchMode.DATE_BEFORE,
        },
        {
            label: primereact.dateAfter,
            value: FilterMatchMode.DATE_AFTER,
        },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};

export const NumberMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.equals,
            value: FilterMatchMode.EQUALS,
        },
        {
            label: primereact.notEquals,
            value: FilterMatchMode.NOT_EQUALS,
        },
        { label: primereact.gt, value: FilterMatchMode.GREATER_THAN },
        {
            label: primereact.gte,
            value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        { label: primereact.lt, value: FilterMatchMode.LESS_THAN },
        { label: primereact.lte, value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};

export const StringMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.startsWith,
            value: FilterMatchMode.STARTS_WITH,
        },
        {
            label: primereact.contains,
            value: FilterMatchMode.CONTAINS,
        },
        {
            label: primereact.notContains,
            value: FilterMatchMode.NOT_CONTAINS,
        },
        {
            label: primereact.endsWith,
            value: FilterMatchMode.ENDS_WITH,
        },
        {
            label: primereact.equals,
            value: FilterMatchMode.EQUALS,
        },
        {
            label: primereact.notEquals,
            value: FilterMatchMode.NOT_EQUALS,
        },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};