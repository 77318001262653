import { useState } from 'react';

import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { useAuth } from 'hooks/context/useAuth';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useDetailAuthorization from 'hooks/queries/authorization/useDetailAuthorization';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import GoBackButton from 'components/misc/GoBackButton';
import ButtonExportAuthorizationTarget from 'components/models/Authorization/ButtonExportAuthorizationTarget';
import CacheControl from 'controller/cache/cacheController';
import MergeAuthorizationForm from 'components/models/Authorization/MergeAuthorizationForm';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';
import { Navigate } from 'react-router-dom';
import useAuthorizationColumnFields from 'hooks/columns/models/useAuthorizationColumnFields';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';

const ListAuthorizations = () => {
    const project = useProject();
    const [localization] = useLocalization();
    const { permissions, user } = useAuth();

    const { filterMetaData } = useAuthorizationColumnFields();

    const [isMergeDialogVisible, setIsMergeDialogVisible] = useState(false);

    const {
        element: UpsertDialog,
        showCreate,
        showEdit,
    } = useUpsertAuthorizationDialog();

    const { filters, pageOptions, sort, setFilters, setPageOptions, setSort } =
        useInitDataTableState({
            filters: filterMetaData,
            sort: {
                field: 'created_at',
                order: SortOrder.DESC,
            },
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']
                        ?.authorization,
            },
        });

    const authorizationsQuery = useDetailAuthorization({
        project_id: project._id,
        options: {
            rawFilters : filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            sort,
        },
    });

    const onAuthorizationMergeModalShow = () => setIsMergeDialogVisible(true);
    const onAuthorizationMergeModalHide = () => setIsMergeDialogVisible(false);

    if (!permissions.detailAuthorizations || user.role === 'authorizer-strict')
        return <Navigate to='/' />;

    return (
        <section>
            <UpsertDialog />
            {isMergeDialogVisible && (
                <MergeAuthorizationForm
                    visible={true}
                    onHide={onAuthorizationMergeModalHide}
                />
            )}
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <h2>
                    {project.name} - {localization.models.authorization.plural}
                </h2>
                <div className='flex flex-row gap-2 ml-auto'>
                    {permissions.exportAuthorizationTarget && (
                        <ButtonExportAuthorizationTarget />
                    )}
                    {permissions.insertAuthorizations && (
                        <Button
                            label={
                                localization.components.models.authorization.button.new
                            }
                            icon='pi pi-plus'
                            onClick={showCreate}
                        />
                    )}
                    {permissions.joinAuthorizations && (
                        <Button
                            label={localization.components.models.authorization.views.join.title}
                            icon='pi pi-arrow-down-left-and-arrow-up-right-to-center'
                            onClick={onAuthorizationMergeModalShow}
                        />
                    )}
                </div>
            </div>
            <AuthorizationDataTable
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort(
                        e.sortField
                            ? {
                                  field: e.sortField,
                                  order: e.sortOrder,
                              }
                            : null
                    )
                }
                loading={authorizationsQuery.isFetching}
                value={authorizationsQuery.data?.payload}
                setFilters={(e) => setFilters(e)}
                filters={filters}
                showApproved
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (authorizationsQuery.data?.payload.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: authorizationsQuery.refresh,
                }}
                onStartEdit={showEdit}
            />
        </section>
    );
};

export default ListAuthorizations;
