import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { useProject } from 'hooks/context/project/useProject';
import LoadingMessage from 'components/misc/LoadingMessage';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyAuthorization } from 'services/ether/case-manager/authorizations';
import { SortOrder } from 'primereact/api';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import { HomeButtonSet } from '../../components/HomeButtonSet';

/**
 * Home page view for the user with Analyst role, as known as Analista/Lab
 * @returns Functional Component for the home
 */
const AnalystHome = () => {
    const project = useProject();
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const metricsQuery = useMetricsQuery(project._id);

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    const views: TableWithTabView[] = [];

    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    if (permissions.viewAuthorizations) {
        views.push(
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                        },
                    }),
                label: localization.models.authorization.plural,
                totalCount: metricsQuery.data?.total_authorizations,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'draft',
                            },
                        },
                    }),
                label: authorizationBadge.draftWithName,
                totalCount: metricsQuery.data?.authorization_status_draft,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'pending',
                            },
                        },
                    }),
                label: authorizationBadge.pendingWithName,
                totalCount: metricsQuery.data?.authorization_status_pending,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'approved',
                            },
                        },
                    }),
                label: authorizationBadge.doneWithName,
                totalCount: metricsQuery.data?.authorization_status_approved,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            }
        );
    }

    return (
        <>
            <p>
                {localization.components.views.homeDashboard.analystDescription}
            </p>
            <div className='ml-auto flex justify-between items-center gap-2'>
                <span className='ml-auto' />
                <HomeButtonSet
                    buttons={[
                        'insert-authorization',
                        'list-authorization',
                        'search-target',
                    ]}
                />
            </div>
            <ViewTablesWithTabs
                mainModel={null}
                uniqueQueryKey='home'
                views={views}
            />
        </>
    );
};

export default AnalystHome;
