import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useAuthorizationConfigColumnFields from 'hooks/columns/models/useAuthorizationConfigColumnFields';

const AuthorizationConfigMenu: React.FC<{
    config: Ether.CaseManager.AuthorizationConfig.Detailed;
    // onShowDetail: (authorization: Ether.CaseManager.AuthorizationConfig) => void;
}> = ({ config }) => {
    // const { user, permissions } = useAuth();
    // const navigate = useNavigate();
    // const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        // ...(permissions.debugAuthorizations
        //     ? [
        //           {
        //               id: 'debug',
        //               label: 'Detail data',
        //               command: () => onShowDetail(authorization),
        //           },
        //       ]
        //     : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const AuthorizationConfigDataTable: React.FC<
    DataTableProps<Ether.CaseManager.AuthorizationConfig.Detailed[]> &
        Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const {columnFields, filterOptions} = useAuthorizationConfigColumnFields();

    const [viewAuthorizationDialogOptions, setViewAuthorizationDialogOptions] =
        useState<{
            visible: boolean;
            data: Ether.CaseManager.AuthorizationConfig | null;
        }>({
            visible: false,
            data: null,
        });

    // const onAuthorizationViewModalShow = (
    //     data: Ether.CaseManager.AuthorizationConfig
    // ) =>
    //     setViewAuthorizationDialogOptions({
    //         visible: true,
    //         data: data,
    //     });

    const onAuthorizationViewModalHide = () =>
        setViewAuthorizationDialogOptions({
            data: null,
            visible: false,
        });

    return (
        <>
            <ObjectDisplayModal
                header={viewAuthorizationDialogOptions.data?.name}
                visible={viewAuthorizationDialogOptions.visible}
                displayData={viewAuthorizationDialogOptions.data}
                onHide={onAuthorizationViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                lazy
                columnConfigName='authorizationConfig'
                emptyMessage={
                    localization.components.models.authorizationConfig.datatable
                        .empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(
                        rowData: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                            navigateTo={`/authorization-config/${rowData._id}`}
                        />
                    )}
                />
                <Column
                    field={columnFields['authorizers_count'].name}
                    header={localization.fields.authorizationConfig.authorizers}
                    body={(
                        rowData: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => rowData.authorizer_ids.length}
                />
                <Column
                    field={columnFields['pirate_brands_count'].name}
                    header={localization.models.tag.types.pirateBrand.plural}
                    body={(
                        data: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => data.pirate_brands.length}
                />
                <Column
                    field={columnFields['documents_count'].name}
                    header={localization.models.document.plural}
                    body={(
                        data: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => data.total_documents}
                />
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(
                        data: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => data.total_targets}
                />
                <Column
                    field={columnFields['created_at'].name}
                    header={localization.fields.authorizationConfig.created}
                    body={(rowData: Ether.CaseManager.AuthorizationConfig) => (
                        <DateBadge value={rowData.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(
                        rowData: Ether.CaseManager.AuthorizationConfig.Detailed
                    ) => <AuthorizationConfigMenu config={rowData} />}
                />
            </CMDataTable>
        </>
    );
};

export default AuthorizationConfigDataTable;
