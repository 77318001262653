import { useLocalization } from 'hooks/context/useLocalization';
import { ListAuthorizationEP } from 'services/ether/case-manager/authorizations/types';
import { useProject } from 'hooks/context/project/useProject';
import useListAuthorization from 'hooks/queries/authorization/useListAuthorization';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SortOrder } from 'primereact/api';

const SelectOneAuthorization: React.FC<
    SelectOneItemProps<
        Ether.CaseManager.Authorization,
        ListAuthorizationEP.Filters.Options
    >
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useListAuthorization(
        {
            project_id: project._id,
            options: {
                sort: { field: 'name', order: SortOrder.ASC },
                limit: 0,
                ...queryOptions,
            },
        },
        { enabled: !props.overrideItems }
    );

    return (
        <SelectOneItem
            title={localization.models.authorization.singular}
            placeholder={
                localization.components.models.authorization.form
                    .selectOnePlaceholder
            }
            label={localization.models.authorization.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneAuthorization;
