import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { DateBadge } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { downloadDocumentFile } from 'services/ether/case-manager/documents';
import { Badge } from 'primereact/badge';
import { getFileModelName, handleFileModelDownload } from 'utils/file';
import useFileWrapperDialog from 'hooks/dialogs/general/useFileViewerDialog';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import useDocumentColumnFields from 'hooks/columns/models/useDocumentColumnFields';

type DetailedModel = Ether.CaseManager.Document.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShow: (item: DetailedModel) => void;
    onShowDetail: (item: DetailedModel) => void;
    onStartEdit?: (item: DetailedModel) => void;
}> = ({ item, onShow, onShowDetail, onStartEdit }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const onDownload = () => {
        downloadDocumentFile(item._id).then((data) =>
            handleFileModelDownload({
                file: data.file,
                item: item,
                extension: data.extension,
            })
        );
    };

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.downloadDocuments
            ? [
                  {
                      id: 'view',
                      label: localization.components.common.menu.view,
                      command: () => onShow(item),
                  },
              ]
            : []),
        ...(permissions.downloadDocuments
            ? [
                  {
                      id: 'download',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.download,
                      command: () => onDownload(),
                  },
              ]
            : []),
        ...(permissions.insertDocuments && onStartEdit
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onStartEdit(item),
                  },
              ]
            : []),
        ...(permissions.debugDocuments
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.datatable
                          .menuDetailItem,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const DocumentDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Document[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            token?: string;
            onStartEdit?: (doc: DetailedModel) => void;
        }
> = ({ token, onStartEdit, ...props }) => {
    const [localization] = useLocalization();

    const { dialog: fileWrapperDialog, showDocument: renderFileDialog } =
        useFileWrapperDialog();
    const { dialog: objectDisplayDialog, show: renderObjectDialog } =
        useShowObjectDisplayModal();
    const showFile = (data: DetailedModel) => {
        renderFileDialog({
            header: getFileModelName(data) ?? '',
            modelId: data._id,
            token: token,
        });
    };

    const { columnFields, filterOptions } = useDocumentColumnFields();

    const showObject = (data: DetailedModel) => {
        renderObjectDialog({
            header: getFileModelName(data) ?? '',
            data: data,
        });
    };

    const sortable = 'sortField' in props;

    return (
        <>
            {fileWrapperDialog}
            {objectDisplayDialog}
            <CMDataTable
                columnConfigName='document'
                lazy
                emptyMessage={
                    localization.components.models.document.datatable.empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(data: DetailedModel) => {
                        let name: string | undefined;
                        if ('authorizations_data' in data)
                            name = data.authorizations_data?.[0]?.name;
                        return (
                            <NameIdAggregator
                                name={getFileModelName(data) ?? ''}
                                id={data._id}
                                association={name}
                                onClick={() => showFile(data)}
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['type'].name}
                    header={localization.fields.document.type}
                    body={(rowData: Ether.CaseManager.Document) => {
                        return (
                            <Badge
                                value={rowData.type?.toLocaleUpperCase() ?? '-'}
                                severity='info'
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['process_document.document_date'].name}
                    sortable={sortable}
                    header={localization.fields.document.documentDate}
                    body={(data: Ether.CaseManager.Document) =>
                        data.process_document?.document_date ? (
                            <DateBadge
                                value={data.process_document.document_date}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
                <Column
                    field={columnFields['created_at'].name}
                    sortable={sortable}
                    header={localization.fields.document.createdAt}
                    body={(data: Ether.CaseManager.Document) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShow={(item) => showFile(item)}
                            onShowDetail={(item) => showObject(item)}
                            onStartEdit={onStartEdit}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default DocumentDataTable;
