import { useLocalization } from 'hooks/context/useLocalization';
import { IntermediateFilter } from '../../types';
import { DropdownProps } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DateMatchModeDropdown } from '../matchModesComponents';
import { DispatchApplyFilter } from '../FilterBoxComponent';
import { Datepicker } from 'components/ethercity-primereact';

/**
 * Datepicker filter for the FilterBox
 * @param object.temporaryFilter - The raw temporary filter object that includes which value and matchMode is using
 * @param object.setTemporaryFilter - The set dispatch action that will update the temporary raw filter object
 * @param object.applyFilter - The action that will apply the input/selected value and label to the final filter, passing alongside the HTML event
 * @param object.hideMatchMode - If true, the MatchMode will not be selectable
 * @param object.dropdownProps - Props for the matchMode dropdown select
 * @returns Datepickers filter element
 */
const FilterBoxDatepickerFilter: React.FC<{
    temporaryFilter: IntermediateFilter;
    setTemporaryFilter: React.Dispatch<
        React.SetStateAction<IntermediateFilter>
    >;
    applyFilter: DispatchApplyFilter;
    dropdownProps: DropdownProps;
    hideMatchMode?: boolean;
}> = ({
    temporaryFilter,
    setTemporaryFilter,
    applyFilter,
    dropdownProps,
    hideMatchMode,
}) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const apply = (e: React.SyntheticEvent) => {
        applyFilter({
            event: e,
            filters: {
                ...temporaryFilter,
            },
        });
        setTemporaryFilter((old) => ({
            ...old,
            value: null,
        }));
    };

    return (
        <div className='flex flex-col gap-2 w-56'>
            {!hideMatchMode && <DateMatchModeDropdown {...dropdownProps} />}
            <Datepicker
                wrapperStyle={{
                    width: '100%',
                }}
                style={{
                    width: '100%',
                }}
                value={
                    temporaryFilter.value == null ||
                    temporaryFilter.value === '' ||
                    typeof temporaryFilter.value === 'string'
                        ? null
                        : (temporaryFilter.value as Date)
                }
                onChange={(e) => {
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: e,
                    }));
                }}
            />
            <Button label={primereact.apply} onClick={apply} />
        </div>
    );
};

export default FilterBoxDatepickerFilter;
