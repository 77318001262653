import { useProject } from 'hooks/context/project/useProject';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useDetailManyOperation from 'hooks/queries/operation/useDetailManyOperation';
import OperationDataTable from 'components/models/Operation/OperationDataTable';
import { SortOrder } from 'primereact/api';
import useOperationColumnFields from 'hooks/columns/models/useOperationColumnFields';
import SearchTargetsButton from 'components/models/Targets/SearchTargetsButton';
import InsertOperationButton from 'components/models/Operation/InsertOperationButton';

const ListOperations = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filterMetaData: operationFilters } = useOperationColumnFields();

    const { filters, pageOptions, setFilters, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: operationFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.operation,
            },
            sort: {
                field: 'created_at',
                order: SortOrder.DESC,
            },
        });

    const query = useDetailManyOperation({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            sort,
        },
    });


    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div className='flex gap-2 items-center'>
                <h2>
                    {project.name} - {localization.models.operation.plural}
                </h2>
                <div className='flex flex-row gap-2 ml-auto'>
                    <InsertOperationButton />
                    <SearchTargetsButton />
                </div>
            </div>
            <OperationDataTable
                loading={query.isLoading}
                value={query.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        !query.data ||
                        query.data.payload.length < pageOptions.rows,
                    onRefresh: query.refresh,
                }}
            />
        </section>
    );
};

export default ListOperations;
