import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';
import { useProject } from 'hooks/context/project/useProject';
import {
    getDefaultTypeOptions,
    getOnOffOptions,
    getSafeIpOptions,
    getYesNoOptions,
} from 'components/models/Targets/TargetsDataTable/utils';
import FilterBoxMultiselectPirateBrand from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiselectTagPirateBrand';
import FilterBoxDropdownTagCountry from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagCountry';
import FilterBoxDropdownTagFlag from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagFlag';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['targets']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['targets']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Target DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useTargetColumnFields = () => {
    const [localization] = useLocalization();
    const project = useProject();

    const filterFields = useMemo(() => {
        const defaultTypeOptions = getDefaultTypeOptions(localization);
        const onOffOptions = getOnOffOptions(localization);
        const safeIpOptions = getSafeIpOptions(localization);
        const yesNoOptions = getYesNoOptions(localization);

        const fields = localization.fields.target;
        // TODO: [EMEA NAME] REMOVE LATER
        const isEmeaProject = project.name.toLowerCase().includes('emea');
        const datatableLocale = localization.components.models.target.datatable;

        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectValue,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            type: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.type,
                    placeholder: datatableLocale.selectType,
                    type: 'dropdown',
                    selectOptions: defaultTypeOptions,
                    relatedColumn: 'type',
                },
            },
            'list_data.country': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.country,
                    type: 'string',
                    hideMatchMode: true,
                    relatedColumn: 'list_data.country',
                },
            },
            'list_data.highest_grading': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.grading,
                    placeholder: datatableLocale.inputGrading,
                    type: 'number',
                    relatedColumn: 'list_data.highest_grading',
                },
            },
            'list_data.official_domain': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.officialDomain,
                    type: 'dropdown',
                    selectOptions: yesNoOptions,
                    relatedColumn: 'list_data.official_domain',
                },
            },
            'list_data.reserved_ip': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.reservedIp,
                    type: 'dropdown',
                    selectOptions: yesNoOptions,
                    relatedColumn: 'list_data.reserved_ip',
                },
            },
            'list_data.safe_ip': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.safeIp,
                    type: 'dropdown',
                    selectOptions: safeIpOptions,
                    relatedColumn: 'list_data.safe_ip',
                },
            },
            'external_services_data.onoff': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.onoffStatus,
                    type: 'dropdown',
                    selectOptions: onOffOptions,
                    relatedColumn: 'external_services_data.onoff',
                },
            },

            omit_pre_reprove: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'multiselect',
                    label: localization.fields.target.omitPreRejected,
                    selectOptions: [
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.recently_notified,
                            value: 'recently_notified',
                        },
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.same_operation,
                            value: 'same_operation',
                        },
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.unreversible,
                            value: '_cm_app_unreversible',
                        },
                    ],
                    relatedColumn: '_cm_name_id',
                },
            },
            notified: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.notified,
                    type: 'dropdown',
                    selectOptions: yesNoOptions,
                    relatedColumn: 'notified',
                },
            },
            _cmapp_tags_pirate_brand: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'custom',
                    label: localization.models.tag.types.pirateBrand.plural,
                    element: FilterBoxMultiselectPirateBrand,
                    initialValue: [],
                    relatedColumn: 'tags',
                },
            },
        };

        if (isEmeaProject) {
            filterFields['_cmapp_tags_country'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.country.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagCountry,
                    initialValue: null,
                },
            };
            filterFields['_cmapp_tags_flag'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.flag.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagFlag,
                    initialValue: null,
                },
            };
        }

        return filterFields;
    }, [project, localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            type: {
                name: 'type',
            },
            authorization_tags: {
                name: 'authorization_tags',
            },
            enrichment_status: {
                name: 'enrichment_status',
            },
            created_at: {
                name: 'created_at',
            },
            authorizations_count: {
                name: 'authorizations_count',
            },
            block_orders_count: {
                name: 'block_orders_count',
            },
            type_order: {
                name: 'type_order',
            },

            'list_data.accessible_ports': {
                name: 'list_data.accessible_ports',
            },
            'list_data.asn': {
                name: 'list_data.asn',
            },
            'list_data.br_domains_count': {
                name: 'list_data.br_domains_count',
            },
            'list_data.country': {
                name: 'list_data.country',
            },
            'list_data.highest_grading': {
                name: 'list_data.highest_grading',
            },
            'list_data.hosted_domains_count': {
                name: 'list_data.hosted_domains_count',
            },
            'list_data.hosted_domains_list': {
                name: 'list_data.hosted_domains_list',
            },
            'list_data.official_domain': {
                name: 'list_data.official_domain',
            },
            'list_data.reserved_ip': {
                name: 'list_data.reserved_ip',
            },
            'list_data.safe_ip': {
                name: 'list_data.safe_ip',
            },
            'list_data.whois_owner': {
                name: 'list_data.whois_owner',
            },
            'list_data.domain_age': {
                name: 'list_data.domain_age',
            },
            'list_data.dns_ns': {
                name: 'list_data.dns_ns',
            },
            'list_data.ips_list': {
                name: 'list_data.ips_list',
            },
            'list_data.hosting_companies': {
                name: 'list_data.hosting_companies',
            },

            compiled_tags: {
                name: 'compiled_tags',
            },
            tags: {
                name: 'tags',
            },
            'tags.pirate_brand': {
                name: 'tags.pirate_brand',
            },

            // 'meta.pirate_brand': 'meta.pirate_brand',
            // 'meta.porta': 'meta.porta',
            // 'meta.protocolo': 'meta.protocolo',
            // 'meta.pre-validado': 'meta.pre-validado',
            // 'meta.modelo': 'meta.modelo',
            // 'meta.app': 'meta.app',
            // 'meta.app_versao': 'meta.app_versao',
            // 'meta.request_type': 'meta.request_type',
            // 'meta.rule': 'meta.rule',
            // 'meta.illegality_report': 'meta.illegality_report',
            // 'meta.similarity_test': 'meta.similarity_test',
            // 'meta.delisting': 'meta.delisting',
            // 'meta.blocking_status': 'meta.blocking_status',
            // 'meta.countries': 'meta.countries',
            // 'meta.isp': 'meta.isp',
            // 'meta.ports': 'meta.ports',

            asns: {
                name: 'asns',
            },
            'external_services_data.block_checker.efficiency': {
                name: 'external_services_data.block_checker.efficiency',
            },
            'external_services_data.block_checker.progress': {
                name: 'external_services_data.block_checker.progress',
            },
            'external_services_data.delisting_checker': {
                name: 'external_services_data.delisting_checker',
            },
            'external_services_data.similarweb': {
                name: 'external_services_data.similarweb',
            },
            'external_services_data.onoff': {
                name: 'external_services_data.onoff',
            },
            answer: {
                name: 'answer',
            },
            answered_at: {
                name: 'answered_at',
            },
            blocked_at: {
                name: 'blocked_at',
            },
            notified: {
                name: 'notified',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useTargetColumnFields;
