import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    LocalizationProvider,
    useLocalization,
} from 'hooks/context/useLocalization';
import { ToastProvider } from 'hooks/context/useToast';
import CaseManagerRouteProvider from 'router';
import { ConfirmDialog } from 'primereact/confirmdialog';
import ErrorBoundaryWrapper from 'components/error/ErrorBoundaryWrapper';
import LoadingMessage from 'components/misc/LoadingMessage';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            gcTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: 1,
        },
    },
});

const StagingStrip = () => {
    const [localization] = useLocalization();

    const isStaging = window.API_URL.startsWith(
        'https://case-manager-api.dev.do.ethercity.center'
    );
    if (!isStaging) return null;

    return (
        <div className='w-full h-10 relative'>
            <div className='flex w-full h-10 text-2xl bg-yellow-400 text-black justify-center items-center fixed top-0 z-[99999]'>
                <strong>
                    {localization.environment.staging.toUpperCase()}
                </strong>
            </div>
        </div>
    );
};

function App() {
    // omg
    useEffect(() => {
        if (
            window.location.href.includes('debug_bonitinho=true') ||
            window.location.href.includes('localhost:')
            // window.API_URL.includes('localhost')
        )
            return;

        const preventAction = (e: Event) => e.preventDefault();

        document.onkeydown = (e) => {
            const key = e.key.toUpperCase();
            // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
            if (
                key === 'F12' ||
                (e.ctrlKey &&
                    e.shiftKey &&
                    (key === 'I' || key === 'J' || key === 'C')) ||
                (e.ctrlKey && key === 'U')
            )
                return false;
            // Disable print (Ctrl + P)
            if (e.ctrlKey && key === 'P') return false;
        };

        // document.addEventListener('selectstart', preventAction);
        // document.addEventListener('copy', preventAction);
        // document.addEventListener('paste', preventAction);
        document.addEventListener('contextmenu', preventAction);

        return () => {
            // document.removeEventListener('selectstart', preventAction);
            // document.removeEventListener('copy', preventAction);
            // document.removeEventListener('paste', preventAction);
            document.removeEventListener('contextmenu', preventAction);
        };
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <QueryClientProvider client={queryClient}>
                <Suspense
                    fallback={<LoadingMessage>Loading...</LoadingMessage>}
                >
                    <LocalizationProvider>
                        <ToastProvider>
                            <ConfirmDialog />
                            <StagingStrip />
                            <CaseManagerRouteProvider />
                        </ToastProvider>
                    </LocalizationProvider>
                </Suspense>
            </QueryClientProvider>
        </ErrorBoundaryWrapper>
    );
}

export default App;
