import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import LoadingMessage from 'components/misc/LoadingMessage';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import DashboardIframe from 'components/models/Dashboard/DashboardIframe';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import SimplifiedTargetsDataTable from 'components/models/Targets/SimplifiedTargetsDataTable';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useGetMultipleDashboardURLs from 'hooks/queries/dashboard/useGetMultipleDashboardURLs';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { SortOrder } from 'primereact/api';
import { useMemo } from 'react';
import {
    detailManyAuthorization,
    detailManyAuthorizationGroupedByConfig,
} from 'services/ether/case-manager/authorizations';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import { detailManyTargets } from 'services/ether/case-manager/targets';

/**
 * Home page view for the user with Authority role, as known as Autoridade/Authorizer Strict
 * @returns Functional Component for the home
 */
const AuthorityHome = () => {
    const [localization] = useLocalization();
    const project = useProject();
    const { user, permissions } = useAuth();
    const metricsQuery = useMetricsQuery(project._id);

    const { isDisabled: dashboardDisabled, query: dashboardQuery } =
        useGetMultipleDashboardURLs({
            filterKeys: ['overview'],
        });

    const dashboardElement = useMemo(() => {
        if (dashboardQuery.isLoading)
            return (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            );
        if (dashboardQuery.isError)
            return <p className='p-error'>{localization.common.error}</p>;
        if (dashboardQuery.data)
            return dashboardQuery.data.map((dash) => (
                <DashboardIframe
                    title={localization.models.dashboard.singular}
                    data={dash}
                />
            ));
        return null;
    }, [
        localization,
        dashboardQuery.data,
        dashboardQuery.isError,
        dashboardQuery.isLoading,
    ]);

    const { relatedCompany: company } = user;
    const viewApprovedGrouped =
        !!company?.view_setup?.['authorizer-strict']
            ?.approved_complaints_grouped;
    const viewPendingGrouped =
        !!company?.view_setup?.['authorizer-strict']
            ?.pending_complaints_grouped;

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    const data = metricsQuery.data;

    if (!data) return <h3>{localization.validations.generic.unhandled}</h3>;

    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    const views: TableWithTabView[] = [];
    if (permissions.viewAuthorizations) {
        views.push(
            {
                fetchData: (params) =>
                    (viewPendingGrouped
                        ? detailManyAuthorizationGroupedByConfig
                        : detailManyAuthorization)({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                authority_data_status: 'pending',
                            },
                        },
                    }).then((query) => ({
                        ...query,
                        payload: query.payload.map((a) => {
                            a.status = 'pending';
                            return a;
                        }),
                    })),
                label: authorizationBadge.pendingWithName,
                totalCount: null,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    if (viewPendingGrouped)
                        return (
                            <AuthorizationDataTable
                                {...paginatorProps}
                                {...rest}
                            />
                        );
                    return <AuthorizationDataTable {...rest} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    (viewApprovedGrouped
                        ? detailManyAuthorizationGroupedByConfig
                        : detailManyAuthorization)({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            devFilters: {
                                authority_data_status: 'done',
                            },
                        },
                    }),
                label: authorizationBadge.finishedWithName,
                totalCount: viewApprovedGrouped
                    ? null
                    : metricsQuery.data.authority_data_status_done,
                tableElement: (props) => {
                    const { sortField, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'official_document',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.oficio.plural,
                totalCount: metricsQuery.data?.total_official_documents ?? 0,
                tableElement: (props) => (
                    <DocumentDataTable
                        // onStartEdit={(doc) => showEditOfficialDoc(doc._id)}
                        {...props}
                    />
                ),
                model: 'document',
            }
        );
    }

    if (permissions.viewTargets)
        views.push({
            fetchData: (params) =>
                detailManyTargets({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            in_type_order: 'judicial',
                        },
                    },
                    signal: params.signal,
                }),
            label: localization.models.judicialTargets.plural,
            totalCount: metricsQuery.data?.total_judicial_targets ?? 0,
            tableElement: (props) => (
                <SimplifiedTargetsDataTable
                    targetsTableConfig={{
                        type: 'type',
                        notified: 'notified',
                        authorizations: 'authorizations_count',
                        block_orders: 'block_orders_count',
                        created_at: 'created_at',
                    }}
                    {...props}
                />
            ),
            model: 'targets',
        });

    if (!dashboardDisabled)
        views.push({
            label: localization.models.dashboard.singular,
            customElement: <>{dashboardElement}</>,
        });

    return (
        <section>
            <ViewTablesWithTabs
                mainModel={null}
                uniqueQueryKey='home'
                views={views}
            />
        </section>
    );
};

export default AuthorityHome;
