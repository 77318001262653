import { CMDataTableColumnFields } from 'components/datatable/CMDataTable/types';
import { CMLocalization } from 'static/language';
import { getTargetMeta } from '../../../components/models/Targets/TargetsDataTable/utils';
import TargetEnrichmentField, {
    EnrichDisplayError,
    EnrichDisplayPending,
} from '../../../components/models/Targets/TargetEnrichmentField';
import YesNoBadge from 'components/display/YesNoBadge';
import { DateBadge } from 'components/ethercity-primereact';
import TargetTypeBadge from '../../../components/models/Targets/TargetTypeBadge';
import {
    objetifyNestedTagList,
    translateSafeIpReasons,
} from 'utils/models/target/target';
import ReservedIpBadge from '../../../components/models/Targets/ReservedIpBadge';
import { Badge } from 'primereact/badge';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import { localizeDistance } from 'utils/dateUtils';
import { Column, ColumnProps } from 'primereact/column';
import _ from 'lodash';
import {
    BlockCheckerEffectivenessBadge,
    BlockCheckerProgressBadge,
} from 'components/models/Targets/BlockCheckerBadge';

type DetailedModel = Ether.CaseManager.Target.Detailed;
type KeyColumnProps = ColumnProps & { key: string };
type TagFieldType = 'tags' | 'compiled_tags' | 'authorization_tags';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['targets']
    | CaseManagerApp.DefaultNameColumn;

const isValidTagField = (key: string | undefined) => {
    if (!key) return false;
    return ['tags', 'compiled_tags', 'authorization_tags'].some(
        (supposedKey) => {
            if (key === supposedKey) return true;
            if (key.startsWith(`${supposedKey}.`)) return true;
            return false;
        }
    );
};

/**
 * Get the column props for a meta field
 * @param key - The meta field key, without the prefix 'meta.'
 * @param refAuthorization - The referencing authorization, must be required for a meta field
 */
const getMetaFieldProps = (
    key: string,
    refAuthorization: Ether.CaseManager.Authorization
) => {
    const metaKey = 'meta.' + key;
    return {
        key: metaKey,
        field: metaKey,
        header: _.upperFirst(key),
        body: (data: Ether.CaseManager.Target) => {
            const meta = getTargetMeta({
                target: data,
                field: metaKey,
                refAuthorization,
            });
            return <div className='max-w-full w-max'>{meta ?? '-'}</div>;
        },
        sortable: false,
    } as KeyColumnProps;
};

/**
 * Get the tags with a set of allowed tags (or all if not provided) to show on that field
 * @param param.key - Key for props referencing
 * @param param.header - Column's header
 * @param param.target - Target's data
 * @param param.fieldType - Tags may come from different sources, so it is important to specify where from
 * @param param.filteredTags - Tags which will appear on this column. If only one, no tag indication will appear before its name. If not given, it will show all tags available
 * @param param.refAuthorization - Referencing authorization, if existing
 * @returns Props to be given to the Column
 */
const getTagFieldProps = ({
    key,
    header,
    fieldType,
    filteredTags,
    refAuthorization,
}: {
    key: string;
    header: string;
    fieldType: TagFieldType;
    filteredTags?: string[];
    refAuthorization?: Ether.CaseManager.Authorization;
}) => {
    // Temporary measure: do not discard until next deploy
    filteredTags = filteredTags?.map((t) => t.replace('_', '-'));

    return {
        key: key,
        field: key,
        header: header,
        body: (data: Ether.CaseManager.Target) => {
            let tags: Record<string, string[]> | null;
            if (fieldType === 'tags') {
                if (refAuthorization)
                    tags = getTargetTags(data, refAuthorization);
                else tags = getAuthorizationTags(data);
            } else if (fieldType === 'authorization_tags')
                tags = getAuthorizationTags(data);
            else tags = getTargetTags(data, refAuthorization);
            if (!tags) return '-';

            if (filteredTags?.length === 1) {
                const specificKey = filteredTags[0] as string;
                let specificTags: string[] | undefined = tags[specificKey];
                if (!specificTags) return '-';
                return <pre>{specificTags?.join(', ')}</pre>;
            }

            const tagList = Object.entries(tags);
            if (tagList.length <= 0) return '-';
            return (
                <pre className='flex flex-col'>
                    {tagList.map(([key, value]) => {
                        if (filteredTags && !filteredTags.includes(key))
                            return null;
                        return (
                            <span key={key + value}>{`${key} : ${
                                Array.isArray(value) ? value.join(', ') : value
                            }`}</span>
                        );
                    })}
                </pre>
            );
        },
        sortable: false,
    } as KeyColumnProps;
};

/**
 * Get the authorization exclusive tags
 * @param target - The target data
 * @returns Objetified tags
 */
const getAuthorizationTags = (target: Ether.CaseManager.Target.Detailed) => {
    const compiledTags = target.compiled_authorizations_tags;
    if (compiledTags && compiledTags.length > 0)
        return objetifyNestedTagList(compiledTags);
    return null;
};

/**
 * Get the target tags, also getting relationship tags if present
 * @param target - The target data
 * @param refAuthorization - The referencing authorization
 * @returns Objetified tags
 */
const getTargetTags = (
    target: Ether.CaseManager.Target.Detailed,
    refAuthorization?: Ether.CaseManager.Authorization.Detailed
) => {
    if (target.compiled_tags && Object.keys(target.compiled_tags).length > 0) {
        return target.compiled_tags;
    }
    if (!refAuthorization) return null;
    let targetAuth = target?.authorizations?.find(
        (a) => a?._id === refAuthorization._id
    );
    if (!targetAuth) {
        targetAuth = target.removed_authorizations?.find(
            (a) => a?._id === refAuthorization?._id
        );
    }
    if (!targetAuth?.tags?.length) return null;
    return objetifyNestedTagList(targetAuth.tags);
};

/**
 * Generates columns dynamically for the TargetsDataTable
 * @param object.targetColumns - The target available columns to transform into elements
 * @param object.project - The currenct project in context
 * @param object.refAuthorization - Referencing authorization, if existing
 * @param object.targetsTableConfig - The targets datatable configuration, listing all visible columns
 * @param object.localization - The localization object
 * @param object.sortable - If the table is sortable
 * @param object.extraMetaFields Which extra meta fields should be considered
 * @returns columns - All columns in a element format
 */
export const generateTargetColumnsAndFilters = ({
    targetColumns,
    project,
    refAuthorization,
    targetsTableConfig,
    localization,
    sortable,
    extraMetaFields,
}: {
    targetColumns: Partial<CMDataTableColumnFields<ModelColumns>>;
    project: Ether.CaseManager.Project;
    refAuthorization: Ether.CaseManager.Authorization.Detailed | undefined;
    targetsTableConfig: CaseManagerApp.AppConfig.Target.DataTable;
    localization: CMLocalization.Localization;
    sortable: boolean;
    extraMetaFields?: string[];
}) => {
    const metaFieldsMap: Record<string, { key: string; assigned: boolean }> =
        {};
    extraMetaFields?.forEach(
        (meta) =>
            (metaFieldsMap[`meta.${meta}`] = { key: meta, assigned: false })
    );

    // TODO: [EMEA NAME] REMOVE LATER
    const isEmea = project.name.toLowerCase().includes('emea');
    const fields = localization.fields.target;
    const mapLocale: Record<
        CaseManagerApp.AppConfig.Target.DataTable.LocaleFields,
        string
    > = {
        type: fields.type,
        tag: fields.tags,
        tags: fields.tags,
        compiled_tags: fields.tags,
        enrichment_status: fields.enrichment,
        grade: fields.grading,
        hosted_domains: fields.hostingHistoryDns,
        domains_count: fields.domainsCount,
        br_domains_count: fields.domainsBrCount,
        country: fields.country,
        asn: fields.asn,
        accessible_ports: fields.accessiblePorts,
        safe_blocking: fields.safeIp,
        reserved_ip: fields.reservedIp,
        official_domain: fields.officialDomain,
        dns_ns: fields.dnsNameservers,
        domain_age: fields.domainAge,
        ips_list: fields.ipsList,
        whois_owner: fields.whoisOwner,
        type_order: fields.typeOrder,
        pirate_brand: fields.pirateBrands,
        block_checker_progress: fields.blockCheckerProgress,
        block_checker_efficiency: fields.blockCheckerEffiency,
        similarweb: fields.similarWeb,
        delisting_checker_status: fields.delistingChecker,
        onoff: fields.onoffStatus,
        created_at: fields.created,
        notified: fields.notified,
        hosting_companies: fields.hostingCompanies,
        authorizations: localization.models.authorization.plural,
        block_orders: localization.models.blockOrder.plural,
    };

    const columns: JSX.Element[] = Object.entries(targetsTableConfig)
        .map(([localeKey, fieldKey]) => {
            const key =
                fieldKey.startsWith('meta.') || isValidTagField(fieldKey)
                    ? fieldKey
                    : targetColumns[fieldKey]?.name;
            if (!key) return null;
            const header =
                mapLocale[
                    localeKey as CaseManagerApp.AppConfig.Target.DataTable.LocaleFields
                ];

            let props: KeyColumnProps;
            if (key.startsWith('meta.')) {
                // Order meta fields
                const meta = metaFieldsMap[key];
                if (!meta || !refAuthorization) return null;
                meta.assigned = true;
                props = getMetaFieldProps(meta.key, refAuthorization);
            } else if (
                ['tags', 'compiled_tags', 'authorization_tags'].some(
                    (supposedKey) => {
                        if (key === supposedKey) return true;
                        if (key.startsWith(`${supposedKey}.`)) return true;
                        return false;
                    }
                )
            ) {
                // tags -> show every tag
                // compiled_tags.pirate_brand -> show only pirate brand tags
                // authorization_tags.pirate_brand,country -> show pirate_brand and country tags, only
                const assertIsValidTag = (
                    key: string | undefined
                ): key is TagFieldType => {
                    return (
                        !!key &&
                        [
                            'tags',
                            'compiled_tags',
                            'authorization_tags',
                        ].includes(key)
                    );
                };
                const [tagField, allowedTags] = key.split('.');
                if (!assertIsValidTag(tagField)) return null;
                props = getTagFieldProps({
                    fieldType: tagField,
                    header: header,
                    key: key,
                    filteredTags: allowedTags?.split(',').map((t) => t.trim()),
                    refAuthorization: refAuthorization,
                });
            } else
                props = {
                    key: key,
                    field: key,
                    header: header,
                    body: (data: any) => {
                        const value = key
                            .split('.')
                            .reduce((a, b) => (a ?? {})[b], data);
                        if (typeof value === 'boolean')
                            return <YesNoBadge value={value} />;
                        if (value instanceof Date)
                            return <DateBadge value={value} />;
                        if (Array.isArray(value)) {
                            if (value.length <= 0) return '-';
                            if (value.length <= 5) return value.join(', ');
                            return value.slice(0, 5).join(', ') + ' (...)';
                        }
                        return (
                            <div className='max-w-full w-max'>
                                {value ?? '-'}
                            </div>
                        );
                    },
                    sortable: false,
                };
            switch (fieldKey) {
                case 'type':
                    props.body = (data: Ether.CaseManager.Target) => (
                        <TargetTypeBadge target={data} />
                    );
                    break;
                case 'type_order':
                    props.body = (data: Ether.CaseManager.Target.Detailed) => {
                        const typesLocale =
                            localization.components.models.blockOrder.badge
                                .type;
                        const typeOrderMap = {
                            administrative: typesLocale.administrative,
                            judicial: typesLocale.judicial,
                        };
                        if (Array.isArray(data.block_orders_data)) {
                            const set = new Set<string>([]);
                            Object.values(data.block_orders_data).forEach((b) =>
                                b.type ? set.add(typeOrderMap[b.type]) : null
                            );
                            const items = Array.from(set);
                            if (items.length <= 0) return '-';
                            return Array.from(set).join(', ');
                        }
                        const blockOrder =
                            data.block_orders_data as any as Ether.CaseManager.BlockOrder.Detailed;
                        return blockOrder.type
                            ? typeOrderMap[blockOrder.type]
                            : '-';
                    };
                    break;
                case 'enrichment_status':
                    props.body = (data: DetailedModel) => (
                        <TargetEnrichmentField target={data} />
                    );
                    break;
                case 'authorizations_count':
                    props.body = (data: DetailedModel) =>
                        data.authorizations?.length ?? '-';
                    break;
                case 'block_orders_count':
                    props.body = (data: DetailedModel) =>
                        data.block_orders?.length ?? '-';
                    break;
                case 'list_data.highest_grading':
                    props.sortable = sortable;
                    break;
                case 'list_data.hosted_domains_list':
                    props.body = (data: DetailedModel) => {
                        const domains = data.list_data?.hosted_domains_list;
                        if (!domains || domains.length <= 0) return '-';
                        return (
                            <div className='flex flex-col gap-1 items-start'>
                                {domains.map((dns, index) => (
                                    <span key={dns + '@' + index}>{dns}</span>
                                ))}
                            </div>
                        );
                    };
                    break;
                case 'list_data.hosted_domains_count':
                    props.sortable = sortable;
                    break;
                case 'list_data.br_domains_count':
                    props.sortable = sortable;
                    break;
                case 'list_data.country':
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.country ?? '-';
                    };
                    break;
                case 'list_data.hosting_companies':
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return (
                            data.list_data?.hosting_companies?.join(', ') ?? '-'
                        );
                    };
                    break;
                case 'list_data.asn':
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.asn ?? '-';
                    };
                    break;
                case 'asns':
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return (
                            data.list_data?.asn ??
                            data.list_data?.hosting_companies?.join(', ') ??
                            '-'
                        );
                    };
                    break;
                case 'list_data.safe_ip':
                    props.body = (data: DetailedModel) => {
                        const locale =
                            localization.components.models.target.badge.safeIp;
                        const isSafe: boolean | null | undefined =
                            data.list_data?.safe_ip;
                        const safeIpReasons = translateSafeIpReasons(
                            data,
                            localization
                        );
                        const classN = `span-safeip-target-${data._id}`;

                        return (
                            <>
                                <div style={{ minWidth: '40px' }}>
                                    {isSafe != null ? (
                                        isSafe ? (
                                            <Badge
                                                severity={'success'}
                                                value={locale.safe}
                                            />
                                        ) : (
                                            <>
                                                <AdaptableTooltip
                                                    target={'.' + classN}
                                                >
                                                    <div className='flex flex-col gap-1'>
                                                        {safeIpReasons.map(
                                                            (r) => (
                                                                <span key={r}>
                                                                    {r}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </AdaptableTooltip>
                                                <Badge
                                                    className={classN}
                                                    severity={'warning'}
                                                    value={locale.unsafe}
                                                />
                                            </>
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </>
                        );
                    };
                    break;
                case 'list_data.domain_age':
                    props.body = (data: DetailedModel) => {
                        if (!data.list_data?.domain_age) return '-';
                        const now = new Date();
                        const text = localizeDistance(
                            now,
                            data.list_data.domain_age,
                            localization
                        );
                        return <div className='max-w-full w-max'>{text}</div>;
                    };
                    break;
                case 'external_services_data.delisting_checker':
                    if (!refAuthorization) return null;
                    // TODO: LOCALIZE
                    props.header = isEmea ? 'Delisting status' : header;
                    props.body = (item: DetailedModel) => {
                        const data =
                            item.external_services_data?.delisting_checker?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <EnrichDisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <EnrichDisplayError />;
                        if (!data.values.status) return '-';
                        const value = data.values.status.toLocaleUpperCase();
                        return (
                            <Badge
                                value={
                                    isEmea
                                        ? value === 'ONLINE'
                                            ? 'INDEXED'
                                            : 'NOT_INDEXED'
                                        : value
                                }
                                severity={
                                    data.values.status === 'online'
                                        ? 'success'
                                        : data.values.status === 'offline'
                                        ? 'danger'
                                        : 'info'
                                }
                            />
                        );
                    };
                    break;
                case 'external_services_data.block_checker.progress':
                    if (!refAuthorization) return null;
                    props.body = (item: DetailedModel) => (
                        <BlockCheckerProgressBadge target={item} />
                    );
                    break;
                case 'external_services_data.block_checker.efficiency':
                    if (!refAuthorization) return null;
                    props.body = (item: DetailedModel) => (
                        <BlockCheckerEffectivenessBadge target={item} />
                    );
                    break;
                case 'external_services_data.similarweb':
                    props.body = (item: DetailedModel) => {
                        const data =
                            item.external_services_data?.similarweb?.[0];
                        if (!data) return '-';
                        if (data.status === 'pending')
                            return <EnrichDisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <EnrichDisplayError />;

                        const { visits, trend } = data.values;
                        const formatter = Intl.NumberFormat(
                            localization.getLanguage(),
                            { notation: 'compact' }
                        );
                        const formattedVisits = formatter.format(visits);
                        const formattedTrend = (trend * 100).toFixed(2) + '%';

                        const trendClassname =
                            trend === 0
                                ? ''
                                : trend > 0
                                ? 'text-green-500'
                                : 'text-red-500';

                        const trendElement = (
                            <div
                                className={`flex flex-row gap-2 font-bold ${trendClassname}`}
                            >
                                {trend !== null && (
                                    <span>{formattedTrend}</span>
                                )}
                                {trend !== null &&
                                    trend !== 0 &&
                                    (trend > 0 ? (
                                        <i className='pi pi-arrow-up-right' />
                                    ) : (
                                        <i className='pi pi-arrow-down-right' />
                                    ))}
                            </div>
                        );

                        const tooltipClass = `similar-${item._id}`;

                        return (
                            <div
                                className={`max-w-full w-max flex flex-col gap-1 text-left ${tooltipClass}`}
                            >
                                <AdaptableTooltip
                                    target={'.' + tooltipClass}
                                    // TODO: LOCALIZE
                                >{`Total visits: ${new Intl.NumberFormat().format(
                                    visits
                                )}`}</AdaptableTooltip>
                                {formattedVisits}
                                {trendElement}
                            </div>
                        );
                    };
                    break;
                case 'external_services_data.onoff':
                    props.body = (item: DetailedModel) => {
                        const data = item.external_services_data?.onoff?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <EnrichDisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <EnrichDisplayError />;
                        const value = data.values.status;
                        if (!value) return '-';
                        return (
                            <Badge
                                value={value.toLocaleUpperCase()}
                                severity={
                                    value === 'online' ? 'success' : 'danger'
                                }
                            />
                        );
                    };
                    break;
            }
            return <Column {...props} />;
        })
        .filter<JSX.Element>((c): c is JSX.Element => !!c);

    if (refAuthorization)
        Object.values(metaFieldsMap)?.forEach(({ assigned, key }) => {
            if (assigned) return;
            const props = getMetaFieldProps(key, refAuthorization);
            columns.push(<Column {...props} />);
        });

    return columns;
};
