import { GetDashboardEP } from 'services/ether/case-manager/dashboard/types';

const DashboardIframe: React.FC<{
    title: string;
    data: GetDashboardEP.Result;
}> = ({ title, data }) => {
    return (
        <iframe
            title={title}
            key={data.url}
            src={data.url.replace('http:', 'https:')}
            className='w-full block border-0'
            style={{
                height: data.height ? `${data.height}px` : undefined,
            }}
        />
    );
};

export default DashboardIframe;
