import { useProject } from 'hooks/context/project/useProject';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listBlockOrdersUnblock } from 'services/ether/case-manager/block-orders';
import { ListBlockOrdersUnblockEP } from 'services/ether/case-manager/block-orders/types';

/**
 * Fetches all block orders from a given flow and config. This is a custom endpoint usage to list all block orders inside a unblock order selection
 * @param params The API valid options
 * @param queryOptions  React query options for the useQuery
 * @returns query useQueryWithRefresh query result
 */
const useListBlockOrderUnblock = (
    params: ListBlockOrdersUnblockEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['block-order', 'list', 'list-unblock', params];

    const project = useProject();

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            listBlockOrdersUnblock({
                ...params,
                project_id : project._id,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListBlockOrderUnblock;
