import { useLocalization } from 'hooks/context/useLocalization';
import { IntermediateFilter } from '../../types';
import { Button } from 'primereact/button';
import { DispatchApplyFilter } from '../FilterBoxComponent';
import { Dropdown } from 'components/ethercity-primereact';

/**
 * Dropdown filter for the FilterBox
 * @param object.temporaryFilter - The raw temporary filter object that includes which value and matchMode is using
 * @param object.setTemporaryFilter - The set dispatch action that will update the temporary raw filter object
 * @param object.applyFilter - The action that will apply the input/selected value and label to the final filter, passing alongside the HTML event
 * @param object.placeholder - The placeholder to show inside the Dropdown element
 * @param object.selectOptions - The valid select optionsv for the dropdown element
 * @returns Dropdown filter element
 */
const FilterBoxDropdownFilter: React.FC<{
    temporaryFilter: IntermediateFilter;
    setTemporaryFilter: React.Dispatch<
        React.SetStateAction<IntermediateFilter>
    >;
    applyFilter: DispatchApplyFilter;
    placeholder?: string;
    selectOptions?: {
        label: string;
        value: string;
    }[];
}> = ({
    temporaryFilter,
    setTemporaryFilter,
    applyFilter,
    placeholder,
    selectOptions,
}) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    return (
        <div className='flex flex-col gap-2 w-56'>
            <Dropdown
                value={temporaryFilter.value}
                options={selectOptions}
                placeholder={placeholder}
                className='w-full'
                onChange={(e) => {
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: e.target.value,
                    }));
                }}
            />
            <Button
                label={primereact.apply}
                onClick={(e) => {
                    applyFilter({
                        event: e,
                        filters: {
                            ...temporaryFilter,
                        },
                    });
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: null,
                    }));
                }}
            />
        </div>
    );
};

export default FilterBoxDropdownFilter;
