import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { FilterMatchMode } from 'primereact/api';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['authorizationConfig']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['authorizationConfig']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the AuthorizationConfig DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useAuthorizationConfigColumnFields = () => {
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.authorizationConfig.datatable;
        const filterFields: CMDataTableFiltersFields<ModelFilters, ModelColumns> = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorizationConfig.created,
                    type: 'date',
                    relatedColumn: 'created_at',
                },
            },
        };
        return filterFields;
    }, [localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            authorizers_count: {
                name: 'authorizers_count',
            },
            pirate_brands_count: {
                name: 'pirate_brands_count',
            },
            documents_count: {
                name: 'documents_count',
            },
            targets_count: {
                name: 'targets_count',
            },
            created_at: {
                name: 'created_at',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useAuthorizationConfigColumnFields;
