import useListAuthorizationConfig from 'hooks/queries/authorization-config/useListAuthorizationConfig';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { ListAuthorizationConfigEP } from 'services/ether/case-manager/authorization-config/types';
import { SortOrder } from 'primereact/api';

const SelectOneAuthorizationConfig: React.FC<
    SelectOneItemProps<
        Ether.CaseManager.AuthorizationConfig,
        ListAuthorizationConfigEP.Filters.Options
    >
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useListAuthorizationConfig(
        {
            project_id: project._id,
            options: {
                sort: { field: 'name', order: SortOrder.ASC },
                limit: 0,
                ...queryOptions,
            },
        },
        {
            enabled: !props.overrideItems,
        }
    );

    return (
        <SelectOneItem
            title={localization.models.authorizationConfig.singular}
            placeholder={
                localization.components.models.authorizationConfig.form
                    .selectOnePlaceholder
            }
            label={localization.models.authorizationConfig.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneAuthorizationConfig;
