import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { getYesNoOptions } from 'components/models/Targets/TargetsDataTable/utils';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { FilterMatchMode } from 'primereact/api';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import FilterBoxMultiselectPirateBrand from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiselectTagPirateBrand';
import FilterBoxDropdownTagCountry from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagCountry';
import FilterBoxDropdownTagFlag from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagFlag';
import FilterBoxMultiselectAuthorizationConfig from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiSelectAuthorizationConfig';
import FilterBoxDropdownAuthorizationFlow from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownAuthorizationFlow';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['authorization']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['authorization']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Authorization DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useAuthorizationColumnFields = () => {
    const project = useProject();
    const { user } = useAuth();
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.authorization.datatable;
        const authorizationBadge =
            localization.components.models.authorization.badge.status;

        // TODO: [EMEA NAME] REMOVE LATER
        const isEmeaProject = project.name.toLowerCase().includes('emea');
        const defaultStatusOptions = [
            {
                label: authorizationBadge.pending.toLocaleUpperCase(),
                value: 'pending',
            },
            {
                label: authorizationBadge.approved.toLocaleUpperCase(),
                value: 'approved',
            },
            {
                label: authorizationBadge.done.toLocaleUpperCase(),
                value: 'done',
            },
            ...(user.role?.startsWith('authorizer')
                ? []
                : [
                      {
                          label: authorizationBadge.draft.toLocaleUpperCase(),
                          value: 'draft',
                      },
                  ]),
        ];

        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            approved_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.approval,
                    type: 'date',
                    relatedColumn: 'approved_at',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.created,
                    type: 'date',
                    relatedColumn: 'created_at',
                },
            },
            'created_by_data.display_name': {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: localization.fields.authorization.createdBy,
                    type: 'string',
                    relatedColumn: 'created_by_data.display_name',
                },
            },
            status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.status,
                    placeholder: datatableLocale.selectStatus,
                    type: 'dropdown',
                    selectOptions: defaultStatusOptions,
                    relatedColumn: 'status',
                },
            },
            authorization_flow_id: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.authorizationFlow.singular,
                    type: 'custom',
                    element: FilterBoxDropdownAuthorizationFlow,
                    initialValue: null,
                    relatedColumn: 'authorization_flow_id',
                },
            },
            authorization_config_id: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.authorizationConfig.singular,
                    type: 'custom',
                    element: FilterBoxMultiselectAuthorizationConfig,
                    initialValue: [],
                    relatedColumn: 'authorization_config_id',
                },
            },
            'block_orders.0|isnull': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.notified,
                    type: 'dropdown',
                    selectOptions: getYesNoOptions(localization),
                    relatedColumn: 'block_orders.0|isnull',
                },
            },
            'block_orders.added_at': {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.notifiedAt,
                    type: 'date',
                    relatedColumn: 'block_orders.added_at',
                },
            },
            'authority_data.status': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.authorityStatus,
                    type: 'dropdown',
                    selectOptions: ['new', 'pending', 'done', 'available'].map(
                        (a) => ({
                            label: localization.components.models.authorization
                                .badge.authorityStatus[
                                a as keyof typeof localization.components.models.authorization.badge.authorityStatus
                            ],
                            value: a,
                        })
                    ),
                    relatedColumn: 'authority_data.status',
                },
            },
            _cmapp_tags_pirate_brand: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'custom',
                    label: localization.models.tag.types.pirateBrand.plural,
                    element: FilterBoxMultiselectPirateBrand,
                    initialValue: [],
                    relatedColumn: '_cm_name_id',
                },
            },
        };

        if (isEmeaProject) {
            filterFields['_cmapp_tags_country'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.country.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagCountry,
                    initialValue: null,
                },
            };
            filterFields['_cmapp_tags_flag'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.flag.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagFlag,
                    initialValue: null,
                },
            };
        }

        return filterFields;
    }, [project.name, localization, user.role]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            approved_at: {
                name: 'approved_at',
            },
            approved_by: {
                name: 'approved_by',
            },
            created_at: {
                name: 'created_at',
            },
            'created_by_data.display_name': {
                name: 'created_by_data.display_name',
            },
            status: {
                name: 'status',
            },
            evidences_count: {
                name: 'evidences_count',
            },
            targets_count: {
                name: 'targets_count',
            },
            registered_at: {
                name: 'registered_at',
            },
            authorization_config_id: {
                name: 'authorization_config_id',
            },
            authorization_flow_id: {
                name: 'authorization_flow_id',
            },
            'block_orders.0|isnull': {
                name: 'block_orders.0|isnull',
            },
            'block_orders.added_at': {
                name: 'block_orders.added_at',
            },
            'authority_data.status': {
                name: 'authority_data.status',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useAuthorizationColumnFields;
