import { useLocalization } from "hooks/context/useLocalization";
import { BlockOrderDashboard } from "../..";

/**
 * Home page view for the user with Operator role, as known as Operadora
 * @returns Functional Component for the home
 */
const OperatorHome = () => {
    // const { permissions } = useAuth();
    const [localization] = useLocalization();

    return (
        <>
            <p>
                {
                    localization.components.views.homeDashboard
                        .operatorDescription
                }
            </p>
            <BlockOrderDashboard hideCount />
        </>
    );
};

export default OperatorHome;