import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { useLocalization } from 'hooks/context/useLocalization';

export type BlockOrderTypes = 'administrative' | 'judicial';
type BlockOrderOptions = { label : string; value : BlockOrderTypes }

const SelectOneBlockOrderType: React.FC<
    SelectOneItemProps<BlockOrderOptions>
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();

    const options : BlockOrderOptions[] = [
        {
            label: localization.components.models.blockOrder.badge.type
                .administrative,
            value: 'administrative',
        },
        {
            label: localization.components.models.blockOrder.badge.type
                .judicial,
            value: 'judicial',
        },
    ];

    return (
        <SelectOneItem
            title={localization.fields.blockOrder.type}
            label={localization.fields.blockOrder.type}
            items={options}
            optionLabel='label'
            optionValue='value'
            itemsLoading={false}
            {...props}
        />
    );
};

export default SelectOneBlockOrderType;
