import { useLocalization } from 'hooks/context/useLocalization';
import { BadgeProps } from 'primereact/badge';
import {
    EnrichDisplayError,
    EnrichDisplayPending,
} from '../TargetEnrichmentField';
import NoWrapBadge from 'components/display/NoWrapBadge';

const BlockCheckerEffectivenessBadge: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    const [localization] = useLocalization();
    const locale =
        localization.components.models.target.badge.blockCheckerEfficiency;
    const data = target.external_services_data?.block_checker?.[0];
    if (!data) return <NoWrapBadge value='NEW' severity={null} />;
    if (data.status === 'pending') return <EnrichDisplayPending />;
    if (data.status === 'error' || !data.values) return <EnrichDisplayError />;
    const effectiveness = data.values.block_effectiveness;
    const props: BadgeProps = {
        value: (effectiveness === 0
            ? locale.unblocked
            : effectiveness < 1
            ? locale.partial
            : locale.unblocked
        ).toLocaleUpperCase(),
        severity:
            effectiveness === 0
                ? 'danger'
                : effectiveness < 1
                ? 'warning'
                : 'success',
    };
    return <NoWrapBadge {...props} />;
};

const BlockCheckerProgressBadge: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    const data = target.external_services_data?.block_checker?.[0];
    if (!data) return <NoWrapBadge value='NEW' severity={null} />;
    if (data.status === 'pending') return <EnrichDisplayPending />;
    if (data.status === 'error' || !data.values) return <EnrichDisplayError />;
    const effectiveness = data.values.block_effectiveness;
    const props: BadgeProps = {
        value: (effectiveness * 100).toFixed(2) + '%',
        severity:
            effectiveness === 0
                ? 'danger'
                : effectiveness < 1
                ? 'warning'
                : 'success',
    };
    return <NoWrapBadge {...props} />;
};

export { BlockCheckerEffectivenessBadge, BlockCheckerProgressBadge };
