import NameIdAggregator from 'components/datatable/NameIdAggregator';
import {
    Checkbox,
    DateBadge,
    ObjectDisplayModal,
} from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Fragment, useMemo, useRef, useState } from 'react';
import { CMDataTableBaseProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import DetailTargetModal from '../DetailTargetModal';
import {
    getTargetValueHrefLink,
    truncateTargetName,
} from 'utils/models/target/target';
import useValidateTarget from 'hooks/mutations/target/useValidateTarget';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog } from 'primereact/confirmdialog';

import './styles.css';
import SafeIPRules from 'components/views/SafeIPRules';

import {
    getPreRejectedReasons,
    getTargetAnswer,
    verifyIfTargetCanBeValidated,
} from './utils';
import {
    DataTableExpandedRows,
    DataTableValueArray,
} from 'primereact/datatable';
import OperatorsTargetSummary from 'components/models/Operators/OperatorsTargetSummary';
import useCopyToClipboard from 'hooks/helpers/useCopyToClipboard';
import useRemoveTargetValidation from 'hooks/mutations/target/useRemoveTargetValidation';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import useUpdateTargetOrderStatusDialog from 'hooks/dialogs/target/useUpdateTargetOrderStatusDialog';
import { useProject } from 'hooks/context/project/useProject';
import { localizeDateOnly } from 'utils/dateUtils';
import { DetailTargetEP } from 'services/ether/case-manager/targets/types';
import { generateTargetColumnsAndFilters } from '../../../../utils/models/target/datatable';
import useTargetColumnFields from 'hooks/columns/models/useTargetColumnFields';
import NoWrapBadge from 'components/display/NoWrapBadge';

type DetailedModel = Ether.CaseManager.Target.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (item: DetailedModel) => void;
}> = ({ item, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const TargetsDataTable: React.FC<
    CMDataTableBaseProps & {
        projectId: string;
        refAuthorization?: Ether.CaseManager.Authorization.Detailed;
        refBlockOrder?: Ether.CaseManager.BlockOrder.Detailed;
        refUnblockOrder?: Ether.CaseManager.UnblockOrder.Detailed;
        showOperatorAnswer?: boolean;
        onValidation?: () => void;
        tokenData?: CaseManagerApp.TokenInfo;
        showExpand?: 'operator';
    }
> = ({
    projectId,
    refAuthorization,
    refBlockOrder,
    refUnblockOrder,
    onValidation,
    showOperatorAnswer,
    tokenData,
    showExpand,
    meta,
    ...props
}) => {
    const [localization, { currentLanguage }] = useLocalization();
    const { user, permissions } = useAuth();
    const project = useProject();
    const config = project.app_config?.target;

    const targetMeta = meta as DetailTargetEP.Data.Meta | undefined;

    const sortable = 'sortField' in props;

    const { columnFields, filterOptions } = useTargetColumnFields();

    // Some filters should be rendered conditionlly based if it's in a refAuthorization view or not.
    // TODO: Separate the filter render logic from the filter availability logic (e.g. country filter may be available but not usable)
    const visibleFilterOptions = useMemo(() => {
        if (!refAuthorization) return filterOptions;
        const visibleFilterOptions = {
            ...filterOptions,
        };
        delete visibleFilterOptions['_cmapp_tags_country'];
        delete visibleFilterOptions['_cmapp_tags_flag'];
        return visibleFilterOptions;
    }, [refAuthorization, filterOptions]);

    const columnElements = useMemo(
        () =>
            project.app_config?.target?.datatable
                ? generateTargetColumnsAndFilters({
                      targetColumns: columnFields,
                      project: project,
                      targetsTableConfig: project.app_config.target.datatable,
                      localization: localization,
                      refAuthorization: refAuthorization,
                      sortable: sortable,
                      extraMetaFields: targetMeta?.meta_fields,
                  })
                : null,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            project._id,
            localization,
            refAuthorization?._id,
            targetMeta?.meta_fields,
        ]
    );

    const copyToClipboard = useCopyToClipboard();

    const [expandedRows, setExpandedRows] = useState<
        DataTableExpandedRows | DataTableValueArray | undefined
    >(undefined);

    const currentUserRole = user.role ?? tokenData?.userRole ?? null;
    const currentUserId =
        (currentUserRole === 'operator'
            ? user.data?.operator_data?._id ?? null
            : user.data?._id ?? null) ??
        tokenData?.userId ??
        null;
    const currentUserCompany = user.data?.data?.company_id ?? null;

    const datatableLocale = localization.components.models.target.datatable;
    const targetValidationLocale =
        localization.components.models.target.views.targetValidation;
    const approvalBadge = localization.components.models.target.badge.approval;

    const [viewTargetDialogOptions, setViewTargetDialogOptions] = useState<{
        mode: 'debug' | 'detail';
        data: DetailedModel | null;
    }>({
        mode: 'detail',
        data: null,
    });

    const [targetsSelect, setTargetSelect] = useState<{
        targets: {
            _id: string;
            value: string;
        }[];
        mode: 'revert' | 'validate';
        revertMode: 'accepted' | 'rejected' | null;
    } | null>(null);

    const { dialog: updateTargetOrderDialog, updateTargetOrderStatus } =
        useUpdateTargetOrderStatusDialog();
    const {
        validateTargets: respondTarget,
        dialogElement: validateTargetDialog,
        isLoading: validateTargetLoading,
    } = useValidateTarget({
        onRespond: () => {
            setTargetSelect(null);
            if (props.paginatorProps?.onRefresh)
                props.paginatorProps.onRefresh();
            if (onValidation) onValidation();
        },
        token: tokenData?.value,
        defaultBlockDate: refBlockOrder?.start_date,
    });
    const {
        revertTargets,
        isLoading: revertTargetsLoading,
        dialogs: revertTargetDialogs,
    } = useRemoveTargetValidation({
        onRevert: () => setTargetSelect(null),
    });

    const targetValidationPositiveLabelSingular = refAuthorization
        ? localization.components.common.button.approve
        : localization.components.common.button.notBlock;
    const targetValidationPositiveLabelPlural = refAuthorization
        ? targetValidationLocale.approveTargets
        : targetValidationLocale.notBlockTargets;
    const targetValidationPositiveIcon = refAuthorization
        ? 'pi pi-check'
        : 'pi pi-circle';
    const targetValidationRevert = targetValidationLocale.revertTargets;

    const targetValidationNegativeLabelSingular = refAuthorization
        ? localization.components.common.button.reject
        : localization.components.common.button.block;
    const targetValidationNegativeLabelPlural = refAuthorization
        ? targetValidationLocale.rejectTargets
        : targetValidationLocale.blockTargets;
    const targetValidationNegativeIcon = refAuthorization
        ? 'pi pi-times'
        : 'pi pi-ban';

    const targetAnswerApproved = refAuthorization
        ? approvalBadge.approved
        : approvalBadge.blocked;
    const targetAnswerPreApproved = approvalBadge.preApproved;
    const targetAnswerPreRejected = approvalBadge.preRejected;
    const targetAnswerApprovedSeverity = refAuthorization
        ? 'success'
        : 'danger';
    const targetAnswerRejected = refAuthorization
        ? approvalBadge.rejected
        : approvalBadge.notBlocked;
    const targetAnswerRejectedSeverity = refAuthorization
        ? 'danger'
        : 'success';

    const targetSelectLocale =
        localization.components.models.target.views.allTargetDialogConfirmation
            .toSelect;

    type ValidationModes =
        | { mode: 'positive' | 'negative' }
        | { mode: 'revert'; revertMode: 'accepted' | 'rejected' };

    const validateTargets = (
        options: ValidationModes & {
            targets: {
                _id: string;
                value: string;
            }[];
            checkIfAll?: boolean;
        }
    ) => {
        const isPositive = options.mode === 'positive';
        if (refAuthorization) {
            if (options.mode === 'revert') {
                revertTargets({
                    targets: options.targets,
                    authorization_id: refAuthorization._id,
                    validation_type: options.revertMode,
                    checkIfAll: options.checkIfAll,
                    hideConfirm: true,
                });
                return;
            } else {
                respondTarget({
                    accept: isPositive,
                    authorization: refAuthorization,
                    ...options,
                });
            }
        } else if (refBlockOrder)
            respondTarget({
                blocked: !isPositive,
                blockOrder: refBlockOrder,
                ...options,
            });
        else if (refUnblockOrder)
            respondTarget({
                blocked: !isPositive,
                unblockOrder: refUnblockOrder,
                ...options,
            });
    };

    const onTargetViewModalShow = (
        data: DetailedModel,
        mode: 'debug' | 'detail'
    ) =>
        setViewTargetDialogOptions({
            mode: mode,
            data: data,
        });

    const onTargetViewModalHide = () =>
        setViewTargetDialogOptions((old) => ({
            ...old,
            data: null,
        }));

    const expandable =
        showExpand &&
        permissions.viewOperators &&
        (refBlockOrder || refUnblockOrder);
    const isViewValidatable =
        (refAuthorization &&
            (refAuthorization?.status === 'pending' ||
                refAuthorization?.status === 'draft')) ||
        (refAuthorization?.status === 'approved' &&
            user.role === 'authorizer-strict') ||
        (refBlockOrder &&
            refBlockOrder.status === 'done' &&
            currentUserRole === 'operator') ||
        (refUnblockOrder &&
            refUnblockOrder.status === 'done' &&
            currentUserRole === 'operator');

    const targetsInScreen = props.value as DetailedModel[] | undefined;

    const checkCanBeApproved = (target: DetailedModel) => {
        if (target.target_info_sync_status !== 'done') return false;
        const baseData = {
            userId: currentUserId,
            userCompanyId: currentUserCompany,
            userRole: currentUserRole,
            target,
        };
        if (refAuthorization)
            return verifyIfTargetCanBeValidated({
                ...baseData,
                authorization: refAuthorization,
            });
        return !!target._user_fields?.can_respond;
    };

    const checkCanBeReverted = (target: DetailedModel) => {
        if (target.target_info_sync_status !== 'done') return false;
        if (!permissions.removeTargetValidation || !refAuthorization)
            return false;
        return target._user_fields?.can_revert_validation ?? false;
    };

    const validatableTargets =
        targetsInScreen?.filter((t) => checkCanBeApproved(t)) ?? [];
    const revertableTargets =
        targetsInScreen?.filter((t) => checkCanBeReverted(t)) ?? [];

    const handleTargetConfirmation = ({
        targets,
        ...rest
    }: ValidationModes & {
        targets: {
            _id: string;
            value: string;
        }[];
    }) => {
        if (!isViewValidatable || !props.value) return;

        const selectedTargetsOnThisPage = targets.filter(
            ({ _id: id }) => !!targetsInScreen?.find((t) => t._id === id)
        ).length;
        const availabeForValidate = validatableTargets.length;
        const availableForRevert = revertableTargets.length;

        const isRevert = rest.mode === 'revert';

        const matchesPageSize =
            (isRevert ? availableForRevert : availabeForValidate) ===
            selectedTargetsOnThisPage;

        validateTargets({
            targets,
            checkIfAll: matchesPageSize,
            ...rest,
        });
    };

    const getAnswer = (target: DetailedModel) => {
        const answer = getTargetAnswer({
            target,
            showOperatorAnswer,
            authorization: refAuthorization,
        });
        return answer;
    };

    const selectAllTargetsHeader = () => {
        if (!revertableTargets.length && !validatableTargets.length)
            return null;
        const allSelected =
            !!targetsSelect && targetsSelect?.mode === 'revert'
                ? revertableTargets.length === targetsSelect.targets.length
                : validatableTargets.length === targetsSelect?.targets.length;
        const populateWithValidatable = () => {
            setTargetSelect({
                targets: validatableTargets,
                mode: 'validate',
                revertMode: null,
            });
        };
        const populateWithRevertable = () => {
            setTargetSelect({
                targets: revertableTargets,
                mode: 'revert',
                revertMode: revertableTargets.find((t) =>
                    t.removed_authorizations?.find(
                        (a) => a?._id === refAuthorization?._id
                    )
                )
                    ? 'rejected'
                    : 'accepted',
            });
        };
        return (
            <Checkbox
                checked={allSelected}
                onClick={() => {
                    if (allSelected) {
                        setTargetSelect(null);
                        return;
                    }
                    if (
                        !revertableTargets.length ||
                        targetsSelect?.mode === 'validate'
                    ) {
                        populateWithValidatable();
                    } else if (
                        !validatableTargets.length ||
                        targetsSelect?.mode === 'revert'
                    ) {
                        populateWithRevertable();
                    } else {
                        confirmDialog({
                            header: targetSelectLocale.title,
                            message: <p>{targetSelectLocale.description}</p>,
                            footer: (props) => (
                                <div className='flex flex-col gap-2'>
                                    <Button
                                        label={targetSelectLocale.option1}
                                        onClick={() => {
                                            populateWithValidatable();
                                            props.reject();
                                        }}
                                    />
                                    <Button
                                        label={targetSelectLocale.option2}
                                        onClick={() => {
                                            populateWithRevertable();
                                            props.reject();
                                        }}
                                    />
                                    <Button
                                        label={
                                            localization.components.common
                                                .button.cancel
                                        }
                                        onClick={() => props.reject()}
                                    />
                                </div>
                            ),
                        });
                    }
                }}
            />
        );
    };

    return (
        <>
            {updateTargetOrderDialog}
            {validateTargetDialog}
            {revertTargetDialogs}
            <ObjectDisplayModal
                header={viewTargetDialogOptions.data?.value}
                visible={
                    !!viewTargetDialogOptions.data &&
                    viewTargetDialogOptions.mode === 'debug'
                }
                displayData={viewTargetDialogOptions.data}
                onHide={onTargetViewModalHide}
                sortKeys={false}
            />
            {viewTargetDialogOptions.data && (
                <DetailTargetModal
                    style={{ width: '50%' }}
                    visible={
                        !!viewTargetDialogOptions.data &&
                        viewTargetDialogOptions.mode === 'detail'
                    }
                    onHide={onTargetViewModalHide}
                    target={viewTargetDialogOptions.data}
                    refAuthorization={refAuthorization ?? null}
                    refBlockOrder={refBlockOrder ?? null}
                    refUnblockOrder={refUnblockOrder ?? null}
                />
            )}
            {isViewValidatable && targetsSelect && (
                <Sidebar
                    position='bottom'
                    visible={!!targetsSelect}
                    onHide={() => {}}
                    showCloseIcon={false}
                    modal={false}
                    header={
                        <div className='flex flex-row justify-between items-center w-screen'>
                            <span>
                                {targetsSelect
                                    ? targetValidationLocale.selectedTargetsCountPlural.replace(
                                          '{count}',
                                          targetsSelect.targets.length.toString()
                                      )
                                    : targetValidationLocale.selectedTargetsCountSingular}
                            </span>
                            <div className='flex flex-row gap-2'>
                                {targetsSelect.mode === 'validate' && (
                                    <>
                                        <Button
                                            label={
                                                targetValidationPositiveLabelPlural
                                            }
                                            severity='success'
                                            icon={targetValidationPositiveIcon}
                                            onClick={() =>
                                                handleTargetConfirmation({
                                                    mode: 'positive',
                                                    targets:
                                                        targetsSelect.targets,
                                                })
                                            }
                                            disabled={
                                                validateTargetLoading ||
                                                props.loading
                                            }
                                        />
                                        <Button
                                            label={
                                                targetValidationNegativeLabelPlural
                                            }
                                            severity='danger'
                                            icon={targetValidationNegativeIcon}
                                            onClick={() =>
                                                handleTargetConfirmation({
                                                    mode: 'negative',
                                                    targets:
                                                        targetsSelect.targets,
                                                })
                                            }
                                            disabled={
                                                validateTargetLoading ||
                                                props.loading
                                            }
                                        />
                                    </>
                                )}
                                {targetsSelect.mode === 'revert' && (
                                    <Button
                                        label={targetValidationRevert}
                                        severity='warning'
                                        icon='pi pi-undo'
                                        onClick={() =>
                                            handleTargetConfirmation({
                                                mode: 'revert',
                                                revertMode:
                                                    targetsSelect.revertMode ===
                                                    'accepted'
                                                        ? 'accepted'
                                                        : 'rejected',
                                                targets: targetsSelect.targets,
                                            })
                                        }
                                        disabled={
                                            revertTargetsLoading ||
                                            props.loading
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    }
                    style={{ height: '60px' }}
                />
            )}
            <SafeIPRules />
            <CMDataTable
                dataKey='_id'
                columnConfigName='targets'
                lazy
                emptyMessage={datatableLocale.empty}
                rowClassName={(data: DetailedModel) => {
                    if (data.list_data?.safe_ip === false)
                        return [
                            'cm-isreview-row',
                            `grade-${data.list_data.highest_grading}`,
                        ].join(' ');
                }}
                filterOptions={visibleFilterOptions}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={(data: Ether.CaseManager.Target) => {
                    if (!showExpand) return null;
                    const modelId = refBlockOrder
                        ? refBlockOrder._id
                        : refUnblockOrder?._id ?? '';
                    return (
                        <OperatorsTargetSummary
                            model={
                                refBlockOrder ? 'block_order' : 'unblock_order'
                            }
                            modelId={modelId}
                            targetValue={data.value}
                        />
                    );
                }}
                {...props}
            >
                {expandable && <Column expander={true} />}
                {isViewValidatable && (
                    <Column
                        headerStyle={{ width: '3rem' }}
                        header={selectAllTargetsHeader}
                        body={(data: DetailedModel) => {
                            const canBeValidated = checkCanBeApproved(data);
                            const canBeReverted = checkCanBeReverted(data);
                            if (!canBeValidated && !canBeReverted) return null;
                            const mode = data._user_fields
                                ?.can_revert_validation
                                ? 'revert'
                                : 'validate';
                            const isRejected =
                                !!data.removed_authorizations?.find(
                                    (a) => a?._id === refAuthorization?._id
                                );
                            const index = (
                                targetsSelect?.targets ?? []
                            ).findIndex((t) => data._id === t._id);
                            const isChecked = index !== -1;
                            if (
                                !isChecked &&
                                targetsSelect &&
                                targetsSelect.mode !== mode
                            )
                                return null;

                            return (
                                <Checkbox
                                    checked={isChecked}
                                    onClick={() => {
                                        setTargetSelect((old) => {
                                            let newSelect = old;
                                            if (!old) {
                                                newSelect = {
                                                    targets: [],
                                                    mode: mode,
                                                    revertMode:
                                                        mode === 'revert'
                                                            ? isRejected
                                                                ? 'rejected'
                                                                : 'accepted'
                                                            : null,
                                                };
                                            } else {
                                                newSelect = {
                                                    ...old,
                                                    targets: [...old.targets],
                                                };
                                            }
                                            if (isChecked)
                                                newSelect.targets.splice(
                                                    index,
                                                    1
                                                );
                                            else newSelect.targets.push(data);
                                            if (newSelect.targets.length <= 0)
                                                return null;
                                            return newSelect;
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                )}
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => {
                        const prerejectedReasons = getPreRejectedReasons({
                            authorizationId: refAuthorization?._id,
                            target: data,
                            localization: localization,
                        });

                        const name = truncateTargetName(data.value);

                        const safeIpReasonsTooltipClass = `span-safeip-target-${data._id}`;
                        const alreadyBlockedTooltipClass = `span-alreadyblocked-target-${data._id}`;
                        const showReasons =
                            (prerejectedReasons?.length ?? 0) > 0;

                        const prerejectedMessages: string[] = [];

                        const latestBlockOrder = data.last_block_order;
                        const recentTimeDelta = 72 * 60 * 60 * 1000;
                        const isRecentOperation = latestBlockOrder?.created_at
                            ? Date.now() -
                                  latestBlockOrder.created_at.getTime() <=
                              recentTimeDelta
                            : false;
                        const localeOperation =
                            localization.components.models.target.text[
                                isRecentOperation
                                    ? 'alreadyBlockedRecent'
                                    : 'alreadyBlockedOld'
                            ];
                        if (latestBlockOrder)
                            prerejectedMessages.push(
                                localeOperation.replace(
                                    '{date}',
                                    localizeDateOnly(
                                        latestBlockOrder.created_at,
                                        currentLanguage
                                    )
                                )
                            );
                        const removedAuthorization =
                            data.removed_authorizations?.find(
                                (a) => a?._id === refAuthorization?._id
                            );
                        const prerejectedReason =
                            removedAuthorization?.pre_reprove_reasons?.find(
                                (r) =>
                                    r === 'same_operation' ||
                                    r === 'recently_notified'
                            );

                        let preRejectedMessage = prerejectedReason
                            ? localization.components.models.target.badge
                                  .preReprovedReason[prerejectedReason]
                            : null;
                        if (latestBlockOrder && preRejectedMessage)
                            preRejectedMessage = preRejectedMessage
                                .replace(
                                    '{blockOrderName}',
                                    latestBlockOrder.name
                                )
                                ?.replace(
                                    '{date}',
                                    localizeDateOnly(
                                        latestBlockOrder.created_at,
                                        currentLanguage
                                    )
                                );

                        if (preRejectedMessage)
                            prerejectedMessages.push(preRejectedMessage);

                        const showDangerIcon =
                            prerejectedMessages.length > 0 &&
                            config?.show_notified;

                        const canBeRemoved =
                            permissions.updateTargetOrderStatus &&
                            refBlockOrder?.status === 'draft' &&
                            data.list_data?.safe_ip === false;

                        return (
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row justify-start items-center gap-2'>
                                    {showReasons && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + safeIpReasonsTooltipClass
                                            }
                                        >
                                            <div className='flex flex-col gap-1'>
                                                {prerejectedReasons.map((r) => (
                                                    <span key={r}>{r}</span>
                                                ))}
                                            </div>
                                        </AdaptableTooltip>
                                    )}
                                    {!!showDangerIcon && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + alreadyBlockedTooltipClass
                                            }
                                        >
                                            <div className='flex flex-col'>
                                                {prerejectedMessages.map(
                                                    (m) => (
                                                        <span key={m}>{m}</span>
                                                    )
                                                )}
                                            </div>
                                        </AdaptableTooltip>
                                    )}
                                    {canBeRemoved && (
                                        <Button
                                            icon='pi pi-trash'
                                            severity='danger'
                                            onClick={() =>
                                                updateTargetOrderStatus({
                                                    order_id: refBlockOrder._id,
                                                    order_type: 'block_order',
                                                    status: 'remove',
                                                    target_id: data._id,
                                                    target_value: data.value,
                                                })
                                            }
                                            tooltip={
                                                localization.components.common
                                                    .button.remove
                                            }
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                        />
                                    )}
                                    <Button
                                        icon='pi pi-info-circle'
                                        size='small'
                                        tooltip={localization.common.details}
                                        onClick={() =>
                                            onTargetViewModalShow(
                                                data,
                                                'detail'
                                            )
                                        }
                                    />
                                    <a
                                        href={getTargetValueHrefLink(
                                            data.value
                                        )}
                                        rel='noreferrer noopener'
                                        target='_blank'
                                    >
                                        <Button
                                            icon='pi pi-external-link'
                                            size='small'
                                        />
                                    </a>
                                    <div
                                        className={
                                            'flex flex-row gap-2 items-center'
                                        }
                                    >
                                        <span title={data.value}>
                                            <NameIdAggregator
                                                name={name}
                                                id={data._id}
                                                onClick={() =>
                                                    copyToClipboard(data.value)
                                                }
                                            />
                                        </span>
                                        {showReasons && (
                                            <i
                                                className={
                                                    safeIpReasonsTooltipClass +
                                                    ' pi pi-question-circle text-yellow-500'
                                                }
                                            />
                                        )}
                                        {showDangerIcon && (
                                            <i
                                                className={
                                                    alreadyBlockedTooltipClass +
                                                    ' pi pi-exclamation-triangle text-red-500'
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                    sortable={sortable}
                />
                {columnElements}
                {(showOperatorAnswer || isViewValidatable) && (
                    <Column
                        field={columnFields['answered_at'].name}
                        header={localization.fields.target.answeredAt}
                        body={(data: DetailedModel) => {
                            if (!currentUserId) return '-';
                            const answer = getAnswer(data);
                            if (answer?.date == null) return '-';
                            return <DateBadge value={answer.date} />;
                        }}
                    />
                )}
                {(showOperatorAnswer || isViewValidatable) && (
                    <Column
                        field={columnFields['answer'].name}
                        header={localization.fields.target.approval}
                        body={(data: DetailedModel) => {
                            const elements: JSX.Element[] = [];
                            const canBeValidated = checkCanBeApproved(data);
                            const canBeReverted = checkCanBeReverted(data);

                            const tooltipClass = `span-pre-target-${data._id}`;
                            let tooltipMessage: string | JSX.Element | null =
                                null;

                            if (canBeValidated)
                                return (
                                    <div className='flex flex-row gap-2'>
                                        <Button
                                            tooltip={
                                                targetValidationPositiveLabelSingular
                                            }
                                            icon={targetValidationPositiveIcon}
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                            severity='success'
                                            onClick={() =>
                                                validateTargets({
                                                    targets: [data],
                                                    mode: 'positive',
                                                })
                                            }
                                            disabled={validateTargetLoading}
                                        />
                                        <Button
                                            tooltip={
                                                targetValidationNegativeLabelSingular
                                            }
                                            icon={targetValidationNegativeIcon}
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                            severity='danger'
                                            onClick={() =>
                                                validateTargets({
                                                    targets: [data],
                                                    mode: 'negative',
                                                })
                                            }
                                            disabled={validateTargetLoading}
                                        />
                                    </div>
                                );

                            const answer = getAnswer(data);
                            const removedAuthorization =
                                data.removed_authorizations?.find(
                                    (a) => a?._id === refAuthorization?._id
                                );
                            if (
                                removedAuthorization &&
                                removedAuthorization.pre_reprove_reasons
                            ) {
                                tooltipMessage = (
                                    <div className='flex flex-col gap-2'>
                                        {removedAuthorization.pre_reprove_reasons
                                            ?.map((r) => {
                                                let message =
                                                    localization.components
                                                        .models.target.badge
                                                        .preReprovedReason[r];
                                                if (
                                                    r === 'same_operation' ||
                                                    r === 'recently_notified'
                                                ) {
                                                    const latestBlockOrder =
                                                        data.last_block_order;
                                                    if (
                                                        r ===
                                                            'recently_notified' &&
                                                        latestBlockOrder
                                                    )
                                                        message = message
                                                            .replace(
                                                                '{blockOrderName}',
                                                                latestBlockOrder.name
                                                            )
                                                            .replace(
                                                                '{date}',
                                                                localizeDateOnly(
                                                                    latestBlockOrder.created_at,
                                                                    currentLanguage
                                                                )
                                                            );
                                                }
                                                return message;
                                            })
                                            .filter((r) => !!r)
                                            .map((r) => (
                                                <span key={r}>{r}</span>
                                            ))}
                                    </div>
                                );
                                elements.push(
                                    <NoWrapBadge
                                        className={tooltipClass}
                                        value={
                                            removedAuthorization.pre_reprove
                                                ? targetAnswerPreRejected
                                                : targetAnswerRejected
                                        }
                                        severity={targetAnswerRejectedSeverity}
                                    />
                                );
                            } else if (answer?.approved != null) {
                                if (answer.pre_approve)
                                    tooltipMessage =
                                        localization.components.models.target
                                            .badge.preApprovedReason
                                            .already_approved;
                                elements.push(
                                    answer.approved ? (
                                        <NoWrapBadge
                                            className={tooltipClass}
                                            value={
                                                answer.pre_approve
                                                    ? targetAnswerPreApproved
                                                    : targetAnswerApproved
                                            }
                                            severity={
                                                targetAnswerApprovedSeverity
                                            }
                                        />
                                    ) : (
                                        <NoWrapBadge
                                            className={tooltipClass}
                                            value={targetAnswerRejected}
                                            severity={
                                                targetAnswerRejectedSeverity
                                            }
                                        />
                                    )
                                );
                            }
                            if (canBeReverted) {
                                elements.push(
                                    <Button
                                        icon='pi pi-undo'
                                        severity='warning'
                                        onClick={() =>
                                            validateTargets({
                                                targets: [data],
                                                mode: 'revert',
                                                revertMode:
                                                    !!removedAuthorization
                                                        ? 'rejected'
                                                        : 'accepted',
                                            })
                                        }
                                        tooltip={
                                            localization.components.common
                                                .button.revert
                                        }
                                        tooltipOptions={{
                                            position: 'left',
                                        }}
                                        disabled={revertTargetsLoading}
                                    />
                                );
                            }
                            if (elements.length <= 0) return '-';
                            return (
                                <div className='flex flex-col gap-1 justify-center items-center'>
                                    {tooltipMessage && (
                                        <AdaptableTooltip
                                            target={'.' + tooltipClass}
                                        >
                                            {tooltipMessage}
                                        </AdaptableTooltip>
                                    )}
                                    {elements.map((t, i) => (
                                        <Fragment key={i}>{t}</Fragment>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(t) =>
                                onTargetViewModalShow(t, 'debug')
                            }
                        />
                    )}
                />
            </CMDataTable>
            {targetsSelect && <div style={{ height: '120px' }} />}
        </>
    );
};

export default TargetsDataTable;
