import { CustomFilterElementProps } from '../../FilterBoxCustomElementFilter';
import SelectManyAuthorizationConfigs from 'components/models/AuthorizationConfig/SelectManyAuthorizationConfigs';

/**
 * Custom filter wrapping the AuthorizationConfig Select for the FilterBox
 * @param props.value - The current selected value
 * @param props.onChange - What value and label to change to
 * @returns A custom dropdown filter element
 */
const FilterBoxMultiselectAuthorizationConfig: React.FC<
    CustomFilterElementProps<string[]>
> = ({ value, onChange }) => {
    return (
        <SelectManyAuthorizationConfigs
            hideTitle
            onChange={(items, options) =>
                onChange({
                    value: items.map((i) => i._id) ?? [],
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                })
            }
            onLoad={(options) => {
                const items = options.filter(
                    (o) => !!value && value.find((v) => v === o._id)
                );
                onChange({
                    value: items.map((i) => i._id),
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                });
            }}
            value={value}
            label={undefined}
        />
    );
};

export default FilterBoxMultiselectAuthorizationConfig;
