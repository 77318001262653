import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import { detailManyBlockOrder } from 'services/ether/case-manager/block-orders';
import { useProject } from 'hooks/context/project/useProject';
import { SortOrder } from 'primereact/api';
import BlockOrderDataTable from 'components/models/BlockOrder/BlockOrderDataTable';
import { detailManyAuthorization } from 'services/ether/case-manager/authorizations';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import LoadingMessage from 'components/misc/LoadingMessage';
import { HomeButtonSet } from '../../components/HomeButtonSet';

/**
 * Home page view for the user with Authorizer role, as known as Regulador/Authorizer-User
 * @returns Functional Component for the home
 */
const AuthorizerHome = () => {
    const project = useProject();
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const metricsQuery = useMetricsQuery(project._id);

    const authorizationBadge =
        localization.components.models.authorization.badge.status;
    const blockOrderBadge =
        localization.components.models.blockOrder.badge.status;

    const authorizationViews: TableWithTabView[] = [];
    const blockOrderViews: TableWithTabView[] = [];

    if (permissions.viewBlockOrders) {
        blockOrderViews.push(
            {
                fetchData: (params) =>
                    detailManyBlockOrder({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            limit: 5,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                        },
                    }),
                label: localization.models.blockOrder.plural,
                totalCount: metricsQuery.data?.total_block_orders ?? 0,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    return <BlockOrderDataTable {...rest} />;
                },
                model: 'blockOrder',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyBlockOrder({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            limit: 5,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                status: 'done',
                            },
                        },
                    }),
                label: blockOrderBadge.doneWithName,
                totalCount: metricsQuery.data?.block_order_status_done ?? 0,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    return <BlockOrderDataTable {...rest} />;
                },
                model: 'blockOrder',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyBlockOrder({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            limit: 5,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                status: 'draft',
                            },
                        },
                    }),
                label: blockOrderBadge.draftWithName,
                totalCount: metricsQuery.data?.block_order_status_draft ?? 0,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    return <BlockOrderDataTable {...rest} />;
                },
                model: 'blockOrder',
                overrideFilters: {},
            }
        );
    }

    if (permissions.viewAuthorizations) {
        authorizationViews.push(
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                        },
                    }),
                label: localization.models.authorization.plural,
                totalCount: metricsQuery.data?.total_authorizations,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'draft',
                            },
                        },
                    }),
                label: authorizationBadge.draftWithName,
                totalCount: metricsQuery.data?.authorization_status_draft,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'pending',
                            },
                        },
                    }),
                label: authorizationBadge.pendingWithName,
                totalCount: metricsQuery.data?.authorization_status_pending,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            },
            {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'approved',
                            },
                        },
                    }),
                label: authorizationBadge.doneWithName,
                totalCount: metricsQuery.data?.authorization_status_approved,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                model: 'authorization',
                overrideFilters: {},
            }
        );
    }

    if (metricsQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    return (
        <>
            <p>
                {
                    localization.components.views.homeDashboard
                        .authorizerDescription
                }
            </p>
            <section className='flex flex-col gap-4'>
                <div className='flex gap-2 justify-between items-center'>
                    <h2 className='self-start mr-auto'>
                        {localization.models.blockOrder.plural}
                    </h2>
                    <HomeButtonSet
                        buttons={['insert-blockOrder', 'insert-operation', 'list-blockOrder']}
                    />
                </div>
                <ViewTablesWithTabs
                    mainModel={null}
                    uniqueQueryKey='home'
                    views={blockOrderViews}
                />
                <div className='flex justify-between items-center gap-2'>
                    <h2 className='self-start mr-auto'>
                        {localization.models.authorization.plural}
                    </h2>
                    <HomeButtonSet
                        buttons={['insert-authorization', 'list-authorization']}
                    />
                </div>
                <ViewTablesWithTabs
                    mainModel={null}
                    uniqueQueryKey='home'
                    views={authorizationViews}
                />
            </section>
        </>
    );
};

export default AuthorizerHome;
