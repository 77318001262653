import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { FilterMatchMode } from 'primereact/api';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['company']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['company']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Company DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useCompanyColumnFields = () => {
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.company.datatable;
        // TODO : GET FROM API
        const defaultTypeOptions = [
            { label: 'Operadora', value: 'operator' },
            { label: 'Entidade', value: 'entity' },
            { label: 'Parceiro', value: 'partner' },
            { label: 'Labs', value: 'labs' },
        ];

        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            type: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.company.type,
                    placeholder: datatableLocale.selectType,
                    type: 'dropdown',
                    selectOptions: defaultTypeOptions,
                    relatedColumn: 'type',
                },
            },
        };
        return filterFields;
    }, [localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            fantasy_name: { name: 'fantasy_name' },
            cnpj: { name: 'cnpj' },
            type: {
                name: 'type',
            },
            uf: { name: 'uf' },
            users_count: { name: 'users_count' },
            target_progress: { name: 'target_progress' },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useCompanyColumnFields;
