import { useLocalization } from 'hooks/context/useLocalization';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import useListFlags from 'hooks/queries/tag/useListFlags';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';

const SelectOneFlag: React.FC<
    SelectOneItemProps<Ether.CaseManager.Tag & { label : string }, DetailTagEP.Filters.Options>
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();

    const query = useListFlags();

    return (
        <SelectOneItem
            title={localization.models.tag.types.flag.singular}
            placeholder={
                localization.components.models.tag.types.flag.form
                    .selectOnePlaceholder
            }
            label={localization.models.tag.types.flag.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            optionLabel='label'
            {...props}
        />
    );
};

export default SelectOneFlag;
