import SearchTargetsButton from 'components/models/Targets/SearchTargetsButton';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useInsertDocumentDialog from 'hooks/dialogs/document/useInsertDocumentDialog';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';
import useCreateOperationDialog from 'hooks/models/operation/useCreateOperationDialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

type Model =
    | 'authorization'
    | 'file'
    | 'officialDocument'
    | 'target'
    | 'blockOrder'
    | 'operation';

const isModelType = (model: string): model is Model => {
    const map : Record<Model, true> = {
        'authorization' : true,
        'file' : true,
        'officialDocument' : true,
        'target' : true,
        'blockOrder' : true,
        'operation' : true,
    }
    return model in map;
};

const AddButton: React.FC<{ models: Model[] }> = ({ models }) => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const insertMenuRef = useRef<Menu>(null);

    const { dialog: insertDocumentDialog, showInsert: showInsertDocument } =
        useInsertDocumentDialog({
            type: 'document',
        });

    const {
        dialog: insertOfficialDocDialog,
        showInsert: showInsertOfficialDoc,
    } = useInsertDocumentDialog({
        type: 'official_document',
    });

    const {
        element: UpsertAuthorizationDialog,
        showCreate: showUpsertAuthorizationDialog,
    } = useUpsertAuthorizationDialog();

    const [menuItems, requiredElements] = useMemo(() => {
        const requiredElements: (JSX.Element | boolean)[] = [];

        const menuItems: MenuItem[] = models
            .map<MenuItem | null>((model) => {
                switch (model) {
                    case 'file':
                        if (!permissions.insertDocuments) return null;
                        requiredElements.push(insertDocumentDialog);
                        return {
                            label: localization.models.document.singular,
                            command: () => showInsertDocument(),
                        };
                    case 'officialDocument':
                        if (!permissions.insertDocuments) return null;
                        requiredElements.push(insertOfficialDocDialog);
                        return {
                            label: localization.models.oficio.singular,
                            command: () => showInsertOfficialDoc(),
                        };
                    case 'authorization':
                        if (!permissions.insertAuthorizations) return null;
                        requiredElements.push(<UpsertAuthorizationDialog />);
                        return {
                            label: localization.models.authorization.singular,
                            command: () => showUpsertAuthorizationDialog,
                        };
                }
                return {
                    label: `UNHANDLED ${model}`,
                };
            })
            .filter<MenuItem>((item): item is MenuItem => !!item);

        return [menuItems, requiredElements];
    }, [
        localization,
        models,
        permissions,
        insertDocumentDialog,
        insertOfficialDocDialog,
        showInsertDocument,
        showInsertOfficialDoc,
        showUpsertAuthorizationDialog,
        UpsertAuthorizationDialog,
    ]);

    return (
        <div>
            {requiredElements}
            <Menu
                popup
                popupAlignment='right'
                ref={insertMenuRef}
                model={menuItems}
            />
            <Button
                icon='pi pi-plus'
                label={localization.components.common.button.add}
                onClick={(e) => insertMenuRef.current?.toggle(e)}
            />
        </div>
    );
};

const InsertAuthorizationButton = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const {
        element: UpsertAuthorizationDialog,
        showCreate: showUpsertAuthorizationDialog,
    } = useUpsertAuthorizationDialog();

    if (!permissions.insertAuthorizations) return null;

    return (
        <div>
            <UpsertAuthorizationDialog />
            <Button
                label={localization.components.models.authorization.button.new}
                icon='pi pi-plus'
                onClick={showUpsertAuthorizationDialog}
                className='ml-auto'
            />
        </div>
    );
};

const ListAuthorizationButton = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    if (!permissions.viewAuthorizations) return null;
    return (
        <Link to='/authorizations'>
            <Button
                label={
                    localization.components.models.authorization.button.viewAll
                }
            />
        </Link>
    );
};

const ListBlockOrderButton = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    if (!permissions.viewBlockOrders) return null;
    return (
        <Link to='/block-orders'>
            <Button
                label={localization.components.models.blockOrder.button.viewAll}
            />
        </Link>
    );
};

const InsertBlockOrderButton = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const { element: UpsertBlockOrderDialog, showCreate } =
        useUpsertBlockOrderDialog();

    if (!permissions.insertBlockOrders) return null;

    return (
        <div>
            <UpsertBlockOrderDialog />
            <Button
                label={localization.components.models.blockOrder.button.new}
                icon='pi pi-plus'
                onClick={() => showCreate()}
            />
        </div>
    );
};

const InsertOperationButton = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const { element: CreateOperationDialog, show: showCreateOperationDialog } =
        useCreateOperationDialog();

    if (!permissions.insertOperation) return null;

    return (
        <div>
            <CreateOperationDialog />
            <Button
                icon='pi pi-plus'
                label={localization.components.models.operation.button.new}
                onClick={showCreateOperationDialog}
            />
        </div>
    );
};

// Buttons are labeled in a category-model manner
type FileButtons = 'add-file' | 'add-officialDocument';
type TargetButtons = 'search-target';
type AuthorizationButtons =
    | 'insert-authorization'
    | 'add-authorization'
    | 'list-authorization';
type BlockOrderButtons = 'insert-blockOrder' | 'list-blockOrder';
type OperationButtons = 'insert-operation';

type HomeButtons =
    | FileButtons
    | TargetButtons
    | AuthorizationButtons
    | BlockOrderButtons
    | OperationButtons;

/**
 * Component to render various buttons for the Home page in a modular way. It will follow the order of the buttons that were provided
 * @param props.buttons The list of buttons by its code
 * @returns Buttons to be appended to the page
 */
export const HomeButtonSet: React.FC<{
    buttons: HomeButtons[];
}> = ({ buttons: buttonList }) => {
    const buttons = useMemo(() => {
        const buttons: JSX.Element[] = [];
        const addButtonModels: Model[] = [];

        const checkAddButton = (value: Model) => {
            addButtonModels.push(value);
            if (addButtonModels.length > 1) return;
            buttons.push(
                <AddButton models={addButtonModels} key={'add-' + value} />
            );
        };

        buttonList.forEach((buttonKey) => {
            const [category, model] = buttonKey.split('-');
            if (!model || !isModelType(model)) return;
            switch (category) {
                case 'add':
                    checkAddButton(model);
                    break;
                case 'search':
                    if (model === 'target')
                        buttons.push(<SearchTargetsButton key={buttonKey} />);
                    break;
                case 'insert':
                    if (model === 'authorization')
                        buttons.push(
                            <InsertAuthorizationButton key={buttonKey} />
                        );
                    if (model === 'blockOrder')
                        buttons.push(
                            <InsertBlockOrderButton key={buttonKey} />
                        );
                    if (model === 'operation')
                        buttons.push(<InsertOperationButton key={buttonKey} />);
                    break;
                case 'list':
                    if (model === 'authorization')
                        buttons.push(
                            <ListAuthorizationButton key={buttonKey} />
                        );
                    if (model === 'blockOrder')
                        buttons.push(<ListBlockOrderButton key={buttonKey} />);
            }
        });

        return buttons;
    }, [buttonList]);

    return buttons;
};
