import { useLocalization } from 'hooks/context/useLocalization';
import { IntermediateFilter } from '../../types';
import { InputText } from 'primereact/inputtext';
import { DropdownProps } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { StringMatchModeDropdown } from '../matchModesComponents';
import { DispatchApplyFilter } from '../FilterBoxComponent';

/**
 * InputText filter for the FilterBox
 * @param object.temporaryFilter - The raw temporary filter object that includes which value and matchMode is using
 * @param object.setTemporaryFilter - The set dispatch action that will update the temporary raw filter object
 * @param object.applyFilter - The action that will apply the input/selected value and label to the final filter, passing alongside the HTML event
 * @param object.placeholder - The placeholder to show inside the InputText element
 * @param object.hideMatchMode - If true, the MatchMode will not be selectable
 * @param object.dropdownProps - Props for the matchMode dropdown select
 * @returns InputText filter element
 */
const FilterBoxInputTextFilter: React.FC<{
    temporaryFilter: IntermediateFilter;
    setTemporaryFilter: React.Dispatch<React.SetStateAction<IntermediateFilter>>;
    applyFilter: DispatchApplyFilter;
    dropdownProps: DropdownProps;
    placeholder?: string;
    hideMatchMode?: boolean;
}> = ({
    temporaryFilter,
    setTemporaryFilter,
    applyFilter,
    dropdownProps,
    placeholder,
    hideMatchMode,
}) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    return (
        <div className='flex flex-col gap-2 w-56'>
            {!hideMatchMode && <StringMatchModeDropdown {...dropdownProps} />}
            <InputText
                value={temporaryFilter.value as string}
                placeholder={placeholder}
                onChange={(e) =>
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: e.target.value,
                    }))
                }
            />
            <Button
                label={primereact.apply}
                onClick={(e) => {
                    applyFilter({
                        event : e,
                        filters : {
                            ...temporaryFilter,
                        }
                    });
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: '',
                    }));
                }}
            />
        </div>
    );
};

export default FilterBoxInputTextFilter;
