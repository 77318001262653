import SelectOneBlockOrderType from 'components/models/BlockOrder/SelectOneBlockOrderType';
import { CustomFilterElementProps } from '../../FilterBoxCustomElementFilter';

/**
 * Custom filter wrapping the Block Order Type Select for the FilterBox
 * @param props.value - The current selected value
 * @param props.onChange - What value and label to change to
 * @returns A custom dropdown filter element
 */
const FilterBoxDropdownBlockOrderType: React.FC<CustomFilterElementProps> = ({
    value,
    onChange,
}) => {
    return (
        <SelectOneBlockOrderType
            hideTitle
            onChange={(item, options) =>
                onChange({
                    value: item?.value ?? null,
                    selectOptions: options,
                })
            }
            onLoad={(options) => {
                const item = options.find(o => o.value === value);
                onChange({
                    value: item?.value ?? null,
                    selectOptions: options,
                })
            }}
            value={value}
            label={undefined}
        />
    );
};

export default FilterBoxDropdownBlockOrderType;
