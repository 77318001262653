import { CustomFilterElementProps } from '../../FilterBoxCustomElementFilter';
import SelectOneCountry from 'components/models/Tag/SelectOneCountry';

/**
 * Custom filter wrapping the Country Tag Select for the FilterBox
 * @param props.value - The current selected value
 * @param props.onChange - What value and label to change to
 * @returns A custom dropdown filter element
 */
const FilterBoxDropdownTagCountry: React.FC<CustomFilterElementProps> = ({
    value,
    onChange,
}) => {
    return (
        <SelectOneCountry
            hideTitle
            onChange={(item, options) =>
                onChange({
                    value: item?._id ?? null,
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                })
            }
            onLoad={(options) => {
                const item = options.find(o => o._id === value);
                onChange({
                    value: item?._id ?? null,
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                })
            }}
            value={value}
            label={undefined}
        />
    );
};

export default FilterBoxDropdownTagCountry;
