import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { ListBlockOrdersUnblockEP } from 'services/ether/case-manager/block-orders/types';
import useListBlockOrderUnblock from 'hooks/queries/block-order/useListBlockOrderUnblock';
import { SortOrder } from 'primereact/api';

/**
 * Select from various block order, but within a given flow and config. This is used inside the create unblock order form, but may be used in other parts
 *
 */
const SelectManyBlockOrdersUnblock: React.FC<
    SelectManyItemsProps<
        Ether.CaseManager.BlockOrder,
        ListBlockOrdersUnblockEP.Filters.Options
    > & {
        requiredOptions: ListBlockOrdersUnblockEP.Filters.RequiredOptions;
    }
> = ({ queryOptions, requiredOptions, ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();

    const query = useListBlockOrderUnblock({
        project_id: project._id,
        options: {
            sort: { field: 'name', order: SortOrder.ASC },
            limit: 0,
            ...queryOptions,
        },
        ...requiredOptions,
    });

    return (
        <SelectManyItems
            title={localization.models.blockOrder.plural}
            placeholder={
                localization.components.models.blockOrder.form
                    .selectManyPlaceholder
            }
            label={localization.models.blockOrder.plural}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyBlockOrdersUnblock;
