import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { getUserDisplayName } from 'utils/models/user';
import { Badge } from 'primereact/badge';
import useOperatorColumnFields from 'hooks/columns/models/useOperatorColumnFields';

// import OperatorsblockOrderEmailStatus from '../OperatorEmailStatus';

type DetailedModel = Ether.CaseManager.Operator.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (operator: DetailedModel) => void;
}> = ({ item, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugOperators
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.datatable
                          .menuDetailItem,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const OperatorDataTable: React.FC<
    DataTableProps<DetailedModel[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            targetAnswerModel?: 'block_order' | 'unblock_order';
        }
> = ({ targetAnswerModel, ...props }) => {
    const [localization] = useLocalization();

    const { columnFields, filterOptions } = useOperatorColumnFields();

    const [viewOperatorDialogOptions, setViewOperatorDialogOptions] = useState<{
        visible: boolean;
        data: DetailedModel | null;
    }>({
        visible: false,
        data: null,
    });

    const onOperatorViewModalShow = (
        data: DetailedModel
    ) =>
        setViewOperatorDialogOptions({
            visible: true,
            data: data,
        });

    const onOperatorViewModalHide = () =>
        setViewOperatorDialogOptions((old) => ({
            ...old,
            visible: false,
        }));

    return (
        <>
            <ObjectDisplayModal
                header={getUserDisplayName(
                    viewOperatorDialogOptions.data?.users_data?.[0]
                )}
                visible={viewOperatorDialogOptions.visible}
                displayData={viewOperatorDialogOptions.data}
                onHide={onOperatorViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='operators'
                lazy
                emptyMessage={localization.components.models.operator.datatable.empty}
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(rowData: DetailedModel) => {
                        return (
                            <NameIdAggregator
                                name={rowData.contact.email}
                                id={rowData._id}
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['tags'].name}
                    header={localization.fields.operator.operatorTags}
                    body={(rowData: DetailedModel) => {
                        return rowData.tags.join(', ');
                    }}
                />
                <Column
                    field={
                        columnFields[
                            'last_notification_data.last_sent_notification'
                        ].name
                    }
                    header={localization.fields.operator.lastNotificationAt}
                    body={(rowData: DetailedModel) => {
                        const date =
                            rowData.last_notification_data
                                ?.last_sent_notification;
                        return date ? <DateBadge value={date} /> : '-';
                    }}
                />
                {targetAnswerModel && (
                    <Column
                        field='target_searched_answer'
                        header={localization.fields.operator.targetResponse}
                        body={(data: DetailedModel) => {
                            const responses =
                                data.target_searched?.order_responses;
                            if (!responses) return '-';
                            const selfResponse = responses.find(
                                (r) =>
                                    r.user_id === data._id &&
                                    r.model === targetAnswerModel
                            );
                            if (selfResponse?.blocked == null) return '-';
                            return selfResponse.blocked ? (
                                <Badge
                                    value={
                                        localization.components.models.target
                                            .badge.approval.blocked
                                    }
                                    severity='danger'
                                />
                            ) : (
                                <Badge
                                    value={
                                        localization.components.models.target
                                            .badge.approval.notBlocked
                                    }
                                    severity='success'
                                />
                            );
                        }}
                    />
                )}
                {targetAnswerModel && (
                    <Column
                        field='target_searched_answer_at'
                        header={localization.fields.operator.targetRespondedAt}
                        body={(data: DetailedModel) => {
                            const responses =
                                data.target_searched?.order_responses;
                            if (!responses) return '-';
                            const selfResponse = responses.find(
                                (r) =>
                                    r.user_id === data._id &&
                                    r.model === targetAnswerModel
                            );
                            if (!selfResponse?.response_date) return '-';
                            return (
                                <DateBadge value={selfResponse.response_date} />
                            );
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(rowData: DetailedModel) => (
                        <RowMenu
                            item={rowData}
                            onShowDetail={onOperatorViewModalShow}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default OperatorDataTable;
