import { Navigate, useParams } from 'react-router-dom';
import useGetDashboardURL from 'hooks/queries/dashboard/useGetDashboardURL';
import NotFound from 'components/page/NotFound';
import DashboardIframe from 'components/models/Dashboard/DashboardIframe';
import { useLocalization } from 'hooks/context/useLocalization';
import LoadingMessage from 'components/misc/LoadingMessage';
import useUserDashboards from 'hooks/models/dashboard/useUserDashboards';

const DetailDashboard = () => {
    const params = useParams<{ dashboardSlug: string }>();
    const [localization] = useLocalization();
    const { dashboardsPermissionMap } = useUserDashboards();

    const dashboardSlug =
        params.dashboardSlug as CaseManagerApp.DashboardConfig.Slug;

    const hasPermission = dashboardsPermissionMap[dashboardSlug];

    const dashboardQuery = useGetDashboardURL(
        {
            dashboard_slug: dashboardSlug,
        },
        {
            enabled: hasPermission,
        }
    );

    if (!hasPermission) return <Navigate to='/' />;

    if (dashboardQuery.query.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (!dashboardQuery.query.data) return <NotFound />;

    const title =
        localization.components.models.dashboard.badge.slug[dashboardSlug] ??
        null;
    const finalTitle = title
        ? localization.models.dashboard.singular + ' - ' + title
        : localization.models.dashboard.singular;

    return (
        <section>
            <h1>{finalTitle}</h1>
            <DashboardIframe
                title={localization.models.dashboard.singular}
                data={dashboardQuery.query.data}
            />
        </section>
    );
};

export default DetailDashboard;
