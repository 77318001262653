import { CMDataTableFiltersFields } from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';
import {
    getDefaultTypeOptions,
    getYesNoOptions,
} from 'components/models/Targets/TargetsDataTable/utils';
import useTargetColumnFields from './useTargetColumnFields';
import FilterBoxDropdownBlockOrderType from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownBlockOrderType';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['targets']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['targets']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Simplified Target DataTable model, a separate view which includes less filters
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useSimplifiedTargetColumnFields = () => {
    const [localization] = useLocalization();

    const { columnFields } = useTargetColumnFields();

    const filterFields = useMemo(() => {
        const defaultTypeOptions = getDefaultTypeOptions(localization);
        const yesNoOptions = getYesNoOptions(localization);

        const fields = localization.fields.target;
        const datatableLocale = localization.components.models.target.datatable;

        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectValue,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            type: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.type,
                    placeholder: datatableLocale.selectType,
                    type: 'dropdown',
                    selectOptions: defaultTypeOptions,
                    relatedColumn: 'type',
                },
            },
            notified: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.notified,
                    type: 'dropdown',
                    selectOptions: yesNoOptions,
                    relatedColumn: 'notified',
                },
            },
            type_order: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.typeOrder,
                    type: 'custom',
                    element: FilterBoxDropdownBlockOrderType,
                    initialValue: null,
                    relatedColumn: '_cm_name_id',
                },
            },
        };

        return filterFields;
    }, [localization]);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useSimplifiedTargetColumnFields;
