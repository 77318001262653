import { Logo } from 'components/ethercity-primereact';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import LoadingMessage from 'components/misc/LoadingMessage';
import { getModelViaToken } from 'services/ether/case-manager/accept-token';
import DetailedBlockOrderViewSync from 'components/models/BlockOrder/DetailedBlockOrderViewSync';
import LocalizationSync from 'static/LocalizationSync';
import { useBlockOrderRespond } from 'hooks/models/blockOrder/useBlockOrderRespond';
import NotFound from 'components/page/NotFound';

const AcceptBlockOrderWrapper = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localization] = useLocalization();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const token = params.get('token');

    const acceptTokenQuery =
        useQuery<Ether.CaseManager.AcceptToken.Detailed | null>({
            queryKey: ['get-accept-token', token],
            queryFn: () => getModelViaToken(token ?? ''),
            enabled: !!token,
            retry: 1,
        });

    const modelToken = acceptTokenQuery.data;
    const blockOrder = modelToken?.models_data[0];
    const project = blockOrder ? blockOrder.project_data?.[0] : undefined;

    if (!token) return <Navigate to='/' />;

    if (acceptTokenQuery.isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;

    const showErrorMessage = (error: unknown): React.ReactNode => {
        let message: string;
        const { generic: errorMessage } = localization.validations;
        if (error instanceof Error) {
            if (error.message.endsWith('invalid token'))
                message = errorMessage.invalidToken;
            else message = error.message;
        } else {
            message = errorMessage.unhandled;
        }
        return (
            <div>
                <h3>{message}</h3>
                <Button
                    label={localization.components.common.button.back}
                    onClick={() => navigate('/')}
                />
            </div>
        );
    };

    if (acceptTokenQuery.isError || modelToken?.model !== 'block_order')
        return showErrorMessage(acceptTokenQuery.error);

    if (!modelToken || !blockOrder) return <NotFound />;

    return (
        <LocalizationSync user={null} project={project}>
            <AcceptBlockOrder
                acceptTokenQuery={acceptTokenQuery}
                token={token}
                project={project}
            />
        </LocalizationSync>
    );
};

const AcceptBlockOrder: React.FC<{
    acceptTokenQuery: UseQueryResult<
        Ether.CaseManager.AcceptToken.Detailed | null,
        unknown
    >;
    token: string;
    project: Ether.CaseManager.Project | undefined;
}> = ({ acceptTokenQuery, token, project }) => {
    const [localization] = useLocalization();
    const thisPage = localization.components.models.blockOrder.views.accept;

    const modelToken = acceptTokenQuery.data;
    const blockOrder = modelToken
        ?.models_data[0] as Ether.CaseManager.BlockOrder.Detailed;

    const refresh = () => acceptTokenQuery.refetch();

    const [acceptBlockOrder, status] = useBlockOrderRespond({
        token,
        onRespond: refresh,
    });

    if (!token) return <Navigate to='/' />;

    const answeredByUser = blockOrder?.operators_order_data?.find(
        (i) =>
            i.response !== null &&
            i.operator_id === acceptTokenQuery.data?.user_id
    );

    return (
        <>
            <section
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '12px',
                }}
            >
                <div
                    style={{
                        alignSelf: 'flex-start',
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    <Logo label='CaseManager' labelRatio={0.9} />
                    <h2>{thisPage.title}</h2>
                </div>
                {acceptTokenQuery.isLoading && (
                    <h3>{localization.common.loading}...</h3>
                )}
                {acceptTokenQuery.isSuccess && (
                    <>
                        {answeredByUser && (
                            <>
                                <h4>{thisPage.alreadyAnswered}</h4>
                                <Link to='/'>
                                    <Button
                                        label={
                                            localization.components.common
                                                .button.leave
                                        }
                                    />
                                </Link>
                            </>
                        )}
                        <DetailedBlockOrderViewSync
                            blockOrder={blockOrder}
                            isLoading={acceptTokenQuery.isLoading}
                            error={acceptTokenQuery.error}
                            handleAccept={(blockOrder) =>
                                acceptBlockOrder(blockOrder, true)
                            }
                            isSubmitting={status.isLoading}
                            hideBackButton
                            hideViewAllButton
                            hideInsertButton
                            onRefresh={refresh}
                            tokenData={{
                                value: token,
                                project: project ?? null,
                                userId: modelToken?.user_id ?? null,
                                userRole: modelToken?.user_type ?? null,
                            }}
                        />
                    </>
                )}
            </section>
        </>
    );
};

export default AcceptBlockOrderWrapper;
