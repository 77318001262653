import { SelectInternalProps, SelectOneItemProps } from '../types';
import BaseSelectItems from '../BaseSelectItems';

function SelectOneItem<T extends Record<string, any>>(
    props: React.PropsWithChildren<
        SelectInternalProps<T> & SelectOneItemProps<T>
    >
) {
    return <BaseSelectItems {...props} type='one' />;
}

export default SelectOneItem;
