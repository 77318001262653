import { useAuth } from 'hooks/context/useAuth';
import { useMemo } from 'react';

/**
 * Get all user available dashboards on the company's config
 * @returns object.dashboards - All available dashboards for the user
 * @returns object.dashboardsPermission - A permission map checking if the user has permission to view specified dash. Quick and easy way to avoid array iteration
 */
const useUserDashboards = () => {
    const { user } = useAuth();
    const { relatedCompany, role } = user;

    const userDashboards = useMemo(() => {
        const dashboardsPermissionMap: Partial<
            Record<CaseManagerApp.DashboardConfig.Slug, boolean>
        > = {};
        const dashboards = relatedCompany?.app_config?.dashboards?.filter(
            (d) => {
                const allowed = !d.roles || (role && d.roles.includes(role));
                dashboardsPermissionMap[d.slug] = allowed;
                return allowed;
            }
        ) ?? [];
        return {
            dashboards,
            dashboardsPermissionMap,
        };
    }, [relatedCompany, role]);

    return userDashboards;
};

export default useUserDashboards
