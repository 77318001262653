import NoWrapBadge from 'components/display/NoWrapBadge';
import { useLocalization } from 'hooks/context/useLocalization';
import { getESTICurrentStatus } from 'utils/models/target/target';

export const EnrichDisplayDone = () => {
    const [localization] = useLocalization();
    return (
        <NoWrapBadge
            severity='success'
            value={
                <div className='flex flex-row gap-1 items-center'>
                    <i className='pi pi-check no-color-override' />
                    <span>
                        {
                            localization.components.models.target.badge.enrich
                                .done
                        }
                    </span>
                </div>
            }
        />
    );
};

export const EnrichDisplayError = () => {
    const [localization] = useLocalization();
    return (
        <NoWrapBadge
            severity='danger'
            value={
                <div className='flex flex-row gap-1 items-center'>
                    <i className='pi pi-ban no-color-override' />
                    <span>
                        {
                            localization.components.models.target.badge.enrich
                                .error
                        }
                    </span>
                </div>
            }
        />
    );
};

export const EnrichDisplayPending = () => {
    const [localization] = useLocalization();
    return (
        <NoWrapBadge
            severity='warning'
            value={
                <div className='flex flex-row gap-1 items-center'>
                    <i className='pi pi-exclamation-triangle no-color-override' />
                    <span>
                        {
                            localization.components.models.target.badge.enrich
                                .pending
                        }
                    </span>
                </div>
            }
        />
    );
};

const TargetEnrichmentField: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    let final = <EnrichDisplayDone />;
    const status = getESTICurrentStatus({
        target,
    });
    if (status === 'pending') final = <EnrichDisplayPending />;
    else if (status === 'error') final = <EnrichDisplayError />;
    return <span className='cm-target-enrichment-field'>{final}</span>;
};

export default TargetEnrichmentField;
