import SelectManyPirateBrands from 'components/models/Tag/SelectManyPirateBrands';
import { CustomFilterElementProps } from '../../FilterBoxCustomElementFilter';

/**
 * Custom filter wrapping the Pirate Brand MultiSelect for the FilterBox
 * @param props.value - The current selected values
 * @param props.onChange - What value and label to change to
 * @returns A custom multiselect filter element
 */
const FilterBoxMultiselectTagPirateBrand: React.FC<
    CustomFilterElementProps
> = ({ onChange, value }) => {
    return (
        <SelectManyPirateBrands
            hideTitle
            onChange={(items, options) =>
                onChange({
                    value: items.map((item) => item._id),
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                })
            }
            onLoad={(options) => {
                const items = options.filter((o) => o._id === value);
                onChange({
                    value: items.map((item) => item._id),
                    selectOptions: options.map((i) => ({
                        label: i.name,
                        value: i._id,
                    })),
                });
            }}
            value={value}
            label={undefined}
        />
    );
};

export default FilterBoxMultiselectTagPirateBrand;
