import { useAuth } from 'hooks/context/useAuth';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getDashboardEmbed } from 'services/ether/case-manager/dashboard';
import { GetDashboardEP } from 'services/ether/case-manager/dashboard/types';

/**
 * Hook that automatically fetches the dashboard info using useQuery
 * Automatically disables the query if user does not have the permissions
 * Prefer to use this instead of writing your own useQuery hook
 * @param params.dashboard_slug - The specific dashboard to be retrieve from the API
 * @param queryOptions - Additional query options that may be given to the Tanstack's useQuery hook
 * @returns object.query - Query object containing all of the useQueryWithRefresh properties
 * @returns object.isAvailable - If the query is available for use
 */
const useGetDashboardURL = (
    params: GetDashboardEP.Params,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['dashboard', 'get', params];
    const { permissions } = useAuth();

    const isDisabled =
        queryOptions?.enabled === false || !permissions.getDashboard;

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            getDashboardEmbed({
                params,
                signal,
            }),
        enabled: !isDisabled,
        gcTime: 1000 * 60 * 60,
    });

    return {
        query,
        isDisabled,
    };
};

export default useGetDashboardURL;
