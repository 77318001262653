import { Dropdown, MultiSelect } from 'components/ethercity-primereact';
import React, { useEffect } from 'react';
import { SelectInternalProps } from '../types';
import { useLocalization } from 'hooks/context/useLocalization';

function BaseSelectItems<T extends Record<string, any>>({
    value,
    onChange,
    type,

    items,
    itemsLoading,
    onLoad,
    additionalItems,
    overrideItems,
    excludeItems,

    optionLabel = 'name',
    optionValue = '_id',

    mapItems,

    label,
    title,
    placeholder,
    hideTitle,

    hidden,
    loading,
    required,
    disabled,
    showClear,
}: React.PropsWithChildren<
    SelectInternalProps<T> &
        (
            | {
                  type: 'one';
                  value: string | undefined | null;
                  onChange: (items: T | null, options: T[]) => void;
              }
            | {
                  type: 'many';
                  value: string[] | undefined;
                  onChange: (items: T[], options: T[]) => void;
              }
        )
>) {
    const [localization] = useLocalization();

    useEffect(() => {
        if (!onLoad || !items || itemsLoading) return;
        onLoad(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, itemsLoading]);

    const additional = additionalItems ?? [];
    const finalItems = overrideItems ?? items ?? [];

    const transformItems = (items: T[]) => {
        let transformedItems: any[] = items;
        if (mapItems) transformedItems = mapItems(items);
        return transformedItems;
    };

    const options = transformItems(
        [
            ...finalItems.filter(
                (i1) =>
                    !additional.find(
                        (i2) => i1[optionValue] === i2[optionValue]
                    )
            ),
            ...additional,
        ].filter((i) => {
            if (!excludeItems) return true;
            return !excludeItems.find((id) => i[optionValue] === id);
        })
    );

    const unavailable =
        itemsLoading ||
        (value
            ? type === 'many'
                ? !value.every(
                      (v) => !!options.find((item) => v === item[optionValue])
                  )
                : !options.find((item) => value === item[optionValue])
            : false);

    const sharedProps = {
        value: unavailable ? undefined : value,
        className: 'w-full',
        label,
        placeholder: unavailable ? localization.common.loading : placeholder,
        options,
        optionLabel,
        optionValue,
        required,
        loading: unavailable || loading,
        disabled: unavailable || disabled,
        showClear,
    };

    if (hidden) return null;

    return (
        <>
            {title && !hideTitle && <h3>{title}</h3>}
            {type === 'many' ? (
                <MultiSelect
                    {...sharedProps}
                    display='chip'
                    onChange={(e) => {
                        const validIds = e.target.value as string[];
                        const newItems = options?.filter(
                            (item) =>
                                !!validIds.find(
                                    (id) => id === item[optionValue]
                                )
                        );
                        onChange(newItems ?? [], options);
                    }}
                />
            ) : (
                <Dropdown
                    {...sharedProps}
                    onChange={(e) => {
                        const id = e.target.value as string;
                        const item = options?.find(
                            (item) => id === item[optionValue]
                        );
                        onChange(item ?? null, options);
                    }}
                />
            )}
        </>
    );
}

export default BaseSelectItems;
