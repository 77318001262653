import NoWrapBadge from 'components/display/NoWrapBadge';
import { useLocalization } from 'hooks/context/useLocalization';
import { BadgeProps } from 'primereact/badge';

const OperationStatusBadge: React.FC<{
    item: Ether.CaseManager.Operation.Detailed;
    badgeProps?: BadgeProps;
}> = ({ item, badgeProps }) => {
    const [localization] = useLocalization();
    const authorizationBadge =
        localization.components.models.operation.badge.status;
    const status = item.status;
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status ? status.toUpperCase() : 'null',
    };
    switch (status) {
        case 'finished':
            badgeProps.severity = 'success';
            badgeProps.value = authorizationBadge.finished.toLocaleUpperCase();
            break;
        case 'in_progress':
            badgeProps.severity = 'warning';
            badgeProps.value =
                authorizationBadge.inProgress.toLocaleUpperCase();
    }
    return <NoWrapBadge {...badgeProps} />;
};

export default OperationStatusBadge;
