import api from 'services/ether/api';
import {
    ParseDataTableParams,
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    DownloadProjectDocumentParams,
    DownloadTemplateTargetsParams,
    ListProjectEP,
    SignProjectDocumentEP,
} from './types';
import {
    fileDownloadBase,
    fileDownloadBlobBase,
    listBase,
} from 'services/ether/base';
import _ from 'lodash';

const handleProjectFilters = ({ filters, sort }: ParseDataTableParams) => {
    return parseDataTableFilterMetaForAPI({
        filters,
        sort,
    });
};

export const listProjects = ({
    options,
    signal,
}: ListProjectEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListProjectEP.Filters.Map = {
        _id: ['_id', devFilters._id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters);
    return listBase<Ether.CaseManager.Project[]>({
        endpoint: '/list-project',
        handleParams: handleProjectFilters,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const getOneProject = (
    _id: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.CaseManager.Project | null>((resolve, reject) => {
        api.get<Ether.ApiResponse<Ether.CaseManager.Project[]>>(
            '/list-project',
            {
                signal: options?.signal,
                params: {
                    _id,
                },
            }
        )
            .then((res) => {
                resolve(res.data.payload[0] ?? null);
            })
            .catch((err) => reject(err));
    });
};

export const upsertProject = (data: {
    _id?: string;
    name?: string;
    group_id?: string;
    authorizers?: string[];
    receivers?: string[];
    config?: {
        [key: string]: any;
    };
    block_checker_id?: string;
}) => {
    return new Promise<{
        _id: string;
        status: 'created' | 'updated';
    }>((resolve, reject) => {
        api.post<
            Ether.ApiResponse<
                {
                    _id: string;
                    status: 'created' | 'updated' | 'error';
                }[]
            >
        >('/upsert-project', data)
            .then((res) => {
                const data = res.data.payload[0];
                if (!data) {
                    reject(new Error('upsert-project failed to return data'));
                    return;
                }
                const { _id, status } = data;
                if (status === 'error')
                    reject(new Error('Error creating project'));
                else resolve({ _id, status });
            })
            .catch((err) => reject(err));
    });
};

export const downloadTemplateTargets = (
    data: DownloadTemplateTargetsParams
) => {
    return fileDownloadBase({
        endpoint: '/download-template-targets',
        params: {
            authorization_config_id: data.authorization_config_id,
        },
        filename:
            new Date().toISOString().split('T')[0] +
            `_template_${data.authorization_config_id}`,
        fallbackExtension: 'csv',
        signal: data.signal,
    });
};

export const downloadProjectDocument = ({
    key,
    lang,
    project_id,
    signal,
}: DownloadProjectDocumentParams) => {
    return fileDownloadBlobBase({
        endpoint: '/download-project-document',
        params: {
            project_id,
            key,
            lang,
        },
        signal,
    });
};

export const signProjectDocument = (data: SignProjectDocumentEP.Data) => {
    return new Promise<SignProjectDocumentEP.Result>((resolve, reject) => {
        api.post<SignProjectDocumentEP.ApiResponse>(
            `/sign-project-document`,
            data
        )
            .then((res) => {
                const data = res.data;
                if ('error' in data) {
                    reject(data.error);
                    return;
                }
                resolve(data);
            })
            .catch((err) => reject(err));
    });
};
