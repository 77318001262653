import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['document']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['document']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Document DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useDocumentColumnFields = () => {
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.document.datatable;
        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            type: {
                meta: getFilterData(),
                options: {
                    label: localization.fields.document.type,
                    placeholder: datatableLocale.selectType,
                    type: 'string',
                    relatedColumn: 'type',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.document.documentDate,
                    type: 'date',
                    relatedColumn: 'created_at',
                },
            },
            'process_document.document_date': {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.document.documentDate,
                    type: 'date',
                    relatedColumn: 'process_document.document_date',
                },
            },
        };
        return filterFields;
    }, [localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            type: {
                name: 'type',
            },
            created_at: {
                name: 'created_at',
            },
            'process_document.document_date': {
                name: 'process_document.document_date',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useDocumentColumnFields;
