import { Logo } from 'components/ethercity-primereact';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { Menubar } from 'primereact/menubar';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import MenuTemplate from 'components/misc/MenuTemplate';
import { useAuth } from 'hooks/context/useAuth';

interface INavbar {
    useNoRedirectLogo?: boolean;
    navigations?: MenuItem[];
    endContent?: ReactElement;
}

const Navbar: React.FC<INavbar> = ({
    navigations,
    useNoRedirectLogo,
    endContent,
}) => {
    const { user } = useAuth();

    const Navigations = () => {
        if (navigations) {
            return (
                <Menubar
                    model={navigations.map((nav) => {
                        if (!nav.items)
                            return {
                                ...nav,
                                template: (
                                    item: MenuItem,
                                    options: MenuItemOptions
                                ) => (
                                    <MenuTemplate
                                        item={item}
                                        options={options}
                                    />
                                ),
                            };
                        return nav;
                    })}
                />
            );
        }
        return <></>;
    };

    const gradientStart =
        user.role === 'admin'
            ? 'var(--darkish-red)'
            : user.role === 'analyst'
            ? 'var(--sky-blue-dark)'
            : user.role?.startsWith('authorizer')
            ? 'var(--duck-yellow)'
            : null;

    return (
        <section
            className='navbar'
            style={{
                background: gradientStart
                    ? `linear-gradient(${gradientStart}, 2%, var(--darkish-blue))`
                    : undefined,
            }}
        >
            <div className='navbar-content'>
                {useNoRedirectLogo ? (
                    <Logo
                        label='Case Manager'
                        labelRatio={0.9}
                        enableHover={false}
                    />
                ) : (
                    <Link to='/'>
                        <Logo
                            label='Case Manager'
                            labelRatio={0.9}
                            enableHover={true}
                        />
                    </Link>
                )}
                <Navigations />
            </div>
            <div className='navbar-end'>{endContent}</div>
        </section>
    );
};

export default Navbar;
