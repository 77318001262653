import api from 'services/ether/api';
import { DataTableFilterMeta, DataTableSortMeta } from 'primereact/datatable';
import { parseDataTableFilterMetaForAPI } from 'services/ether/utils';
import { InsertMonitoring } from './types';

export const listMonitoringFromProject = (
    project_id: string,
    options?: {
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        signal?: AbortSignal;
        sort?: DataTableSortMeta;
    }
) => {
    const params = parseDataTableFilterMetaForAPI({
        ...options,
    });

    return new Promise<Ether.CaseManager.Monitoring[]>((resolve, reject) => {
        api.get<Ether.ApiResponse<Ether.CaseManager.Monitoring[]>>(
            '/list-monitoring',
            {
                signal: options?.signal,
                params: {
                    project_id,
                    ...params,
                },
            }
        )
            .then((res) => {
                resolve(res.data.payload);
            })
            .catch((err) => reject(err));
    });
};

export const insertMonitoring = async (data: InsertMonitoring.Data) => {
    return new Promise<string>((resolve, reject) => {
        api.post<Ether.ApiResponse<InsertMonitoring.Response>>(
            '/insert-monitoring',
            data
        )
            .then((res) => {
                const response = res.data.payload[0];
                if (!response)
                    throw new Error('insert-monitoring failed to return data');
                if (typeof response === 'object' && 'error' in response)
                    throw new Error(response.error);
                resolve(response);
            })
            .catch((err) => reject(err));
    });
};
