import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { getUserDisplayName } from 'utils/models/user';
import useOperatorOrderColumnFields from 'hooks/columns/models/useOperatorOrderColumnFields';

type DetailedModel = Ether.CaseManager.OperatorsOrder.Detailed;

const RowMenu: React.FC<{
    item: Ether.CaseManager.OperatorsOrder.Detailed;
    onShowDetail: (operator: Ether.CaseManager.OperatorsOrder.Detailed) => void;
}> = ({ item, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugOperatorsOrder
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.datatable
                          .menuDetailItem,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const OperatorsOrderDataTable: React.FC<
    DataTableProps<DetailedModel[]> & Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const { columnFields } = useOperatorOrderColumnFields();

    const [
        viewOperatorsOrderDialogOptions,
        setViewOperatorsOrderDialogOptions,
    ] = useState<{
        visible: boolean;
        data: DetailedModel | null;
    }>({
        visible: false,
        data: null,
    });

    const onOperatorsOrderViewModalShow = (data: DetailedModel) =>
        setViewOperatorsOrderDialogOptions({
            visible: true,
            data: data,
        });

    const onOperatorsOrderViewModalHide = () =>
        setViewOperatorsOrderDialogOptions((old) => ({
            ...old,
            visible: false,
        }));

    const baseFilterOptions = {
        showAddButton: false,
        showFilterOperator: false,
    };

    return (
        <>
            <ObjectDisplayModal
                header={getUserDisplayName(
                    viewOperatorsOrderDialogOptions.data?.operators_data?.[0]
                        ?.users_data?.[0]
                )}
                visible={viewOperatorsOrderDialogOptions.visible}
                displayData={viewOperatorsOrderDialogOptions.data}
                onHide={onOperatorsOrderViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='operatorsOrder'
                lazy
                emptyMessage={
                    localization.components.models.operatorsBlockOrder.datatable
                        .empty
                }
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(rowData: DetailedModel) => {
                        const user = rowData.operators_data?.[0];
                        if (!user) return <></>;
                        return (
                            <NameIdAggregator
                                name={user.contact.email}
                                id={rowData._id}
                            />
                        );
                    }}
                    filter={!!props?.filters?._cm_name_id}
                    {...baseFilterOptions}
                />
                <Column
                    field={columnFields['operators_data.0.tags'].name}
                    header={localization.fields.operator.operatorTags}
                    body={(rowData: DetailedModel) => {
                        const operator = rowData.operators_data?.[0];
                        if (!operator) return '-';
                        return operator.tags.join(', ');
                    }}
                    {...baseFilterOptions}
                />
                <Column
                    field={
                        columnFields[
                            'operators_data.last_notification_data.last_sent_notification'
                        ].name
                    }
                    header={localization.fields.operator.lastNotificationAt}
                    body={(rowData: DetailedModel) => {
                        const operator = rowData.operators_data?.[0];
                        if (!operator) return '-';
                        const date =
                            operator.last_notification_data
                                ?.last_sent_notification;
                        return date ? <DateBadge value={date} /> : '-';
                    }}
                    {...baseFilterOptions}
                />
                {/* <Column
                    field='email_status'
                    header={localization.fields.operatorsBlockOrder.emailStatus}
                    filter={!!props?.filters?.email_status}
                    {...baseFilterOptions}
                    body={(rowData: Ether.CaseManager.OperatorsOrder) => (
                        <OperatorsblockOrderEmailStatus
                            operatorBlockOrder={rowData}
                        />
                    )}
                />
                <Column
                    field='last_sent_notification'
                    header={
                        localization.fields.operatorsBlockOrder
                            .lastSentNotification
                    }
                    filter={!!props?.filters?.last_sent_notification}
                    {...baseFilterOptions}
                    body={(rowData: Ether.CaseManager.OperatorsOrder) =>
                        rowData.last_sent_notification ? (
                            <DateBadge value={rowData.last_sent_notification} />
                        ) : (
                            '-'
                        )
                    }
                /> */}
                <Column
                    field='actions'
                    body={(rowData: DetailedModel) => (
                        <RowMenu
                            item={rowData}
                            onShowDetail={onOperatorsOrderViewModalShow}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default OperatorsOrderDataTable;
