import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { useAuth } from 'hooks/context/useAuth';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef, } from 'react';
import { downloadEvidenceFile } from 'services/ether/case-manager/evidences';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import EvidenceParsedDataStatusBadge from '../EvidenceParsedDataStatusBadge';
import { getFileModelName, handleFileModelDownload } from 'utils/file';
import useFileWrapperDialog from 'hooks/dialogs/general/useFileViewerDialog';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import useEvidenceColumnFields from 'hooks/columns/models/useEvidenceColumnFields';

type DetailedModel = Ether.CaseManager.Evidence.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShow: (item: DetailedModel) => void;
    onShowDetail: (item: DetailedModel) => void;
}> = ({ item, onShow, onShowDetail }) => {
    const menuRef = useRef<Menu>(null);
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const onDownload = () => {
        const file = item.file;
        if (!file) return;
        downloadEvidenceFile(item._id, file.id).then((data) =>
            handleFileModelDownload({
                file: data.file,
                item: item,
                extension: data.extension,
            })
        );
    };

    const menuItems: MenuItem[] = [
        {
            id: 'view',
            // icon: 'pi pi-sign-out',
            label: localization.components.common.menu.view,
            command: () => onShow(item),
        },
        {
            id: 'download',
            icon: 'pi pi-download',
            label: localization.components.common.button.download,
            command: () => onDownload(),
        },
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const EvidencesDatatable: React.FC<
    DataTableProps<DetailedModel[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & { token?: string }
> = ({ token, ...props }) => {
    const [localization] = useLocalization();

    const { dialog: fileWrapperDialog, showEvidence: renderFileDialog } =
        useFileWrapperDialog();
    const { dialog: objectDisplayDialog, show: renderObjectDialog } =
        useShowObjectDisplayModal();

    const { columnFields, filterOptions } = useEvidenceColumnFields();

    const showFile = (data: DetailedModel) => {
        renderFileDialog({
            header: getFileModelName(data) ?? '',
            modelId: data._id,
            token: token,
        });
    };
    const showObject = (data: DetailedModel) => {
        renderObjectDialog({
            header: getFileModelName(data) ?? '',
            data: data,
        });
    };

    const datatableLocale = localization.components.models.evidence.datatable;

    return (
        <>
            {fileWrapperDialog}
            {objectDisplayDialog}
            <CMDataTable
                columnConfigName='evidences'
                lazy
                emptyMessage={datatableLocale.empty}
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(data: DetailedModel) => {
                        const target = data.targets_data?.[0];
                        const auth = target?.authorizations_data?.[0];
                        return (
                            <NameIdAggregator
                                name={getFileModelName(data) ?? '-'}
                                id={data._id}
                                association={auth?.name}
                                onClick={() => showFile(data)}
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['type'].name}
                    header={localization.fields.evidence.type}
                    body={(data: DetailedModel) => {
                        return data.type ? (
                            <Badge
                                value={data.type.toLocaleUpperCase()}
                                severity='info'
                            />
                        ) : (
                            '-'
                        );
                    }}
                />
                <Column
                    field={columnFields['parsed_data_status'].name}
                    header={localization.fields.evidence.parsedStatus}
                    body={(data: DetailedModel) => (
                        <EvidenceParsedDataStatusBadge evidence={data} />
                    )}
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShow={(item) => showFile(item)}
                            onShowDetail={(item) => showObject(item)}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default EvidencesDatatable;
