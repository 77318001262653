import { useProject } from 'hooks/context/project/useProject';
import { OperatorsCompanySummaryProps } from './types';
import useDetailOperators from 'hooks/queries/operator/useDetailOperators';
import OperatorDataTable from '../OperatorsDataTable';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useOperatorColumnFields from 'hooks/columns/models/useOperatorColumnFields';

const OperatorsCompanySummary: React.FC<OperatorsCompanySummaryProps> = ({
    companyId,
    authorizationConfigId,
    blockOrderId,
    unblockOrderId,
}) => {
    const project = useProject();
    const { filterMetaData: operatorFilters } = useOperatorColumnFields();

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: operatorFilters,
            pageOptions: {
                startingRow: 5,
            },
        });

    const operatorsQuery = useDetailOperators({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            devFilters: {
                company_id: companyId,
                authorization_config_id: authorizationConfigId,
                block_order_id: blockOrderId,
                unblock_order_id: unblockOrderId,
            },
        },
    });

    return (
        <OperatorDataTable
            value={operatorsQuery.data?.payload}
            loading={operatorsQuery.isFetching}
            filters={filters}
            setFilters={setFilters}
            paginatorProps={{
                hideRowsPerPage: true,
                onPageChange: (e) => setPageOptions(e),
                page: pageOptions.page,
                rows: pageOptions.rows,
                onRefresh: operatorsQuery.refresh,
                disableNext:
                    !operatorsQuery.data?.payload.length ||
                    operatorsQuery.data.payload.length < pageOptions.rows,
            }}
        />
    );
};

export default OperatorsCompanySummary;
