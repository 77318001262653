import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['operators']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['operators']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Operator DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useOperatorColumnFields = () => {
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.operator.datatable;
        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
        };
        return filterFields;
    }, [localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            tags: {
                name: 'tags',
            },
            'users_data.0.infos.email': {
                name: 'users_data.0.infos.email',
            },
            'last_notification_data.last_sent_notification': {
                name: 'last_notification_data.last_sent_notification',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useOperatorColumnFields;
