import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';

import { useAuth } from 'hooks/context/useAuth';
import { useProject } from 'hooks/context/project/useProject';
import BlockOrderDataTable from 'components/models/BlockOrder/BlockOrderDataTable';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import CacheControl from 'controller/cache/cacheController';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';
import useDetailBlockOrderList from 'hooks/queries/block-order/useDetailManyBlockOrderList';
import useBlockOrderColumnFields from 'hooks/columns/models/useBlockOrderColumnFields';

const ListBlockOrders = () => {
    const { permissions } = useAuth();
    const project = useProject();
    const [localization] = useLocalization();
    const { element : UpsertDialog, showCreate, showEdit } = useUpsertBlockOrderDialog();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const { filterMetaData: blockOrderFilters } = useBlockOrderColumnFields();

    const { filters, pageOptions, setFilters, setPageOptions } =
        useInitDataTableState({
            filters: blockOrderFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.blockOrder,
            },
        });

    const blockOrdersQuery = useDetailBlockOrderList({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            sort: {
                field: 'created_at',
                order: SortOrder.DESC,
            },
        },
    });

    return (
        <section>
            <UpsertDialog />
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <h2>
                    {project.name} - {localization.models.blockOrder.plural}
                </h2>
                {permissions.insertBlockOrders && (
                    <Button
                        style={{ marginLeft: 'auto' }}
                        label={
                            localization.components.models.blockOrder.button.new
                        }
                        icon='pi pi-plus'
                        onClick={() => showCreate()}
                    />
                )}
            </div>
            <BlockOrderDataTable
                loading={blockOrdersQuery.isFetching}
                value={blockOrdersQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (blockOrdersQuery.data?.payload.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: blockOrdersQuery.refresh,
                }}
                onRespond={() => {
                    hardRefresh(['block-order']);
                    softRefresh(['unblock-order']);
                }}
                onShowEdit={showEdit}
            />
        </section>
    );
};

export default ListBlockOrders;
