import GoBackButton from 'components/misc/GoBackButton';
import { useLocalization } from 'hooks/context/useLocalization';

const NotFound: React.FC<{ hideButton?: boolean }> = ({ hideButton }) => {
    const [localization] = useLocalization();

    return (
        <section className='flex flex-col items-start'>
            <h2>{localization.validations.generic.notFound}</h2>
            {!hideButton && <GoBackButton />}
        </section>
    );
};

export default NotFound;
