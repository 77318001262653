import SimplifiedTargetsDataTable from 'components/models/Targets/SimplifiedTargetsDataTable';
import useSimplifiedTargetColumnFields from 'hooks/columns/models/useSimplifiedTargetColumnFields';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useDetailTargetsUnwindedBlockOrder from 'hooks/queries/target/useDetailTargetsUnwindedBlockOrder';
import { Dialog } from 'primereact/dialog';
import { useMemo, useState } from 'react';

/**
 * A modal/dialog to display a search box for targets, with simplified columns. The targets are queried the moment a filter is applied.
 * @returns object.dialog - The rendered element for the dialog. Must be supplied on the page to be used or it won't show
 * @returns object.show - Function to show the dialog
 */
const useSearchTargetsDialog = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filterMetaData, filterOptions } = useSimplifiedTargetColumnFields();
    const {
        pageOptions,
        setPageOptions,
        filters,
        setFilters,
        sort,
        setSort,
        isFiltersApplied,
    } = useInitDataTableState({
        filters: filterMetaData,
    });

    const [visible, setVisible] = useState(false);

    const { data, isLoading, refresh } = useDetailTargetsUnwindedBlockOrder(
        {
            project_id: project._id,
            options: {
                rawFilters: filters,
                limit: pageOptions.limit,
                offset: pageOptions.offset,
            },
        },
        {
            enabled: isFiltersApplied,
        }
    );

    const hide = () => setVisible(false);
    const show = () => setVisible(true);

    const dialog = useMemo(
        () => (
            <Dialog
                visible={visible}
                onHide={hide}
                header={
                    localization.components.models.target.button.searchTargets
                }
            >
                <SimplifiedTargetsDataTable
                    targetsTableConfig={{
                        type: 'type',
                        notified: 'notified',
                        type_order: 'type_order',
                        created_at: 'created_at',
                    }}
                    value={data?.payload}
                    loading={isLoading}
                    filters={filters}
                    filterOptions={filterOptions}
                    setFilters={setFilters}
                    sortField={sort?.field}
                    sortOrder={sort?.order}
                    onSort={(e) =>
                        setSort({
                            field: e.sortField,
                            order: e.sortOrder,
                        })
                    }
                    paginatorProps={{
                        onPageChange: (e) => setPageOptions(e),
                        page: pageOptions.page,
                        rows: pageOptions.rows,
                        onRefresh: refresh,
                        disableNext:
                            !data?.payload.length ||
                            data.payload.length < pageOptions.rows,
                    }}
                    hideTable={!isFiltersApplied}
                />
            </Dialog>
        ),
        [
            visible,
            data,
            isLoading,
            filters,
            filterOptions,
            setFilters,
            sort?.field,
            sort?.order,
            pageOptions.page,
            pageOptions.rows,
            refresh,
            setSort,
            setPageOptions,
            isFiltersApplied,
            localization,
        ]
    );

    return {
        dialog,
        show,
    };
};

export default useSearchTargetsDialog;
