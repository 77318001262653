import { Badge, BadgeProps } from 'primereact/badge';

/**
 * A PrimeReact Badge with overriding nowrap styles, so the text stays contained within the badge
 * @param props - BadgeProps, do note that the props.style.whiteSpace cannot be changed. For that, use the normal PrimeReact Badge
 * @returns A Badge element that has whitespace: nowrap style properties
 */
const NoWrapBadge: React.FC<BadgeProps> = ({ style, ...props }) => (
    <Badge
        {...props}
        style={{
            ...style,
            whiteSpace: 'nowrap',
        }}
    />
);

export default NoWrapBadge;
