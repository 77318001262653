import { useState } from 'react';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Link } from 'react-router-dom';

import DisplayCountBox from 'components/display/DisplayCountBox';

import { useAuth } from 'hooks/context/useAuth';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/models/user';

import useDetailRecentBlockOrder from 'hooks/queries/block-order/useDetailRecentBlockOrder';
import useDetailRecentAuthorization from 'hooks/queries/authorization/useQueryDetailRecentAuthorization';

import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';

import BlockOrderDataTable from 'components/models/BlockOrder/BlockOrderDataTable';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';

import AdminHome from './roles/AdminHome';
import AnalystHome from './roles/AnalystHome';
import AuthorizerHome from './roles/AuthorizerHome';
import AuthorityHome from './roles/AuthorityHome';
import OperatorHome from './roles/OperatorHome';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import InsertOperationButton from 'components/models/Operation/InsertOperationButton';

// const ShowChart: React.FC<{
//     title: string;
//     result: string;
//     resultLabel: string;
// }> = ({ title, result, resultLabel }) => {
//     return (
//         <div>
//             <span className='text-sm'>{title.toLocaleUpperCase()}</span>
//             <div className='flex flex-row gap-4 items-center'>
//                 <div className='flex flex-col text-justify items-center gap-4'>
//                     <strong className='text-3xl'>{result}</strong>
//                     <span>{resultLabel.toLocaleUpperCase()}</span>
//                 </div>
//                 <FakeChartSvg />
//             </div>
//         </div>
//     );
// };

// const SimpleBox: React.FC<{
//     topText: string;
//     bottomText: string;
//     lastText?: string;
// }> = ({ topText, bottomText, lastText }) => {
//     return (
//         <div className='flex flex-col justify-start items-center text-center w-full'>
//             <span className='text-base'>{topText.toLocaleUpperCase()}</span>
//             <strong className='text-3xl'>{bottomText}</strong>
//             {lastText && <span className='text-sm'>{lastText}</span>}
//         </div>
//     );
// };

export const AuthorizationDashboard: React.FC<{
    hideTitle?: boolean;
    hideCount?: boolean;
}> = ({ hideTitle, hideCount }) => {
    const project = useProject();
    const [localization] = useLocalization();
    const { permissions, user } = useAuth();
    const { element: UpsertDialog, showCreate } =
        useUpsertAuthorizationDialog();

    const refetchAll = () => {
        pendingAuthorizations.refetch();
        approvedAuthorizations.refetch();
        metricsQuery.refetch();
    };

    const viewDraft = !user.role?.startsWith('authorizer');

    const [selectedMenu, setSelectedMenu] = useState<
        'pending' | 'approved' | 'draft'
    >(viewDraft ? 'draft' : 'pending');

    const pendingAuthorizations = useDetailRecentAuthorization(
        project._id,
        'pending'
    );
    const approvedAuthorizations = useDetailRecentAuthorization(
        project._id,
        'approved'
    );
    const draftAuthorizations = useDetailRecentAuthorization(
        project._id,
        'draft',
        {
            enabled: viewDraft,
        }
    );

    const metricsQuery = useMetricsQuery(project._id);

    const pageLocale = localization.components.views.homeDashboard;
    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    return (
        <section>
            <UpsertDialog />
            {!hideTitle && <h3>{localization.models.authorization.plural}</h3>}
            {!hideCount && (
                <div>
                    {metricsQuery.isLoading ? (
                        <h4>Loading metrics...</h4>
                    ) : (
                        <div className='flex flex-start gap-2'>
                            <DisplayCountBox
                                label={localization.models.authorization.plural}
                                count={
                                    metricsQuery.data?.total_authorizations ?? 0
                                }
                            />
                            {viewDraft && (
                                <DisplayCountBox
                                    label={authorizationBadge.draft}
                                    count={
                                        metricsQuery.data
                                            ?.authorization_status_draft ?? 0
                                    }
                                />
                            )}
                            <DisplayCountBox
                                label={authorizationBadge.pending}
                                count={
                                    metricsQuery.data
                                        ?.authorization_status_pending ?? 0
                                }
                            />
                            <DisplayCountBox
                                label={authorizationBadge.approved}
                                count={
                                    metricsQuery.data
                                        ?.authorization_status_approved ?? 0
                                }
                            />
                        </div>
                    )}
                </div>
            )}
            <div className='flex justify-between items-center gap-2'>
                <h3>{pageLocale.recentAuthorizations}</h3>
                {permissions.insertAuthorizations && (
                    <Button
                        label={
                            localization.components.models.authorization.button
                                .new
                        }
                        icon='pi pi-plus'
                        onClick={showCreate}
                        style={{ marginLeft: 'auto' }}
                    />
                )}
                <Link to='/authorizations'>
                    <Button
                        label={
                            localization.components.models.authorization.button
                                .viewAll
                        }
                    />
                </Link>
            </div>
            <TabMenu
                model={[
                    ...(viewDraft
                        ? [
                              {
                                  label:
                                      authorizationBadge.draft +
                                      (metricsQuery.data
                                          ? ` (${
                                                metricsQuery.data
                                                    ?.authorization_status_draft ??
                                                0
                                            })`
                                          : ''),
                                  command: () => setSelectedMenu('draft'),
                              },
                          ]
                        : []),
                    {
                        label:
                            authorizationBadge.pending +
                            (metricsQuery.data
                                ? ` (${
                                      metricsQuery.data
                                          ?.authorization_status_pending ?? 0
                                  })`
                                : ''),
                        command: () => setSelectedMenu('pending'),
                    },
                    {
                        label:
                            localization.components.models.authorization.badge
                                .status.approved +
                            (metricsQuery.data
                                ? ` (${
                                      metricsQuery.data
                                          ?.authorization_status_approved ?? 0
                                  })`
                                : ''),
                        command: () => setSelectedMenu('approved'),
                    },
                ]}
            />
            <AuthorizationDataTable
                value={
                    (selectedMenu === 'pending'
                        ? pendingAuthorizations.data
                        : selectedMenu === 'draft'
                        ? draftAuthorizations.data
                        : approvedAuthorizations.data
                    )?.payload
                }
                onRespond={refetchAll}
                showApproved={selectedMenu === 'approved'}
                loading={
                    pendingAuthorizations.isLoading ||
                    draftAuthorizations.isLoading ||
                    approvedAuthorizations.isLoading
                }
            />
        </section>
    );
};

export const BlockOrderDashboard: React.FC<{
    hideTitle?: boolean;
    hideCount?: boolean;
}> = ({ hideTitle, hideCount }) => {
    const project = useProject();
    const { permissions, user } = useAuth();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [localization] = useLocalization();
    const { element: UpsertDialog, showCreate } = useUpsertBlockOrderDialog();

    const showDraft = user.role !== 'operator';

    const doneBlockOrders = useDetailRecentBlockOrder(project._id, 'done');
    const draftBlockOrders = useDetailRecentBlockOrder(
        project._id,
        'draft',
        showDraft
    );

    const metricsQuery = useMetricsQuery(project._id);

    const pageLocale = localization.components.views.homeDashboard;

    return (
        <section>
            <UpsertDialog />
            {!hideTitle && <h3>{localization.models.blockOrder.plural}</h3>}
            {!hideCount && (
                <div>
                    {metricsQuery.isLoading ? (
                        <h4>{pageLocale.metricsLoading}</h4>
                    ) : (
                        <div className='flex items-start gap-2'>
                            <DisplayCountBox
                                label={localization.models.blockOrder.plural.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data?.total_block_orders ?? 0
                                }
                            />
                            <DisplayCountBox
                                label={localization.components.models.blockOrder.badge.status.doneWithName.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data
                                        ?.block_order_status_done ?? 0
                                }
                            />
                            {showDraft && (
                                <DisplayCountBox
                                    label={localization.components.models.blockOrder.badge.status.draftWithName.toLocaleUpperCase()}
                                    count={
                                        metricsQuery.data
                                            ?.block_order_status_draft ?? 0
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className='flex gap-2 justify-between items-center'>
                <h3>{pageLocale.recentBlockOrders}</h3>
                {permissions.insertBlockOrders && (
                    <Button
                        label={
                            localization.components.models.blockOrder.button.new
                        }
                        icon='pi pi-plus'
                        onClick={() => showCreate()}
                        style={{ marginLeft: 'auto' }}
                    />
                )}
                {user.role === 'authorizer-user' && <InsertOperationButton />}
                <Link to='/block-orders'>
                    <Button
                        label={
                            localization.components.models.blockOrder.button
                                .viewAll
                        }
                    />
                </Link>
            </div>
            <TabMenu
                activeIndex={selectedIndex}
                onTabChange={(e) => setSelectedIndex(e.index)}
                model={[
                    {
                        label:
                            localization.components.models.blockOrder.badge
                                .status.doneWithName +
                            (metricsQuery.data && !hideCount
                                ? ` (${
                                      metricsQuery.data
                                          ?.block_order_status_done ?? 0
                                  })`
                                : ''),
                    },
                    ...(showDraft
                        ? [
                              {
                                  label:
                                      localization.components.models.blockOrder
                                          .badge.status.draftWithName +
                                      (metricsQuery.data && !hideCount
                                          ? ` (${
                                                metricsQuery.data
                                                    ?.block_order_status_draft ??
                                                0
                                            })`
                                          : ''),
                              },
                          ]
                        : []),
                ]}
            />
            <BlockOrderDataTable
                value={
                    (selectedIndex === 0
                        ? doneBlockOrders.data
                        : draftBlockOrders.data
                    )?.payload
                }
                loading={
                    draftBlockOrders.isFetching || doneBlockOrders.isFetching
                }
            />
        </section>
    );
};

const Home = () => {
    const { user } = useAuth();
    const [localization] = useLocalization();
    return (
        <>
            <h2>
                {localization.components.views.homeDashboard.welcome.replace(
                    '{username}',
                    getUserDisplayName(user.data)
                )}
            </h2>
            {user.role === 'admin' && <AdminHome />}
            {user.role === 'analyst' && <AnalystHome />}
            {user.role === 'authorizer-user' && <AuthorizerHome />}
            {user.role === 'authorizer-strict' && <AuthorityHome />}
            {user.role === 'operator' && <OperatorHome />}
        </>
    );
};

export default Home;
