import { useRef, useState } from 'react';
import {
    FilterOption,
    IntermediateFilter,
    UpdateFilterAction,
} from '../../types';
import { DropdownProps } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import FilterBoxInputTextFilter from '../FilterBoxInputTextFilter';
import FilterBoxDatepickerFilter from '../FilterBoxDatepickerFilter';
import FilterBoxInputNumberFilter from '../FilterBoxInputNumberFilter';
import FilterBoxDropdownFilter from '../FilterBoxDropdownFilter';
import FilterBoxMultiSelectFilter from '../FilterBoxMultiselectFilter';
import FilterBoxCustomElementFilter from '../FilterBoxCustomElementFilter';

export type FilterComponentProps = {
    options: FilterOption;
    filter: IntermediateFilter;
    onFilter: UpdateFilterAction;
};

export type DispatchApplyFilter = (params: {
    filters: IntermediateFilter;
    event: React.SyntheticEvent | undefined | null;
}) => void;

/**
 * Component for the FilterBox, able to determine from each type which filter to render
 * @param object.options - The filter options describing object, will determine which filter to render and how
 * @param object.filter - The raw filter object that includes which value and matchMode is using
 * @param object.onFilter - The onFilter dispatch action that will update the raw filter object
 * @returns A Filter element for the FilterBox given its type
 */
const FilterBoxComponent: React.FC<FilterComponentProps> = ({
    options,
    filter,
    onFilter,
}) => {
    const defaultNull =
        options.type === 'string'
            ? ''
            : options.type === 'multiselect'
            ? []
            : null;
    const defaultMatchMode =
        options.type === 'date'
            ? FilterMatchMode.DATE_IS
            : options.type === 'number'
            ? FilterMatchMode.EQUALS
            : FilterMatchMode.CONTAINS;

    const [temporary, setTemporary] = useState<IntermediateFilter>({
        ...filter,
        matchMode: filter.matchMode ?? defaultMatchMode,
    });

    const dropdownProps: DropdownProps = {
        value: temporary.matchMode,
        onChange: (e) =>
            setTemporary((old) => ({
                ...old,
                matchMode: e.value,
            })),
    };

    const overlayRef = useRef<OverlayPanel>(null);

    const applyFilter: DispatchApplyFilter = ({ filters, event }) => {
        overlayRef.current?.toggle(event);
        onFilter(filters);
        setTemporary((old) => ({
            ...old,
            value: defaultNull,
        }));
    };

    return (
        <>
            <Button
                onClick={(e) => overlayRef.current?.toggle(e)}
                rounded
                label={options.label}
                icon='pi pi-angle-down'
                iconPos='right'
            />
            <OverlayPanel ref={overlayRef}>
                {options.type === 'date' ? (
                    <FilterBoxDatepickerFilter
                        applyFilter={applyFilter}
                        dropdownProps={dropdownProps}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        hideMatchMode={options.hideMatchMode}
                    />
                ) : options.type === 'string' ? (
                    <FilterBoxInputTextFilter
                        applyFilter={applyFilter}
                        dropdownProps={dropdownProps}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        placeholder={options.placeholder}
                        hideMatchMode={options.hideMatchMode}
                    />
                ) : options.type === 'number' ? (
                    <FilterBoxInputNumberFilter
                        applyFilter={applyFilter}
                        dropdownProps={dropdownProps}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        placeholder={options.placeholder}
                        hideMatchMode={options.hideMatchMode}
                    />
                ) : options.type === 'multiselect' ? (
                    <FilterBoxMultiSelectFilter
                        applyFilter={applyFilter}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        selectOptions={options.selectOptions}
                        placeholder={options.placeholder}
                    />
                ) : options.type === 'dropdown' ? (
                    <FilterBoxDropdownFilter
                        applyFilter={applyFilter}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        selectOptions={options.selectOptions}
                        placeholder={options.placeholder}
                    />
                ) : options.type === 'custom' ? (
                    <FilterBoxCustomElementFilter
                        applyFilter={applyFilter}
                        temporaryFilter={temporary}
                        setTemporaryFilter={setTemporary}
                        FilterElement={options.element}
                    />
                ) : (
                    'FilterBox component error'
                )}
            </OverlayPanel>
        </>
    );
};

export default FilterBoxComponent;
