import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';
import { useProject } from 'hooks/context/project/useProject';
import FilterBoxMultiselectPirateBrand from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiselectTagPirateBrand';
import FilterBoxDropdownTagCountry from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagCountry';
import FilterBoxDropdownTagFlag from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTagFlag';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['blockOrder']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['blockOrder']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the BlockOrder DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useBlockOrderColumnFields = () => {
    const [localization] = useLocalization();
    const project = useProject();

    const filterFields = useMemo(() => {
        // TODO: [EMEA NAME] REMOVE LATER
        const isEmeaProject = project.name.toLowerCase().includes('emea');
        const datatableLocale =
            localization.components.models.blockOrder.datatable;
        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.blockOrder.status,
                    type: 'dropdown',
                    selectOptions: [
                        {
                            label: localization.components.models.blockOrder
                                .badge.status.done,
                            value: 'done',
                        },
                        {
                            label: localization.components.models.blockOrder
                                .badge.status.draft,
                            value: 'draft',
                        },
                    ],
                    relatedColumn: 'status',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.blockOrder.created,
                    type: 'date',
                    relatedColumn: 'created_at',
                },
            },
            _cmapp_tags_pirate_brand: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'custom',
                    label: localization.models.tag.types.pirateBrand.plural,
                    element: FilterBoxMultiselectPirateBrand,
                    initialValue: [],
                    relatedColumn: '_cm_name_id',
                },
            },
        };
        if (isEmeaProject) {
            filterFields['_cmapp_tags_country'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.country.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagCountry,
                    initialValue: null,
                },
            };
            filterFields['_cmapp_tags_flag'] = {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.tag.types.flag.singular,
                    relatedColumn: '_cm_name_id',
                    type: 'custom',
                    element: FilterBoxDropdownTagFlag,
                    initialValue: null,
                },
            };
        }
        return filterFields;
    }, [project, localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            authorizations_count: {
                name: 'authorizations_count',
            },
            status: {
                name: 'status',
            },
            validation_status: {
                name: 'validation_status',
            },
            'block_checker.synced_at': {
                name: 'block_checker.synced_at',
            },
            created_at: {
                name: 'created_at',
            },
            created_by: {
                name: 'created_by',
            },
            targets_count: {
                name: 'targets_count',
            },
            registered_at: {
                name: 'registered_at',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useBlockOrderColumnFields;
