import { CMDataTableFiltersFields } from 'components/datatable/CMDataTable/types';
import { FilterOption } from 'components/datatable/FilterBox/types';
import { DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { useMemo } from 'react';

/**
 * Hook to get generate the filterOptions and filterMetaData given a filtersFields object
 * @template ValidKeys - The valid keys which may be indexed in the filter object
 * @params object.columnFields - The object describing each column by its field name and available filters
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
function useGenerateFilters<ValidKeys extends string>(
    filtersFields: CMDataTableFiltersFields<string, string>
): {
    filterOptions: { [key in ValidKeys]?: FilterOption };
    filterMetaData: { [key in ValidKeys]?: DataTableOperatorFilterMetaData };
} {
    const [filterOptions, filterMetaData] = useMemo(() => {
        const filterOptions: { [key in ValidKeys]?: FilterOption } = {};
        const filterMetaData: {
            [key in ValidKeys]?: DataTableOperatorFilterMetaData;
        } = {};
        Object.entries(filtersFields).forEach(([key, value]) => {
            if (!value) return;
            filterMetaData[key as ValidKeys] = value.meta;
            filterOptions[key as ValidKeys] = value.options;
        });
        return [filterOptions, filterMetaData];
    }, [filtersFields]);

    return { filterOptions, filterMetaData };
}
export default useGenerateFilters;
