import { useLocalization } from 'hooks/context/useLocalization';
import { IntermediateFilter } from '../../types';
import { Button } from 'primereact/button';
import { DispatchApplyFilter } from '../FilterBoxComponent';
import { FilterMatchMode } from 'primereact/api';

export type CustomFilterElementProps<T = any> = {
    value: T;
    onChange: (params: {
        value: T;
        selectOptions: { label: string; value: string }[];
    }) => void;
    onFilterApply?: (event: React.SyntheticEvent | undefined | null) => void;
    matchMode?: FilterMatchMode;
};

/**
 * Custom filter element for the FilterBox
 * @param object.temporaryFilter - The raw temporary filter object that includes which value and matchMode is using
 * @param object.setTemporaryFilter - The set dispatch action that will update the temporary raw filter object
 * @param object.applyFilter - The action that will apply the input/selected value and label to the final filter, passing alongside the HTML event
 * @param object.FilterElement - The renderable element as a FunctionalComponent, it should have a value and dispatch a value and label on its props
 * @returns A wrapper for the custom filtter element
 */
const FilterBoxCustomElementFilter: React.FC<{
    temporaryFilter: IntermediateFilter;
    setTemporaryFilter: React.Dispatch<
        React.SetStateAction<IntermediateFilter>
    >;
    applyFilter: DispatchApplyFilter;
    FilterElement: React.FC<CustomFilterElementProps>;
}> = ({ FilterElement, temporaryFilter, setTemporaryFilter, applyFilter }) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const apply = (event: React.SyntheticEvent | undefined | null) => {
        applyFilter({
            event: event,
            filters: {
                ...temporaryFilter,
            },
        });
        setTemporaryFilter((old) => ({
            ...old,
            value: [],
        }));
    };

    const changeFilter = (params: {
        value: any;
        selectOptions: { value: string; label: string }[];
    }) => {
        setTemporaryFilter((old) => ({
            ...old,
            value: params.value,
            selectOptions : params.selectOptions,
        }));
    };

    return (
        <div className='flex flex-col gap-2 w-56'>
            <FilterElement
                value={temporaryFilter.value}
                onChange={changeFilter}
                onFilterApply={apply}
            />
            <Button
                label={primereact.apply}
                onClick={(e) => {
                    applyFilter({
                        event: e,
                        filters: {
                            ...temporaryFilter,
                        },
                    });
                    setTemporaryFilter((old) => ({
                        ...old,
                        value: [],
                    }));
                }}
            />
        </div>
    );
};

export default FilterBoxCustomElementFilter;
