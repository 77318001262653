import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['operation']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['operation']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Operation DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useOperationColumnFields = () => {
    const [localization] = useLocalization();

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.operation.datatable;
        const fieldsLocalization = localization.fields.operation;
        const statusLocalization =
            localization.components.models.operation.badge.status;

        const filterFields: CMDataTableFiltersFields<
            ModelFilters,
            ModelColumns
        > = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    relatedColumn: '_cm_name_id',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: fieldsLocalization.status,
                    placeholder: datatableLocale.selectStatus,
                    type: 'dropdown',
                    selectOptions: [
                        {
                            label: statusLocalization.finished,
                            value: 'finished',
                        },
                        {
                            label: statusLocalization.inProgress,
                            value: 'in_progress',
                        },
                    ],
                    relatedColumn: 'created_at',
                },
            },
            status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fieldsLocalization.created,
                    type: 'date',
                    relatedColumn: 'status',
                },
            },
        };
        return filterFields;
    }, [localization]);

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            created_at: {
                name: 'created_at',
            },
            status: {
                name: 'status',
            },
            block_orders_count: {
                name: 'block_orders_count',
            },
            authorizations_count: {
                name: 'authorizations_count',
            },
            targets_count: {
                name: 'targets_count',
            },
            ips_count: {
                name: 'ips_count',
            },
            domains_count: {
                name: 'domains_count',
            },
            total_unique_countries: {
                name: 'total_unique_countries',
            },
            total_unique_hosting_companies: {
                name: 'total_unique_hosting_companies',
            },
        };
        return columnFields;
    }, []);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useOperationColumnFields;
