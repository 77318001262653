import { useLocalization } from 'hooks/context/useLocalization';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import useListPirateBrands from 'hooks/queries/tag/useListPirateBrands';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';

const SelectManyPirateBrands: React.FC<
    SelectManyItemsProps<Ether.CaseManager.Tag, DetailTagEP.Filters.Options>
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();

    const query = useListPirateBrands();

    return (
        <SelectManyItems
            label={localization.models.tag.types.pirateBrand.plural}
            title={localization.models.tag.types.pirateBrand.plural}
            placeholder={
                localization.components.models.tag.types.pirateBrand.form
                    .selectManyPlaceholder
            }
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyPirateBrands;
