import { useState } from 'react';
import { ViewTablesWithTabsProps } from './types';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { DataTableOperatorFilterMetaData } from 'primereact/datatable';
import CacheControl from 'controller/cache/cacheController';
import useQueryRefresh, {
    ValidCancelKeys,
} from 'hooks/queries/useQueryRefresh';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import useAuthorizationColumnFields from 'hooks/columns/models/useAuthorizationColumnFields';
import useAuthorizationConfigColumnFields from 'hooks/columns/models/useAuthorizationConfigColumnFields';
import useBlockOrderColumnFields from 'hooks/columns/models/useBlockOrderColumnFields';
import useCompanyColumnFields from 'hooks/columns/models/useCompanyColumnFields';
import useDocumentColumnFields from 'hooks/columns/models/useDocumentColumnFields';
import useEvidenceColumnFields from 'hooks/columns/models/useEvidenceColumnFields';
import useOperatorColumnFields from 'hooks/columns/models/useOperatorColumnFields';
import useTagColumnFields from 'hooks/columns/models/useTagColumnFields';
import useTargetColumnFields from 'hooks/columns/models/useTargetColumnFields';
import useUnblockOrderColumnFields from 'hooks/columns/models/useUnblockOrderColumnFields';

type ColumnKeys = keyof CaseManagerApp.ModelColumns;

const TabBox: React.FC<{
    label: string;
    count?: number | string;
    active?: boolean;
    onClick?: () => void;
}> = ({ label, count, active, onClick }) => {
    const color = !active ? 'gray-blue-950' : 'white';
    const oppositeColor = active ? 'gray-blue-950' : 'white';

    const wrapperDivClasses = [
        'flex',
        'flex-col',
        'justify-center',
        'gap-4',
        `bg-${color}`,
        'items-center',
        'p-4',
        'w-[140px]',
        'rounded-t-lg',
        'hover:cursor-pointer',
    ];

    return (
        <div
            className={wrapperDivClasses.join(' ')}
            style={{
                border: !active ? '1px solid var(--white)' : undefined,
            }}
            onClick={onClick}
        >
            <span
                className={[
                    `text-${oppositeColor}`,
                    'text-xs',
                    'text-center',
                    'items-center',
                    ...(count != null ? ['min-h-[42px]'] : []),
                ].join(' ')}
            >
                {label.toUpperCase()}
            </span>
            {count != null && (
                <span
                    className={[
                        `text-${oppositeColor}`,
                        'text-3xl',
                        'font-bold',
                    ].join(' ')}
                >
                    {count}
                </span>
            )}
        </div>
    );
};

const cancelKeyDict: {
    [key in ColumnKeys]: ValidCancelKeys;
} = {
    authorization: 'authorization',
    authorizationConfig: 'authorization-config',
    blockOrder: 'block-order',
    company: 'company',
    document: 'document',
    evidences: 'evidence',
    monitoring: 'monitor',
    operation: 'operation',
    operators: 'operator',
    operatorsOrder: 'operator-order',
    tag: 'tag',
    targets: 'target',
    unblockOrder: 'unblock-order',
    user: 'user',
    groupedUserProfile: 'grouped-user',
};

const ViewTablesWithTabs: React.FC<ViewTablesWithTabsProps> = ({
    mainModel,
    uniqueQueryKey,
    views,
    onTabChange,
}) => {
    const { filterMetaData: authorizationFilters } =
        useAuthorizationColumnFields();
    const { filterMetaData: authorizationConfigFilters } =
        useAuthorizationConfigColumnFields();
    const { filterMetaData: blockOrderFilters } = useBlockOrderColumnFields();
    const { filterMetaData : companyFilters } = useCompanyColumnFields();
    const { filterMetaData : documentFilters } = useDocumentColumnFields();
    const { filterMetaData : evidenceFilters } = useEvidenceColumnFields();
    const { filterMetaData: operatorFilters } = useOperatorColumnFields();
    const { filterMetaData : tagFilters } = useTagColumnFields();
    const { filterMetaData: targetFilters } = useTargetColumnFields();
    const { filterMetaData: unblockOrderFilters } = useUnblockOrderColumnFields();

    const defaultModelItems: Record<
        ColumnKeys,
        {
            filters: {
                [field: string]: DataTableOperatorFilterMetaData;
            };
        }
    > = {
        authorization: {
            filters: authorizationFilters,
        },
        authorizationConfig: {
            filters: authorizationConfigFilters,
        },
        blockOrder: {
            filters: blockOrderFilters,
        },
        company: {
            filters: companyFilters,
        },
        document: {
            filters: documentFilters,
        },
        evidences: {
            filters: evidenceFilters,
        },
        monitoring: {
            filters: {},
        },
        operation: {
            filters: {},
        },
        operators: {
            filters: operatorFilters,
        },
        operatorsOrder: {
            filters: {},
        },
        tag: {
            filters: tagFilters,
        },
        targets: {
            filters: targetFilters,
        },
        unblockOrder: {
            filters: unblockOrderFilters,
        },
        user: {
            filters: {},
        },
        groupedUserProfile: {
            filters: {},
        },
    };

    const [selectedIndex, setSelectedIndex] = useState(0);

    const currentView = views[selectedIndex];
    if (!currentView) throw new Error('expected view to exist');

    const model = 'model' in currentView ? currentView.model : null;

    const baseFilters =
        'overrideFilters' in currentView && currentView.overrideFilters
            ? currentView.overrideFilters
            : model
            ? defaultModelItems[model].filters
            : {};
    const baseRows = model
        ? CacheControl.UserConfig.get()['paginatorRows'][model]
        : undefined;
    const fetchMethod =
        'fetchData' in currentView
            ? currentView.fetchData
            : () =>
                  new Promise<{ payload: unknown[]; meta: {} }>((resolve) =>
                      resolve({ payload: [], meta: {} })
                  );

    const { filters, setFilters, sort, setSort, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: baseFilters,
            pageOptions: {
                startingRow: baseRows,
            },
        });

    const onIndexChange = (index: number) => {
        setSelectedIndex(index);
        const newView = views[index];
        if (!newView || !('model' in newView)) return;
        const model = newView.model;
        const baseFilters =
            'overrideFilters' in newView && newView.overrideFilters
                ? newView.overrideFilters
                : defaultModelItems[model].filters;
        const rows = CacheControl.UserConfig.get()['paginatorRows'][model];
        setPageOptions({
            page: 1,
            rows: rows ?? 50,
        });
        setFilters(baseFilters);
        if (mainModel) softRefresh([mainModel]);
        if (onTabChange) onTabChange(index);
    };

    const dataQuery = useQueryWithRefresh({
        queryKey: [
            model ? cancelKeyDict[model] : model,
            'list-view-tables',
            uniqueQueryKey,
            selectedIndex,
            filters,
            sort,
            pageOptions,
        ],
        queryFn: ({ signal }) => {
            return fetchMethod({
                options: {
                    rawFilters: filters,
                    offset: pageOptions.offset,
                    limit: pageOptions.rows,
                    sort: sort,
                },
                signal: signal,
            });
        },
    });

    const { softRefresh } = useQueryRefresh();

    const elementToDisplay =
        'tableElement' in currentView ? (
            <currentView.tableElement
                lazy
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        !dataQuery.data?.payload?.length ||
                        dataQuery.data?.payload.length < pageOptions.rows,
                    onRefresh: () => {
                        if (mainModel) softRefresh([mainModel]);
                        dataQuery.refresh();
                    },
                }}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortOrder={sort?.order}
                sortField={sort?.field}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                value={dataQuery.data?.payload}
                meta={dataQuery.data?.meta}
                loading={dataQuery.isFetching}
            />
        ) : (
            currentView.customElement
        );

    return (
        <section>
            <div className='flex flex-row gap-4 border-0 border-b-white border-b-2 border-solid'>
                {views.map((v, index) => (
                    <TabBox
                        key={index}
                        count={v.totalCount ?? undefined}
                        label={v.label}
                        active={selectedIndex === index}
                        onClick={() => onIndexChange(index)}
                    />
                ))}
            </div>
            {elementToDisplay}
        </section>
    );
};

export default ViewTablesWithTabs;
